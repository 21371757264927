<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    bottom
    center
    transition="slide-y-transition"
    max-width="400"
  >
    <template #activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card
          outlined
          class="cursor-pointer pa-2 d-flex flex-row uploadComponent"
          v-bind="attrs"
          v-on="on"
          :style="{
            transition: 'background-color 0.3s',
            'background-color': hover ? 'var(--color-foreground-low)' : '',
            'border-color': 'var(--color-foreground-low)',
          }"
        >
          <div class="d-flex">
            <v-icon
              class="me-2"
              :color="hover ? 'white' : ''"
              >{{ icon }}</v-icon
            >

            <p :class="`text-subtitle-1 mb-0 ${hover ? 'text-white' : ''}`">
              Upload {{ uploadType.charAt(0).toUpperCase() + uploadType.slice(1) }}
            </p>
          </div>
        </v-card>
      </v-hover>
    </template>

    <v-card
      max-width="400"
      style="overflow: hidden"
    >
      <v-row class="py-2 px-3">
        <v-col
          cols="12"
          class="pb-1"
        >
          <v-text-field
            v-if="uploadType === 'bookmark'"
            v-model="setLinkBookmark"
            placeholder="https://"
            dense
            outlined
            clearable
            hide-details
          >
          </v-text-field>

          <v-file-input
            v-else
            v-model="setInputFile"
            counter
            show-size
            dense
            outlined
            clearable
            hide-details
            :hide-input="false"
          ></v-file-input>
        </v-col>

        <v-col
          cols="12"
          class="pt-0"
        >
          <div v-if="loading && uploadType !== 'bookmark'">
            <span class="text-caption">
              ({{ uploadLoaded.toFixed(2) / 1024 }} KB / {{ uploadTotal.toFixed(2) / 1024 }} KB)
              {{ uploadProgress }}%
            </span>
            <v-progress-linear :value="uploadProgress"></v-progress-linear>
          </div>

          <v-btn
            color="primary"
            class="w-100 mt-1"
            @click="emit('submit')"
          >
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </div>
            <div v-else>Upload</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>
<script setup>
import { computed } from 'vue'

const emit = defineEmits(['submit', 'set-linkBookmark', 'set-inputFile'])
const {
  loading,
  inputFile,
  icon,
  uploadType,
  linkBookmark,
  uploadLoaded,
  uploadProgress,
  uploadTotal,
} = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  inputFile: {
    type: [File],
    default: null,
  },
  icon: {
    type: String,
    default: '',
  },
  uploadType: {
    type: String,
    default: '',
    validator: value => ['audio', 'file', 'bookmark'].includes(value),
  },
  linkBookmark: {
    type: String,
    default: null,
  },
  uploadLoaded: {
    type: [Number],
    default: null,
  },
  uploadTotal: {
    type: [Number],
    default: null,
  },
  uploadProgress: {
    type: [Number],
    default: null,
  },
})

const setInputFile = computed({
  get() {
    return inputFile
  },
  set(val) {
    return emit('set-inputFile', val)
  },
})

const setLinkBookmark = computed({
  get() {
    return linkBookmark
  },
  set(val) {
    return emit('set-linkBookmark', val)
  },
})
</script>
<style lang=""></style>
