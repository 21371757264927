<template>
  <v-card class="d-flex flex-column px-3 h-full">
    <div class="d-flex flex-column">
      <v-alert
        border="left"
        color="primary"
        dense
        text
        type="info"
        class="mb-4 text-subtitle-2 py-2"
      >
        Daftar pengguna di {{ workspaceDetail?.workspace?.name }}. Kamu bisa mengundang hingga 5
        pengguna di workspace ini.
      </v-alert>
      <v-row class="mb-1">
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            v-if="workspaceRole.id <= 2"
            outlined
            color="primary"
            small
            @click="$refs.emailInvite.show()"
          >
            Undang Pengguna
          </v-btn>
          <span
            v-else
            class="mr-2 text-subtitle-2 mt-1"
            >Tautan Undangan</span
          >
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ml-md-auto"
        >
          <div
            v-if="workspaceRole.id <= 2"
            class="d-flex"
          >
            <span class="mr-4 text-subtitle-2 mt-1">Tautan Undangan</span>
            <v-switch
              v-model="invitationSwitch"
              inset
              :loading="loading"
              class="ma-0"
              hide-details
            />
          </div>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <v-text-field
          v-if="invitationSwitch"
          v-model="inviteUrl"
          dense
          outlined
          readonly
          hide-details
        >
          <template #append>
            <div class="d-flex align-center">
              <v-icon
                class="mr-2"
                size="22"
                @click="refreshCode"
              >
                {{ icons.mdiRefresh }}
              </v-icon>
              <v-icon
                size="22"
                @click="copyClipboard"
              >
                {{ icons.mdiContentCopy }}
              </v-icon>
            </div>
          </template>
        </v-text-field>
      </v-slide-y-transition>
    </div>

    <ActiveSharedUser />

    <email-invite ref="emailInvite" />
  </v-card>
</template>
<script>
import { onOffInviteWorkspace, refreshWorkspaceInviteCode } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import ActiveSharedUser from '@/views/workspace/component/ActiveSharedUser.vue'
import { apolloClient } from '@/vue-apollo'
import {
  mdiAccountMultiplePlusOutline,
  mdiContentCopy,
  mdiDotsVertical,
  mdiLogout,
  mdiRefresh,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import Vue, { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import EmailInvite from './EmailInvite.vue'

export default defineComponent({
  components: {
    ActiveSharedUser,
    EmailInvite,
  },
  setup(props, { emit }) {
    const workspaceDetail = inject('workspaceDetail')

    const workspaceRole = computed(() => workspaceDetail.value.role)
    const loading = ref(false)
    const invitationSwitch = useStorage('invitationlink', true)
    const inviteUrl = ref('')

    const copyClipboard = () => {
      navigator.clipboard.writeText(inviteUrl.value)
      Vue.notify({
        type: 'success',
        title: 'Berhasil disalin!',
        text: 'Tautan undangan berhasil disalin',
      })
    }

    const onOffInvite = value => {
      loading.value = true
      apolloClient
        .mutate({
          mutation: onOffInviteWorkspace,
          variables: {
            open_invite: value,
            workspace_id: workspaceDetail.value.workspace.id,
          },
        })
        .then(() => {
          loading.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status invitation!',
          })
        })
        .catch(err => {
          loading.value = false
          errorHandling(err)
        })
    }

    const refreshCode = () => {
      loading.value = true
      apolloClient
        .mutate({
          mutation: refreshWorkspaceInviteCode,
          variables: {
            workspace_id: workspaceDetail.value.workspace.id,
          },
        })
        .then(() => {
          loading.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah kode undangan',
          })
          emit('refetch')
        })
        .catch(err => {
          loading.value = false
          errorHandling(err)
        })
    }

    watch(invitationSwitch, value => {
      onOffInvite(value)
    })

    onMounted(() => {
      if (workspaceDetail.value.workspace && workspaceDetail.value.workspace.invite_code) {
        inviteUrl.value = `https://dev.oriens.my.id/invite/${workspaceDetail.value.workspace.invite_code}`
      }
    })

    return {
      workspaceDetail,

      copyClipboard,
      refreshCode,
      workspaceRole,

      inviteUrl,
      invitationSwitch,
      loading,

      icons: {
        mdiAccountMultiplePlusOutline,
        mdiDotsVertical,
        mdiContentCopy,
        mdiLogout,
        mdiRefresh,
      },
    }
  },
})
</script>
