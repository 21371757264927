<template>
  <div
    :value="localValue"
    class="v-treeview v-treeview-draggable"
    @input="updateValue"
  >
    <div
      v-for="item in items"
      :key="item.key"
    >
      <multi-tree-view-node
        :value="item"
        :search="search"
        @input="updateItem"
      >
        <template #prepend="{ item: nodeItem, open }">
          <slot
            name="prepend"
            v-bind="{ item: nodeItem, open }"
          />
        </template>
        <template #label="{ item: nodeItem, open }">
          <slot
            name="label"
            v-bind="{ item: nodeItem, open }"
          />
        </template>
        <template #append="{ item: nodeItem, open }">
          <slot
            name="append"
            v-bind="{ item: nodeItem, open }"
          />
        </template>
      </multi-tree-view-node>
    </div>

    <v-btn
      v-if="isEmailSearch && !isEmailFound"
      class="mx-3 mt-2 pa-3"
      color="primary"
      width="376"
      small
      @click="handleInviteToWorkspace"
    >
      <v-icon
        size="20"
        class="me-2"
      >
        {{ icons.mdiEmailOutline }}
      </v-icon>
      <span class="d-flex align-center">
        Invite
        <span
          class="font-weight-bold text-truncate ms-1"
          style="max-width: 200px; display: inline-block"
          >{{ search }}</span
        >
      </span>
    </v-btn>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import MultiTreeViewNode from './MultiTreeViewNode.vue'
import { mdiEmailOutline } from '@mdi/js'

export default {
  components: {
    MultiTreeViewNode,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const localValue = ref({ ...props.value })
    const isEmailSearch = computed(() => {
      if (
        props.search === '' ||
        props.search === null ||
        !(props.search.includes('@') && props.search.includes('.'))
      ) {
        return false
      }

      return true
    })
    const isEmailFound = computed(() => {
      return props.items.some(item => {
        if (item.email.toLowerCase().includes(props.search.toLowerCase())) return true

        if (item.users) {
          return item.users.some(user =>
            user.email.toLowerCase().includes(props.search.toLowerCase()),
          )
        }

        return false
      })
    })

    const updateValue = newValue => {
      localValue.value = newValue
      emit('input', localValue.value)
    }
    const updateItem = itemValue => {
      const index = localValue.value.findIndex(v => v.id === itemValue.id)
      localValue.value.splice(index, 1, itemValue)
      emit('input', localValue.value)
    }

    /* TODO: implement invite to workspace */
    const handleInviteToWorkspace = () => {
      console.log(`invite ${props.search} to workspace`)
    }

    watch(
      () => props.value,
      newValue => {
        localValue.value = [...newValue]
      },
    )

    return {
      localValue,
      isEmailSearch,
      isEmailFound,
      updateValue,
      updateItem,
      handleInviteToWorkspace,

      icons: {
        mdiEmailOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
}

.v-treeview-node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}

.v-treeview-node__label {
  flex: 1;
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-treeview-node__level {
  width: 24px;
}
</style>
