<template>
  <div class="tiptap-container d-flex">
    <div
      v-if="editor"
      class="editor-canvas"
    >
      <!-- drag menu -->
      <floating-menu
        v-if="editor && checkPermission('CONTRIBUTOR')"
        id="dragMenu"
        plugin-key="dragMenu"
        :should-show="shouldShowDragMenu"
        :editor="editor"
        :class="{ isTyping: isTyping }"
        :tippy-options="dragMenuOptions"
      >
        <div
          v-if="topLevelNodeType !== 'loading'"
          style="display: flex; flex-direction: row"
          :draggable="dragging"
          @dragend="endDragging($event)"
        >
          <v-menu
            ref="dragMenuRefs"
            v-model="isDragMenuOpen"
            offset-y
            nudge-bottom="12"
            attach="#dragMenu"
            min-width="300"
          >
            <!-- <template #activator="menu">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="px-0 mx-0"
                    x-small
                    min-width="22"
                    max-width="22"
                    v-bind="{ ...menu.attrs, ...attrs }"
                    v-on="{ ...menu.on, ...on }"
                    @mousedown="startDragging($event)"
                    @mouseup="
                      draggedNodePosition = false
                      dragging = false
                    "
                  >
                    <v-icon>{{ icons.mdiDrag }}</v-icon>
                  </v-btn>
                </template>
                <div class="text-center">
                  <span>Hold for Dragging</span>
                  <br />
                  <span>Click for Menu</span>
                </div>
              </v-tooltip>
            </template> -->
            <drag-menu-list
              :editor="editor"
              :top-level-node-type="topLevelNodeType"
            />
          </v-menu>
        </div>
      </floating-menu>

      <!-- slash menu -->
      <floating-menu
        v-if="editor && checkPermission('CONTRIBUTOR')"
        id="slashMenu"
        plugin-key="slashMenu"
        :should-show="shouldShowSlashMenu"
        :editor="editor"
        :tippy-options="slashMenuOptions"
      >
        <slash-menu-list
          v-if="slashMenuInstance && slashMenuInstance.active"
          style="margin-top: 30px"
          :editor="editor"
          :instance="slashMenuInstance"
          :top-level-node-type="topLevelNodeType"
        />
      </floating-menu>

      <template v-if="isCollaborative">
        <!-- mention menu -->
        <floating-menu
          v-if="editor && checkPermission('CONTRIBUTOR')"
          id="mentionMenu"
          plugin-key="mentionMenu"
          :should-show="shouldShowMentionMenu"
          :editor="editor"
          :tippy-options="mentionMenuOptions"
        >
          <mention-menu-list
            v-if="mentionMenuInstance && mentionMenuInstance.active"
            style="margin-top: 30px"
            :editor="editor"
            :instance="mentionMenuInstance"
            :top-level-node-type="topLevelNodeType"
            :document-id="documentId"
          />
        </floating-menu>

        <!-- toolbar menu -->
        <bubble-menu
          v-if="editor && checkPermission('CONTRIBUTOR')"
          id="toolbarMenu"
          plugin-key="toolbarMenu"
          :should-show="shouldShowToolbarMenu"
          :editor="editor"
          :tippy-options="toolbarMenuOptions"
        >
          <div
            v-show="!$refs.commentMenu || !$refs.commentMenu.isOpen"
            style="display: flex; align-items: center"
            class="v-item-group v-btn-toggle bubble-menu-button-group elevation-3"
            :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'"
          >
            <font-color-button :editor="editor" />
            <highlight-color-button :editor="editor" />
            <font-format-button :editor="editor" />
            <align-tool-button :editor="editor" />
            <link-attach-button :editor="editor" />
            <inline-tool-button :editor="editor" />
            <font-family-button :editor="editor" />
            <comment-button
              ref="commentMenu"
              :editor="editor"
              :document-id="documentId"
            />
          </div>
        </bubble-menu>
      </template>

      <!-- table cell menu -->
      <bubble-menu
        v-if="editor && (tableRowTools || tableColumnTools)"
        id="tableToolbarMenu"
        plugin-key="tableBubbleMenu"
        :should-show="isTableActive"
        :editor="editor"
        :tippy-options="tableCellMenuOptions"
      >
        <table-cell-menu :editor="editor" />
      </bubble-menu>

      <!-- table row menu -->
      <!-- <bubble-menu
        v-if="editor && tableRowTools && !dragging"
        id="tableRowMenu"
        plugin-key="tableRowMenu"
        :should-show="isTableActive"
        :editor="editor"
        :tippy-options="tableRowMenuOptions"
      >
        <menu-item
          id="menuItem"
          action="Row"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="px-0 mx-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiDragVertical }}
                </v-icon>
              </v-btn>
            </template>
            Row Tools
          </v-tooltip>

          <template #dropdown>
            <v-list
              dense
              class="pt-1"
            >
              <v-subheader class="ms-1"> Row Action </v-subheader>
              <v-list-item
                v-for="tool in tableRowTools"
                :key="tool.title"
                @click="tool.command(editor)"
              >
                <v-list-item-icon>
                  <v-icon>{{ tool.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ tool.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </menu-item>
      </bubble-menu> -->

      <!-- table column menu -->
      <!-- <bubble-menu
        v-if="editor && tableColumnTools"
        id="tableColMenu"
        plugin-key="tableColumnMenu"
        :should-show="isTableActive"
        :editor="editor"
        :tippy-options="tableColMenuOptions"
      >
        <menu-item
          action="Column"
          class="menu-item"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="px-0 mx-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiDragHorizontal }}
                </v-icon>
              </v-btn>
            </template>
            Column Tools
          </v-tooltip>

          <template #dropdown>
            <v-list
              dense
              class="pt-1"
            >
              <v-subheader class="ms-1"> Row Action </v-subheader>
              <v-list-item
                v-for="tool in tableColumnTools"
                :key="tool.title"
                @click="tool.command(editor)"
              >
                <v-list-item-icon>
                  <v-icon>{{ tool.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ tool.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </menu-item>
      </bubble-menu> -->

      <!-- editor -->
      <editor-content
        id="editor"
        :editor="editor"
        :value="editor.getAttributes('textStyle').color"
        class="text--primary"
      />
    </div>
    <v-sheet
      v-else
      :color="`grey darken-2`"
      width="100%"
      class="pt-3"
    >
      <v-skeleton-loader
        class="mx-0"
        type="paragraph@3"
      />
    </v-sheet>
  </div>
</template>

<script>
// tiptap extension
import { Editor, generateHTML } from '@tiptap/core'
import { BubbleMenu, EditorContent, FloatingMenu } from '@tiptap/vue-2'
import defaultExtension from './extensions'

// collaboration
import { HocuspocusProvider } from '@hocuspocus/provider'
import Collaboration from '@tiptap/extension-collaboration'
import { Doc, applyUpdate, encodeStateAsUpdate } from 'yjs'
import { CollaborationCursor } from './extensions/collaborationCursor'

// buttons for toolbar menu
import AlignToolButton from './tools/buttons/AlignToolButton.vue'
import CommentButton from './tools/buttons/CommentButton.vue'
import FontColorButton from './tools/buttons/FontColorButton.vue'
import FontFamilyButton from './tools/buttons/FontFamilyButton.vue'
import FontFormatButton from './tools/buttons/FontFormatButton.vue'
import HighlightColorButton from './tools/buttons/HighlightColorButton.vue'
import InlineToolButton from './tools/buttons/InlineToolButton.vue'
import LinkAttachButton from './tools/buttons/LinkAttachButton.vue'

// floating-menu
import SlashMenuList from './tools/commands/SlashMenuList.vue'
import DragMenuList from './tools/floating-menu/action/DragMenuList.vue'

// utils
import { mdiDrag, mdiDragHorizontal, mdiDragVertical } from '@mdi/js'
import TableCellMenu from './tools/buttons/TableCellMenu.vue'
// import MenuItem from './tools/buttons/tableTools/MenuItem.vue'
import defaultBlockTools from './tools/utils/block-tools'
import { tableColumnTools, tableRowTools } from './tools/utils/table'
import { handleImageDrop, handleVideoDrop } from './utils/handleDrop'
import { handleImagePaste, handleVideoPaste } from './utils/handlePaste'

import usePermission from '@/composables/usePermission'
import store from '@/store'
import { randomizeColor } from '@/utils/colorSelection'
import errorHandling from '@/utils/errorHandling'
import axios from 'axios'
import { computed } from 'vue'
import { prosemirrorJSONToYDoc, ySyncPlugin } from 'y-prosemirror'
import { Comment, CommentImage } from './extensions/comment'
import DocumentMention from './extensions/documentMention'
import MentionMenuList from './tools/commands/MentionMenuList.vue'
import {
  DragNode,
  GetTableColumnCoords,
  GetTableRowCoords,
  GetTopLevelBlockCoords,
  GetTopLevelNode,
} from './utils/pm-utils'
import { mergeArrays } from './utils/utils'

export default {
  components: {
    AlignToolButton,
    BubbleMenu,
    CommentButton,
    EditorContent,
    FloatingMenu,
    DragMenuList,
    FontColorButton,
    FontFamilyButton,
    FontFormatButton,
    HighlightColorButton,
    InlineToolButton,
    LinkAttachButton,
    // MenuItem,
    SlashMenuList,
    TableCellMenu,
    MentionMenuList,
  },
  props: {
    doc: {
      type: Array,
      required: true,
    },
    editorClass: {
      type: String,
      default: null,
    },
    blockTools: {
      type: Array,
      default: () => [],
    },
    documentId: {
      type: String,
    },
    documentContent: {
      type: Object,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    isCollaborative: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiDrag,
        mdiDragVertical,
        mdiDragHorizontal,
      },
    }
  },
  data() {
    return {
      currentUser: {
        id: this.$store.getters.getUserData.id,
        name: `${this.$store.getters.getUserData.first_name} ${this.$store.getters.getUserData.last_name}`,
        color: this.getRandomColor(),
      },
      dragMenuOptions: {
        maxWidth: '350',
        placement: 'left-start',
        animation: 'fade',
        duration: 500,
        getReferenceClientRect: this.getMenuCoords,
        onCreate: instance =>
          instance.popper.classList.add(
            'max-md:!sticky',
            'max-md:!bottom-0',
            'max-md:!top-auto',
            'max-md:!transform-none',
          ),
      },
      slashMenuOptions: {
        maxWidth: '350',
        placement: 'bottom-start',
        animation: 'fade',
        duration: 500,
        getReferenceClientRect: this.getMenuCoords,
        appendTo: () => document.getElementById('editor'),
        interactive: true,
        trigger: 'manual',
        onHide: () => {
          this.slashMenuInstance = null
        },
      },
      mentionMenuOptions: {
        maxWidth: '350',
        placement: 'bottom-start',
        animation: 'fade',
        duration: 500,
        getReferenceClientRect: this.getMenuCoords,
        appendTo: () => document.getElementById('editor'),
        interactive: true,
        trigger: 'manual',
        onHide: () => {
          this.mentionMenuInstance = null
        },
      },
      tableCellMenuOptions: {
        duration: 500,
        placement: 'top-start',
        getReferenceClientRect: this.getMenuCoords,
        appendTo: () => document.getElementById('editor'),
        zIndex: 1,
      },
      tableRowMenuOptions: {
        placement: 'right',
        animation: 'fade',
        zIndex: 1,
        duration: 500,
        appendTo: () => document.getElementById('editor'),
        getReferenceClientRect: this.getTableRowMenuCoords,
      },
      tableColMenuOptions: {
        placement: 'bottom',
        animation: 'fade',
        duration: 500,
        zIndex: 1,
        appendTo: () => document.getElementById('editor'),
        getReferenceClientRect: this.getTableColumnMenuCoords,
      },
      toolbarMenuOptions: {
        duration: 300,
        placement: 'top-start',
        zIndex: 500,
        appendTo: () => document.getElementById('editor'),
      },
      allBlockTools: mergeArrays(defaultBlockTools(), this.blockTools),
      awareness: '',
      currentBlockTool: null,
      currentNodeType: null,
      draggedNodePosition: null,
      dragging: false,
      editor: null,
      initEditor: false,
      isDragMenuOpen: false,
      isEditable: true,
      isTyping: false,
      provider: null,
      slashMenuInstance: null,
      mentionMenuInstance: null,
      status: 'disconnect',
      tableColumnTools: tableColumnTools(),
      tableRowTools: tableRowTools(),
      topLevelNodeType: null,
      total: 0,
      users: '',
    }
  },
  computed: {
    activeAlignmentTools() {
      if (this.topLevelNodeType) {
        return this.allAlignmentTools.filter(alignmentToolGroup =>
          alignmentToolGroup.find(tool => tool.isActiveTest(this.editor, this.topLevelNodeType)),
        )
      }

      return null
    },
  },
  watch: {
    isEditable(value) {
      this.editor.setEditable(value)
    },
    topLevelNodeType() {
      this.currentBlockTool = this.getCurrentBlockTool()
    },
  },
  mounted() {
    this.initDocument()

    document.addEventListener('drag-handle-clicked', this.showDragMenu)
  },
  beforeDestroy() {
    document.removeEventListener('drag-handle-clicked', this.showDragMenu)
  },
  inject: ['documentDetail'],
  methods: {
    checkPermission(permissionType) {
      // Skip checking permission when component is used only as editor / input field
      if (!this.isCollaborative) return

      const userId = store.getters.getUserData.id
      const { documentPermissionList } = usePermission()

      if (this.documentDetail.created_by.id === userId) {
        return true
      }

      const userPermission = this.documentDetail.document_permissions.find(
        el => el.user.id === userId,
      )

      if (!userPermission) {
        return false
      }

      const permissionIndex = documentPermissionList.findIndex(
        permission => permission.value === permissionType,
      )
      const userPermissionIndex = documentPermissionList.findIndex(
        permission => permission.value === userPermission.permission_type,
      )

      return userPermissionIndex <= permissionIndex
    },
    showDragMenu(event) {
      this.isDragMenuOpen = event.detail.isOpen
    },
    initDocument() {
      if (this.provider) {
        this.provider.destroy()
        this.provider = null
      }
      if (this.ydoc) {
        this.ydoc.destroy()
        this.ydoc = null
      }
      if (this.editor) {
        this.editor.destroy()
        this.editor = null
      }

      const ydoc = new Doc()
      if (this.doc.length) applyUpdate(ydoc, Uint8Array.from(this.doc))
      const userData = computed(() => store.state.auth.userData)

      /**
       * ? Quick rundown,
       * ---
       * This "only-editor" mode requires an emit to update the parent value and to store the concurrent yjs
       */
      if (!this.isCollaborative && !this.editor) {
        this.editor = new Editor({
          editable: true,

          extensions: [...defaultExtension, ySyncPlugin(ydoc.getXmlFragment('prosemirror'))],
          content: ``,

          onCreate: ({ editor }) => {
            if (this.documentContent) editor.commands.setContent(this.documentContent)
          },

          onUpdate: ({ editor }) => {
            const getEditorJSON = editor.getJSON()

            this.$emit(
              'update:yjs',
              encodeStateAsUpdate(prosemirrorJSONToYDoc(editor.schema, getEditorJSON)),
            )

            this.$emit('update:content', getEditorJSON)
          },
        })

        return
      }

      this.provider = new HocuspocusProvider({
        url: process.env.VUE_APP_HOCUS_WS,
        name: this.documentId,
        document: ydoc,
        token: localStorage.getItem('token'),
        onAwarenessChange: ({ states }) => {
          this.awareness = this.filterUsers(states)
          this.users = this.awareness
          this.total = this.awareness.length
          this.$emit('update:dataUsers', this.users)
        },
        onStatus: ({ status }) => {
          if (status === 'disconnected') {
            this.status = 'unauthorized'
          } else {
            this.status = status
          }
        },
        onSynced: () => {
          if (!this.editor) {
            const { documentId } = this
            this.editor = new Editor({
              editable: this.editable,
              extensions: [
                ...defaultExtension,
                Comment,
                CommentImage,
                Collaboration.configure({ document: this.provider.document }),
                CollaborationCursor.configure({ provider: this.provider, user: this.currentUser }),
                DocumentMention.configure({ currentUser: userData.value.id }),
              ],
              editorProps: {
                handleDrop(view, event, slice, moved) {
                  if (
                    !moved &&
                    event.dataTransfer &&
                    event.dataTransfer.files &&
                    event.dataTransfer.files[0]
                  ) {
                    const file = event.dataTransfer.files[0]
                    const fileSize = (file.size / 1024 / 1024).toFixed(4)

                    if (file.type === 'image/jpeg' || file.type === 'image/png') {
                      const LimitSize = 5
                      if (fileSize < 5) {
                        try {
                          handleImageDrop(view, event, file, documentId)
                        } catch (err) {
                          errorHandling(err)
                        }
                      } else {
                        errorHandling(new Error(`Max Image size is ${LimitSize} mb`))
                      }
                    }

                    if (file.type === 'video/mp4') {
                      const LimitSize = 100
                      if (fileSize < LimitSize) {
                        handleVideoDrop(view, event, file, documentId)
                      } else {
                        errorHandling(new Error(`Max Video size is ${LimitSize} mb`))
                      }
                    }

                    return true
                  }

                  return false
                },
                handlePaste(view, event) {
                  if (
                    event.clipboardData &&
                    event.clipboardData.files &&
                    event.clipboardData.files[0]
                  ) {
                    const file = event.clipboardData.files[0]
                    const fileSize = (file.size / 1024 / 1024).toFixed(4)

                    if (file.type === 'image/jpeg' || file.type === 'image/png') {
                      const LimitSize = 5
                      if (fileSize < 5) {
                        try {
                          handleImagePaste(view, file, documentId)
                        } catch (err) {
                          errorHandling(err)
                        }
                      } else {
                        errorHandling(new Error(`Max Image size is ${LimitSize} mb`))
                      }
                    }

                    if (file.type === 'video/mp4') {
                      const LimitSize = 100
                      if (fileSize < LimitSize) {
                        handleVideoPaste(view, file, documentId)
                      } else {
                        errorHandling(new Error(`Max Video size is ${LimitSize} mb`))
                      }
                    }

                    return true
                  }

                  return false
                },
              },
              onUpdate: () => {
                this.updateToolbar()
              },
              onSelectionUpdate: () => {
                this.updateToolbar()
              },
            })
          }
        },
      })
    },
    shouldShowDragMenu() {
      return true
    },
    shouldShowSlashMenu({ state }) {
      const { active, text } = state.suggestion$
      this.slashMenuInstance = state.suggestion$

      return active && text[0] === '/'
    },
    shouldShowMentionMenu({ state }) {
      const { active, text } = state.mentionSuggestion$
      this.mentionMenuInstance = state.mentionSuggestion$

      return active && text[0] === '@'
    },
    shouldShowToolbarMenu({ state }) {
      if (this.isDragMenuOpen) return false
      if (state.selection.empty) {
        this.$refs.commentMenu.isOpen = false

        return false
      }
      const excludedNodeTypes = [
        // 'image',
        'codeBlock',
        'bookmark',
        'loading',
        // 'video',
        'horizontalRule',
        'youtube',
        'Page',
        'toggle',
      ]

      return !excludedNodeTypes.includes(this.currentNodeType)
    },

    isTableActive() {
      return this.getTopLevelNodeType() === 'table'
    },
    isOverflown(coord) {
      // prevent menu from going out of the screen
      // needs to be extracted out from this
      // if you want this component to be reusable
      const { top, bottom } = document
        .querySelector('.document-main-content')
        .getBoundingClientRect()

      return coord.bottom > bottom || coord.top < top
    },

    getTopLevelNodeType() {
      const child = this.editor.view.state.selection
      if (child.node !== undefined) this.currentNodeType = child.node.type.name
      else this.currentNodeType = GetTopLevelNode(this.editor.view)?.type.name

      return GetTopLevelNode(this.editor.view)?.type.name
    },
    getCurrentBlockTool() {
      return this.allBlockTools.find(tool => {
        if (tool.name === this.topLevelNodeType) {
          return true
        }
        if (tool.tools && tool.tools.length > 0) {
          return (
            tool.tools.find(childTool => childTool.name === this.topLevelNodeType) !== undefined
          )
        }

        return false
      })
    },
    getMenuCoords() {
      const coord = GetTopLevelBlockCoords(this.editor.view)

      if (this.isOverflown(coord)) {
        // workaround for now
        return {
          bottom: -1000,
          height: -1000,
          left: -1000,
          right: -1000,
          top: -1000,
          width: -1000,
          x: -1000,
          y: -1000,
        }
      }

      const val = coord.left - 4
      const updatedCoord = {
        bottom: coord.bottom,
        height: coord.height,
        left: val,
        right: coord.right,
        top: coord.top,
        width: coord.width,
        x: coord.x,
        y: coord.y,
      }

      return updatedCoord
    },
    getRandomColor() {
      return randomizeColor().hex
    },
    getTableRowMenuCoords() {
      const coord = GetTableRowCoords(this.editor.view)
      if (this.isOverflown(coord)) {
        // workaround for now
        return {
          bottom: -1000,
          height: -1000,
          left: -1000,
          right: -1000,
          top: -1000,
          width: -1000,
          x: -1000,
          y: -1000,
        }
      }

      return coord
    },
    getTableColumnMenuCoords() {
      const coord = GetTableColumnCoords(this.editor.view)
      if (this.isOverflown(coord)) {
        // workaround for now
        return {
          bottom: -1000,
          height: -1000,
          left: -1000,
          right: -1000,
          top: -1000,
          width: -1000,
          x: -1000,
          y: -1000,
        }
      }

      return coord
    },

    updateToolbar() {
      this.topLevelNodeType = this.getTopLevelNodeType()
    },
    filterUsers(dataMap) {
      const dataArray = Array.from(dataMap.values())

      if (!dataArray.user) return []

      const mapBaru = new Map()
      dataArray.forEach(value => {
        const userId = value.user.id
        if (!mapBaru.has(userId)) {
          mapBaru.set(userId, value)
        }
      })

      return mapBaru
    },
    startDragging(event) {
      if (this.topLevelNodeType === 'image') {
        this.dragging = false

        return
      }
      const coords = { left: event.clientX + 48, top: event.clientY }
      if (this.editor.view.posAtCoords(coords)) {
        this.draggedNodePosition = this.editor.view.posAtCoords(coords).pos
        this.dragging = true
      }
    },
    endDragging(event) {
      const targetNodeFromCoords = this.editor.view.posAtCoords({
        left: event.clientX + 20,
        top: event.clientY,
      })
      if (targetNodeFromCoords) {
        DragNode({
          view: this.editor.view,
          state: this.editor.state,
          draggedNodePosition: this.draggedNodePosition,
          targetNodePosition: targetNodeFromCoords.pos,
        })
      }
      this.dragging = false
      this.draggedNode = null
    },
    handleExportPDF(title) {
      const cssUrl = 'https://dev.oriens.my.id/styles/editor.min.css'
      const apiUrl = 'https://oriensstaging.oriens.my.id/document/export-pdf'
      const body = generateHTML(this.editor.getJSON(), [...defaultExtension])

      fetch(cssUrl)
        .then(response => response.text())
        .then(css => {
          const html = `
            <!DOCTYPE html>
            <html lang="en">
              <head>
                <style>
                  ${css}
                </style>
              </head>
              <body class="tiptap">
                <h1 class="title">${title}</h1>
                ${body}
              </body>
            </html>
          `

          axios
            .post(
              apiUrl,
              {
                html,
                title: 'Parent',
              },
              {
                responseType: 'blob',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then(res => {
              const url = window.URL.createObjectURL(new Blob([res.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${title}.pdf`)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/tiptap-style.scss';

.tiptap-container {
  .collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    word-break: normal;
    pointer-events: none;
  }

  .collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0d0d0d;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
  }

  .inline-comment {
    background-color: #ffd50030;
    color: inherit;
    border-bottom: 2px solid rgb(255, 212, 0);
  }

  img.inline-comment {
    background: rgba(255, 213, 0, 0.1);
  }

  .inline-comment.hovered {
    background-color: #ffd5006b;
  }

  .inline-comment.focused {
    background-color: #ffd5006b;
  }

  .isTyping {
    pointer-events: none;
    opacity: 0;
  }

  #tableToolbarMenu {
    margin-left: 4px;
  }

  #tableRowMenu {
    margin-left: -25px;
  }

  #tableColMenu {
    margin-top: -26px;
  }

  .details {
    display: flex;
    margin: 1rem 0;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 0.5rem;

    > button {
      display: flex;
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;

      &::before {
        content: '\25B6';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
      }
    }

    &.is-open > button::before {
      content: '\25BC';
    }

    > div {
      flex: 1 1 auto;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
