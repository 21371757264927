import {
  mdiAccountAlertOutline,
  mdiAccountMinusOutline,
  mdiAccountMultipleMinusOutline,
  mdiAccountMultiplePlusOutline,
  mdiAccountPlusOutline,
  mdiAlertOctagonOutline,
  mdiAt,
  mdiCalendarAlert,
  mdiCheckCircleOutline,
  mdiClockAlertOutline,
  mdiEyeMinusOutline,
  mdiEyePlusOutline,
  mdiFolderOutline,
  mdiInformationOutline,
  mdiTimelineAlertOutline,
  mdiTimerOutline,
} from '@mdi/js'

export const filterList = [
  {
    header: 'Assigned, Unassign, & Mention saya',
  },
  {
    id: 1,
    label: 'Assignment',
    icon: mdiAccountPlusOutline,
    message: '{ User Z } men-assign { U8 } kepada { User A }',
  },
  {
    id: 5,
    label: 'Unassignment',
    icon: mdiAccountMinusOutline,
    message: '{ User Z } men-unassign { User A } dengan { U8 }',
  },
  {
    id: 2,
    icon: mdiAt,
    label: 'Mention',
    message:
      '{ User X } men-mention { User Y } di sebuah { post } / { description } / { comment } / { attachment_post }',
  },
  {
    id: 11,
    icon: mdiEyePlusOutline,
    label: 'Watcher Added',
    message:
      '{ User F } telah di menetapkan Anda sebagai watcher untuk { job.name } / { document.name }',
  },
  {
    id: 12,
    icon: mdiEyeMinusOutline,
    label: 'Watcher Removed',
    message:
      '{ User F } telah di memberhentikan Anda sebagai watcher untuk { job.name } / { document.name }',
  },

  {
    header: 'Dibagikan kepada saya',
  },
  {
    id: 13,
    label: ' Deadline Missed (Assignee)',
    icon: mdiTimerOutline,
    message: 'Batas waktu yang ditetapkan untuk { job.name } telah dilewati',
  },
  {
    id: 14,
    label: ' Deadline Missed (Watcher)',
    icon: mdiCalendarAlert,
    message: '{ job.name } telah melewati batas waktu yang telah ditetapkan',
  },
  {
    id: 4,
    label: 'Date Change',
    icon: mdiFolderOutline,
    message: '{ User X } men-set tanggal akhir dari 7 Mar 2023 menjadi 9 Mar 2023',
  },
  {
    id: 15,
    label: 'Before Start Date (Assignee)',
    icon: mdiTimelineAlertOutline,
    message: 'ditunjukan kepada Assignee untuk [ start_date ]',
  },
  {
    id: 16,
    label: 'Before Start Date (Watcher)',
    icon: mdiAccountAlertOutline,
    message: 'ditunjukan kepada Watcher / Owner untuk [ start_date ]',
  },
  {
    id: 17,
    label: 'Before End Date (Asignee)',
    icon: mdiClockAlertOutline,
    message: 'ditunjukan kepada Watcher / Owner untuk [ end_date ]',
  },
  {
    id: 18,
    label: 'Before End Date (Watcher)',
    icon: mdiAccountAlertOutline,
    message: 'ditunjukan kepada Watcher / Owner untuk [ end_date ]',
  },

  {
    header: 'Documents, Workspaces, & Teams',
  },
  {
    id: 6,
    icon: mdiAccountMultiplePlusOutline,
    label: 'Invited to Team / Workspace',
    message:
      '{ User A } menambahkan { Anda } / { User B } kedalam { teams.name } / { workspace.name }',
  },
  {
    id: 7,
    icon: mdiAccountMultipleMinusOutline,
    label: 'Invited from Team / Workspace',
    message:
      '{ User C } mengeluarkan { Anda } / { User D } kedalam { teams.name } / { workspace.name }',
  },
  {
    id: 8,
    label: 'Request to Join',
    icon: mdiInformationOutline,
    message: '{ User E } meminta untuk bergabung dengan { teams.name } / { workspace.name }',
  },
  {
    id: 9,
    icon: mdiCheckCircleOutline,
    label: 'Invitation Accepted',
    message: 'Permintaan anda untuk bergabung dengan { teams.name } / { workspace.name } diterima',
  },
  {
    id: 10,
    label: 'Invitation Rejected',
    icon: mdiAlertOctagonOutline,
    message: 'Permintaan anda untuk bergabung dengan { teams.name } / { workspace.name } ditolak',
  },

  // {
  //   id: 3,
  //   label: 'Unknown',
  //   icon: mdiHelp,
  //   message: 'UNKNOWN, left previously in notes -> { category_id = 3 }',
  // },
]

export const categoryMap = {
  1: {
    label: 'Assignment',
    icon: mdiAccountPlusOutline,
    message: 'Job ini telah ditugaskan kepada seseorang.',
  },
  2: {
    label: 'Mention',
    icon: mdiAt,
    message: 'Seseorang menyebut Pengguna dalam sebuah deskripsi, komentar, atau Attachment.',
  },
  4: {
    label: 'Date Change',
    icon: mdiFolderOutline,
    message: 'Tanggal untuk Job ini telah diubah.',
  },
  5: {
    label: 'Unassignment',
    icon: mdiAccountMinusOutline,
    message: 'Job ini telah dibatalkan untuk ditugaskan kepada seseorang.',
  },
  6: {
    label: 'Addition',
    icon: mdiAccountMultiplePlusOutline,
    message: 'Seseorang telah menambahkan Pengguna lain kedalam Team ini.',
  },
  7: {
    label: 'Removal',
    icon: mdiAccountMultipleMinusOutline,
    message: 'Seseorang telah menghapus Pengguna lain kedalam Team ini.',
  },
  8: {
    label: 'Join Request',
    icon: mdiInformationOutline,
    message: 'Seseorang meminta untuk bergabung dengan Team ini.',
  },
  9: {
    label: 'Join Accepted',
    icon: mdiCheckCircleOutline,
    message: 'Permintaan Anda untuk bergabung dengan Team ini telah diterima.',
  },
  10: {
    label: 'Join Rejected',
    icon: mdiAlertOctagonOutline,
    message: 'Permintaan Anda untuk bergabung dengan Team ini telah ditolak.',
  },
  11: {
    label: 'Watcher Added',
    icon: mdiEyePlusOutline,
    message: 'Seseorang telah ditetapkan sebagai Watcher untuk Job.',
  },
  12: {
    label: 'Watcher Removed',
    icon: mdiEyeMinusOutline,
    message: 'Seseorang telah dihapus sebagai Watcher untuk Job.',
  },
  13: {
    label: 'Deadline Missed',
    icon: mdiTimerOutline,
    message: 'Batas waktu untuk Job ini telah terlewati.',
  },
  14: {
    label: 'Deadline Passed',
    icon: mdiCalendarAlert,
    message: 'Job ini telah melewati batas waktu yang ditetapkan.',
  },
  15: {
    label: 'Before Start Date (Assignee)',
    icon: mdiTimelineAlertOutline,
    message: 'Pengingat untuk Assignee Job ini sebelum tanggal mulai.',
  },
  16: {
    label: 'Before Start Date (Watcher)',
    icon: mdiAccountAlertOutline,
    message: 'Pengingat untuk Watcher atau Owner dari Job ini sebelum tanggal mulai.',
  },
  17: {
    label: 'Before End Date (Assignee)',
    icon: mdiClockAlertOutline,
    message: 'Pengingat untuk Assignee Job ini sebelum tanggal selesai.',
  },
  18: {
    label: 'Before End Date (Watcher)',
    icon: mdiAccountAlertOutline,
    message: 'Pengingat untuk Watcher atau Owner dari Job ini sebelum tanggal selesai.',
  },
}

export const messageList = {
  1: 'membuat',
  11: 'mengubah deskripsi',
  13: 'men-archive',
  14: 'men-unarchive',
  15: 'menghapus',
  4: 'tanggal akhir',
  5: 'tanggal mulai',
}
