<template>
  <v-card
    height="350"
    width="400"
    class="document-selector-container elevation-0"
  >
    <chip-search-input
      class="py-2 px-3"
      :selected-object-ids="selectedDocument"
      :object-list="documentList"
      object-type="document"
      :width="400"
      :search.sync="search"
      :autoFocus="true"
      @input-search="search = $event"
      @remove="handleChipDelete"
      @clear="selectedDocument = []"
    />

    <v-divider />

    <v-list
      v-if="!loadingDocument"
      dense
      class="pt-1 document-selector-list"
      style="outline: none"
      two-line
      height="100%"
    >
      <v-list-item
        v-for="(item, index) in filteredDocuments"
        :key="item.uuid"
        :value="item.uuid"
        :id="`document-${index}`"
        style=""
      >
        <v-tooltip
          top
          z-index="9999999"
        >
          <template #activator="{ on, attrs }">
            <v-list-item-action>
              <v-simple-checkbox
                :value="selectedDocumentId.includes(item.uuid)"
                :disabled="singleSelect && selectedDocument && selectedDocument?.uuid !== item.uuid"
                hide-details
                class="my-0 ps-1 py-0 mx-auto"
                color="primary"
                dense
                @input="$event => updateSelectedDocument(item, $event)"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle v-if="item.parent">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="font-weight-medium"
                  >{{ formatParentHierarchy(item.parent) }}</span
                >
              </v-list-item-subtitle>
              <v-list-item-title class="p-1 d-flex align-center">
                {{ item.name.length >= 50 ? item.name.slice(0, 50) + '...' : item.name }}
                <v-icon
                  v-if="!item.is_public"
                  small
                  class="ml-2"
                  >{{ mdiLockOutline }}</v-icon
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span class="font-weight-medium">{{ formatParentHierarchy(item.parent) }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>

    <v-skeleton-loader
      v-else
      type="list-item-avatar"
      style="width: 100%"
    ></v-skeleton-loader>
  </v-card>
</template>

<script setup>
import ChipSearchInput from './user-team-selector/components/ChipSearchInput.vue'

import useDocument from '@/composables/useDocument'
import { mdiLockOutline } from '@mdi/js'
import { computed, onMounted, ref, watch } from 'vue'

const { documentList, loadingDocument, fetchDocumentParentHierarchy } = useDocument()
const emit = defineEmits(['update:selectedDocuments'])
const { value, singleSelect } = defineProps({
  value: {
    type: Array,
  },
  singleSelect: {
    type: Boolean,
    default: false,
  },
})

const selectedDocument = ref(singleSelect ? {} : [])
const search = ref('')
const isLoading = ref(false)

const selectedDocumentId = computed(() =>
  singleSelect
    ? selectedDocument.value.uuid
    : selectedDocument.value.map(document => document.uuid),
)

const filteredDocuments = computed({
  get() {
    return documentList.value.filter(e => {
      return e.name.toLowerCase().includes(search.value.toLowerCase())
    })
  },
})

const determineSelectedDocument = () =>
  new Promise(resolve => {
    selectedDocument.value = singleSelect
      ? documentList.value.find(document => document.uuid === value.uuid)
      : documentList.value.filter(document =>
          value.map(object => object.uuid).includes(document.uuid),
        )

    resolve()
  })

const formatParentHierarchy = parent => {
  if (!parent) return ''
  let hierarchy = parent.name
  let currentParent = parent.parent
  while (currentParent) {
    hierarchy = `${currentParent.name} > ${hierarchy}`
    currentParent = currentParent.parent
  }

  return hierarchy
}

const handleChipDelete = documentUUID =>
  (selectedDocument.value = singleSelect
    ? undefined
    : selectedDocument.value.filter(uuid => uuid !== documentUUID))

const updateSelectedDocument = (data, value) => {
  selectedDocument.value = singleSelect
    ? documentList.value.find(document => (value ? document.uuid === data.uuid : undefined)) || {}
    : value
    ? Array.from([
        ...selectedDocument.value,
        ...documentList.value.filter(document => document.uuid === data.uuid),
      ])
    : selectedDocument.value.filter(document => document.uuid !== data.uuid)
}

watch(selectedDocument, val => {
  if (isLoading.value) return

  emit('update:selectedDocuments', val)
})

onMounted(() => {
  fetchDocumentParentHierarchy({ rootOnly: false, filterSearchChildren: true }).then(() => {
    if (value?.length > 0) {
      isLoading.value = true

      determineSelectedDocument().then(() => (isLoading.value = false))
    }
  })
})
</script>
<style lang="scss">
@import '~@/styles/treeselect.scss';
@import '~@core/preset/preset/mixins.scss';

.document-selector-container {
  .document-selector-list {
    @include style-scroll-bar();
    overflow-y: auto;
  }
}
</style>
