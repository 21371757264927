import {
  mdiApps,
  mdiArchiveOutline,
  mdiBriefcaseOutline,
  mdiCalendarMinusOutline,
  mdiCalendarPlusOutline,
  mdiCheckCircleOutline,
  mdiCommentBookmarkOutline,
  mdiCommentTextOutline,
  mdiFileOutline,
  mdiFolderOutline,
  mdiFormatListBulletedType,
  mdiHistory,
  mdiPaperclip,
  mdiSelectAll,
  mdiStarOutline,
  mdiUpdate,
} from '@mdi/js'

export const sortList = [
  {
    id: 'best_matches',
    label: 'Best Matches',
    icon: mdiStarOutline,
  },
  {
    id: 'last_edited_newest',
    label: 'Last Edited Newest',
    icon: mdiUpdate,
  },
  {
    id: 'last_edited_oldest',
    label: 'Last Edited Oldest',
    icon: mdiHistory,
  },
  {
    id: 'created_newest',
    label: 'Created Newest',
    icon: mdiCalendarPlusOutline,
  },
  {
    id: 'created_oldest',
    label: 'Created Oldest',
    icon: mdiCalendarMinusOutline,
  },
]

export const objectList = [
  {
    id: 'all',
    label: 'Show All Object',
    icon: mdiApps,
  },
  {
    id: 'folder',
    label: 'Folder',
    icon: mdiFolderOutline,
  },
  {
    id: 'job type',
    label: 'Job Type',
    icon: mdiFormatListBulletedType,
  },
  {
    id: 'job',
    label: 'Job',
    icon: mdiBriefcaseOutline,
  },
  {
    id: 'job comment',
    label: 'Job  →  Comment',
    icon: mdiCommentTextOutline,
  },
  {
    id: 'document',
    label: 'Document',
    icon: mdiFileOutline,
  },
  {
    id: 'document comment',
    label: 'Document  →  Comment',
    icon: mdiCommentBookmarkOutline,
  },
  {
    id: 'attachment',
    label: 'Attachment',
    icon: mdiPaperclip,
  },
]

export const objectMap = {
  folder: {
    label: 'Folder',
    icon: mdiFolderOutline,
    route: 'folder-detail',
  },
  'job type': {
    label: 'Job Type',
    icon: mdiFormatListBulletedType,
    route: 'jobtype-detail',
  },
  job: {
    label: 'Job',
    icon: mdiBriefcaseOutline,
    route: 'job-detail',
  },
  'job comment': {
    label: 'Job Comment',
    icon: mdiCommentTextOutline,
    route: 'job-detail',
  },
  document: {
    label: 'Document',
    icon: mdiFileOutline,
    route: 'document-detail',
  },
  'document comment': {
    label: 'Document Comment',
    icon: mdiCommentBookmarkOutline,
    route: 'document-detail',
  },
  attachment: {
    label: 'Attachment',
    icon: mdiPaperclip,
    route: 'attachment-detail',
  },
}

export const archiveStatusList = [
  {
    id: 0,
    label: 'Not Archived',
    icon: mdiCheckCircleOutline,
  },
  {
    id: 1,
    label: 'Archived',
    icon: mdiArchiveOutline,
  },
  {
    id: 2,
    label: 'Show All',
    icon: mdiSelectAll,
  },
]
