import gql from 'graphql-tag'

const profileInfo = gql`
  query profileInfo {
    profileInfo {
      id
      phone
      email
      name
      first_name
      last_name
      photo
      is_guest
      availability
      last_seen_at
      created_at
      updated_at
    }
  }
`

export default profileInfo
