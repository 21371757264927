import gql from 'graphql-tag'

const globalSearch = gql`
  query GlobalSearch(
    $search: String!
    $object: String!
    $archiveStatus: Float!
    $sort: String!
    $pagination: pagination!
    $createdBy: [Float!]
    $assignedTo: [Float!]
    $workspaceId: Float!
  ) {
    globalSearch(
      search: $search
      object: $object
      archive_status: $archiveStatus
      sort: $sort
      pagination: $pagination
      created_by: $createdBy
      assigned_to: $assignedTo
      workspace_id: $workspaceId
    ) {
      id
      name
      description
      type
      created_at
      updated_at
      folder {
        name
        color
        is_public
      }
      jobtype {
        name
        color
        is_public
      }
      workspace {
        name
      }
    }
  }
`

export default globalSearch
