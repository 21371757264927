<template>
  <v-form
    v-if="!isLoading"
    ref="permissionForm"
    @submit.prevent="onSubmit"
  >
    <v-row
      class="mt-0"
      no-gutters
    >
      <v-col
        cols="12"
        md="4"
      >
        <div class="mb-1">
          <span class="text-caption">Hak akses</span>
        </div>
        <v-btn
          min-height="110"
          min-width="110"
          outlined
          class="me-4"
          :text="!localValue?.is_public"
          :color="localValue?.is_public ? 'primary' : null"
          @click="localValue.is_public = true"
        >
          <div class="d-flex flex-column align-center">
            <v-icon
              class="text--disabled mb-2"
              size="40"
            >
              {{ icons.mdiAccountGroup }}
            </v-icon>
            <span :class="localValue?.is_public ? '' : 'text--disabled'">Publik</span>
          </div>
        </v-btn>
        <v-btn
          min-height="110"
          min-width="110"
          outlined
          :text="localValue?.is_public"
          :color="!localValue?.is_public ? 'primary' : null"
          @click="localValue.is_public = false"
        >
          <div class="d-flex flex-column align-center">
            <v-icon
              class="text--disabled mb-2"
              size="40"
            >
              {{ icons.mdiShieldLock }}
            </v-icon>
            <span :class="!localValue?.is_public ? '' : 'text--disabled'">Kustom</span>
          </div>
        </v-btn>
      </v-col>

      <v-col
        v-if="!localValue?.is_public"
        cols="12"
        md="8"
      >
        <div class="mb-1">
          <span class="text-caption">User Permission</span>
        </div>

        <div class="autocomplete-field pa-0">
          <chip-search-input
            v-if="userList.length !== 0"
            class="pt-1 pb-2 px-3"
            object-type="user"
            :width="400"
            :max-items="12"
            :search.sync="search"
            :autoFocus="true"
            @input-search="search = $event ?? ''"
            @remove="handleChipDelete"
            @clear="selectedUser = []"
            :selected-object-ids="selectedUser.map(user => user?.id)"
            :object-list="userList"
            :can-chip-be-deleted="
              object => {
                const isAdmin = findUserPermission(object?.id)?.permission_type === 'Admin'
                const isOwner = object?.id === objectDetail?.owner?.id
                const isSelf = object?.id === currentUserId

                return !isAdmin && !isOwner && !isSelf
              }
            "
          />
        </div>
      </v-col>
    </v-row>

    <div class="mt-4 mb-1">
      <div class="mb-1">
        <span class="text-caption">Pemilik Folder</span>
      </div>
      <v-avatar
        size="30"
        class="mr-2 v-avatar-light-bg"
      >
        <v-img
          v-if="objectDetail?.owner?.photo"
          :src="objectDetail?.owner?.photo"
        />
        <span v-else>{{ avatarText(objectDetail?.owner?.name) }}</span>
      </v-avatar>
      <span>{{ objectDetail?.owner?.name }}</span>
    </div>

    <template v-if="!objectDetail?.is_public">
      <TreeUserSelector
        disable-team-utility
        width="100%"
        :value="selectedUser"
        :search-value="search"
        :object-owner="objectDetail?.owner"
        :return-only-select="['permission_type', 'id']"
        :permission-list="objectDetail[permissionAccessor[objectType].target]"
        :user-disabled-rule="
          item => {
            const isOwner = item.id === objectDetail?.owner?.id
            const isAdmin = item.permission_type === 1
            const isCurrentUser = item.id === currentUserId

            if (objectDetail?.owner?.id === currentUserId) return false

            if (lookupCurrentUserPermisison?.permission_type === 'Admin' && !isOwner && !isAdmin)
              return false

            return isOwner || isAdmin || isCurrentUser
          }
        "
        @input="selectedUser = $event"
      >
        <template #append="{ item, user }">
          <div
            v-if="item.id == objectDetail?.owner?.id"
            style="max-width: 150px"
          >
            <v-select
              :disabled="true"
              item-text="label"
              item-value="value"
              placeholder="Owner"
              dense
              outlined
              hide-details="auto"
              class="my-0"
            />
          </div>

          <div
            v-else-if="selectedUser.find(user => user?.id === item.id)"
            style="max-width: 150px"
          >
            <v-select
              v-model="user.permission_type"
              :items="
                folderPermissionList.filter(permission => {
                  const isNotOwner = currentUserId !== objectDetail?.owner?.id
                  const isNotSuperAdmin = user.permission_type !== 1

                  return isNotOwner && isNotSuperAdmin ? permission.id !== 1 : true
                })
              "
              :disabled="currentUserId !== objectDetail?.owner?.id && item.is_disabled"
              item-text="label"
              item-value="id"
              placeholder="Permission"
              dense
              outlined
              hide-details="auto"
              class="my-0"
              @change="handleChangePermission($event, item.id)"
            />
          </div>
        </template>
      </TreeUserSelector>
    </template>
  </v-form>

  <div
    v-else
    class="d-flex align-center justify-center"
    style="min-height: 500px"
  >
    <v-progress-circular
      indeterminate
      size="20"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import ChipSearchInput from '@/components/inputs/user-team-selector/components/ChipSearchInput.vue'
import TreeUserSelector from '@/components/inputs/user-team-selector/components/treeview/TreeUserSelector.vue'
import useFolder from '@/composables/useFolder'
import useJob from '@/composables/useJob'
import { folderPermissionList, permissionAccessor } from '@/constants/permission'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { avatarText } from '@core/utils/filter'
import { mdiAccountGroup, mdiShieldLock } from '@mdi/js'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

export default {
  components: {
    ChipSearchInput,
    TreeUserSelector,
  },

  props: {
    objectDetail: {
      type: Object,
      default: undefined,
    },
    objectType: {
      type: String,
      validator: value => ['folder', 'job-type'].includes(value),
    },
  },

  setup(props, { emit }) {
    const isLoading = ref(true)

    const { updateFolderPermission, loadingEditFolder } = useFolder()
    const { updateJobTypePermission, loadingEditJobType } = useJob()

    const search = ref('')
    const permissionForm = ref()
    const localValue = ref(props.objectDetail)
    const currentUserId = computed(() => store.getters.getUserData.id)
    const lookupCurrentUserPermisison = computed(() =>
      props.objectDetail[permissionAccessor[props.objectType].target].find(
        object => object?.user?.id === currentUserId.value || object?.id === currentUserId.value,
      ),
    )

    const selectedUser = ref([])

    const loadingSubmit = computed(
      () => isLoading.value || loadingEditFolder.value || loadingEditJobType.value,
    )
    const userList = computed(() => store.getters.getCurrentWorkspaceUserList)

    const reset = () => {
      localValue.value = props.objectDetail

      permissionForm.value.reset()
    }

    const submitForm = () => {
      if (permissionForm.value.validate()) {
        switch (props.objectType) {
          case 'folder':
            updateFolderPermission({
              id: props.objectDetail.id,
              isPublic: localValue.value.is_public,
              userPermissions: selectedUser.value.map(object => ({
                user_id: object.id,
                permission_type: object.permission_type,
              })),
            })
            break

          case 'job-type':
            updateJobTypePermission({
              id: props.objectDetail.id,
              isPublic: localValue.value.is_public,
              userPermissions: selectedUser.value.map(object => ({
                user_id: object.id,
                permission_type: object.permission_type,
              })),
            })
            break

          default:
            break
        }
      }
    }

    const findUserPermission = id => {
      props.objectDetail[permissionAccessor[props.objectType].target].find(
        object => object?.user?.id === id || object?.id === id,
      )
    }

    const handleChipDelete = userId => {
      selectedUser.value = selectedUser.value.filter(user => user?.id !== userId)
    }

    const refreshComponent = val => {
      isLoading.value = true

      if (!val) return

      localValue.value = val
      selectedUser.value = [
        val?.owner?.id,
        ...val?.folder_permissions
          .filter(object => object.user.id !== val?.owner?.id)
          .map(object => object.user.id),
      ]

      if (localValue.value) isLoading.value = false
      else errorHandling('DATA DOES NOT EXIST!')
    }

    watch(selectedUser, () => {
      emit(
        'update:selected',
        selectedUser.value.filter(user => user?.id !== props.objectDetail?.owner?.id),
      )
    })

    watch(
      () => props.objectDetail,
      incoming => refreshComponent(incoming),
    )

    onMounted(refreshComponent(props.objectDetail))

    onUnmounted(() => {
      localValue.value = undefined
      selectedUser.value = undefined

      isLoading.value = true
    })

    return {
      isLoading,
      search,
      permissionForm,

      localValue,

      currentUserId,
      selectedUser,
      lookupCurrentUserPermisison,

      userList,
      loadingSubmit,

      reset,
      submitForm,
      handleChipDelete,
      findUserPermission,

      avatarText,
      folderPermissionList,
      permissionAccessor,

      icons: {
        mdiShieldLock,
        mdiAccountGroup,
      },
    }
  },
}
</script>

<style lang="scss">
.autocomplete-field {
  position: relative;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid;
  border-color: map-deep-get($material-light, 'text-fields', 'outlined');

  .theme--dark & {
    border-color: map-deep-get($material-dark, 'text-fields', 'outlined');
  }

  .autocomplete-search {
    input {
      padding: 0 !important;
    }
  }
}
</style>
