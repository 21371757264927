<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    max-width="1200"
  >
    <v-card height="85vh">
      <v-navigation-drawer
        v-model="isLeftSidebarOpen"
        width="260"
        touchless
        mobile-breakpoint="sm"
        :temporary="$vuetify.breakpoint.smAndDown"
        absolute
        class="left-sidebar"
      >
        <div class="d-flex flex-column h-full py-4 px-2">
          <div class="d-flex flex-column mx-2 mb-1">
            <span class="text-caption text--disabled">Workspace</span>
            <span class="text-subtitle-2">{{ workspaceDetail?.workspace?.name }}</span>
          </div>

          <v-divider class="my-2" />

          <v-list
            rounded
            dense
          >
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="(menu, index) in filteredWorkspaceMenu"
                :key="index"
                @click="
                  isLeftSidebarOpen && $vuetify.breakpoint.smAndDown
                    ? (isLeftSidebarOpen = !isLeftSidebarOpen)
                    : null
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-card
            outlined
            class="pa-2 mx-2 mt-auto d-flex flex-column"
          >
            <div class="d-flex align-start mb-1">
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Paket Berlangganan</span>
                <span class="text-subtitle-2">Personal</span>
              </div>
              <v-icon
                class="ml-auto"
                color="primary"
                size="20"
              >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </div>
            <span class="text-caption mb-2"
              >Dapatkan manfaat lebih dari Oriens CRM dengan berlangganan</span
            >
            <v-btn
              outlined
              small
              color="primary"
            >
              Upgrade
            </v-btn>
          </v-card>

          <v-btn
            outlined
            small
            text
            color="error"
            class="mt-2 mx-2"
            @click="quit"
          >
            Keluar Workspace
          </v-btn>
        </div>
      </v-navigation-drawer>

      <div
        :style="{
          'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '260px' : null,
        }"
        class="main-area h-full"
      >
        <div class="d-flex flex-column w-full h-full pt-6 pb-4">
          <div class="d-flex mb-4">
            <v-icon
              class="ml-4"
              @click="isLeftSidebarOpen = !isLeftSidebarOpen"
            >
              {{ isLeftSidebarOpen ? icons.mdiChevronDoubleLeft : icons.mdiChevronDoubleRight }}
            </v-icon>

            <h3
              class="mx-auto"
              :class="{ 'text-subtitle-2 font-weight-bold': $vuetify.breakpoint.width < 500 }"
            >
              {{ title }}
            </h3>

            <v-icon
              class="mr-6"
              @click="isOpen = !isOpen"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>

          <v-tabs-items
            v-if="!loadingDetail"
            class="flex-fill h-full"
            v-model="selectedItem"
            touchless
            style="max-height: 100%"
          >
            <v-tab-item
              class="flex-fill overflow-hidden"
              style="max-height: 100%"
            >
              <WorkspaceSetting @onDelete="isOpen = false" />
            </v-tab-item>
            <v-tab-item
              class="flex-fill overflow-hidden"
              style="max-height: 100%"
            >
              <WorkspaceSharedUser @refetch="fetchWorkspaceDetail" />
            </v-tab-item>
            <v-tab-item
              class="flex-fill overflow-hidden"
              style="max-height: 100%"
            >
              <WorkspaceChatSettings />
            </v-tab-item>
            <v-tab-item
              class="flex-fill overflow-hidden"
              style="max-height: 100%"
            >
              <Integrations />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-card>

    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import errorHandling from '@/utils/errorHandling'
import WorkspaceSetting from '@/views/workspace/WorkspaceSetting.vue'
import WorkspaceSharedUser from '@/views/workspace/WorkspaceSharedUser.vue'
import {
  // mdiAccountKey,
  mdiAccountGroup,
  mdiAccountMultiplePlusOutline,
  mdiApps,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiClose,
  mdiCog,
  mdiForum,
  mdiHelpCircleOutline,
  mdiMenu,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import Vue, { computed, ref, watchEffect } from 'vue'
import WorkspaceChatSettings from './WorkspaceChatSettings.vue'

import useWorkspace from '@/composables/useWorkspace'
import { quitWorkspace } from '@/graphql/mutations'
import router from '@/router'
import store from '@/store'
import Integrations from '@/views/integration/Integrations.vue'
import { apolloClient } from '@/vue-apollo'
import { provide } from 'vue'
// import ability from '@/plugins/acl/ability'

const workspaceMenu = [
  {
    icon: mdiCog,
    title: 'Pengaturan Workspace',
    hasPermission: true,
  },
  {
    icon: mdiAccountGroup,
    title: 'Daftar Pengguna',
    hasPermission: true,
  },
  {
    icon: mdiForum,
    title: 'Pengaturan Chat',
    hasPermission: true,
  },
  {
    icon: mdiApps,
    title: 'Integrasi',
    hasPermission: true,
  },
]

export default {
  components: {
    WorkspaceSharedUser,
    WorkspaceSetting,
    WorkspaceChatSettings,
    Integrations,
  },
  setup() {
    const loadingQuit = ref(false)
    const isOpen = ref(false)
    const loadingDetail = ref(true)
    const isLeftSidebarOpen = ref(true)

    const workspaceDetail = ref({})

    const filteredWorkspaceMenu = computed(() => workspaceMenu.filter(menu => menu.hasPermission))
    const title = computed(() => {
      if (selectedItem.value <= filteredWorkspaceMenu.value.length - 1) {
        return filteredWorkspaceMenu.value[selectedItem.value].title
      }

      return ''
    })

    const selectedItem = useStorage('workspaceTabItem', 0)
    const { fetchWorkspaceDetail } = useWorkspace()
    const $vuetify = getVuetify()

    provide('workspaceDetail', workspaceDetail)

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    const show = id => {
      isOpen.value = true

      fetchWorkspaceDetail(id, false).then(data => {
        workspaceDetail.value = data
        loadingDetail.value = false
      })
    }
    const close = () => {
      workspaceDetail.value = undefined
      loadingDetail.value = true

      isOpen.value = false
    }
    const quit = () => {
      Vue.$dialog({
        title: 'Keluar Workspace?',
        body: 'Yakin ingin keluar dari workspace?',
      }).then(result => {
        if (result) {
          loadingQuit.value = true
          apolloClient
            .mutate({
              mutation: quitWorkspace,
              variables: {
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(() => {
              loadingQuit.value = false
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil keluar dari workspace!',
              })
              router.go()
            })
            .catch(err => {
              loadingQuit.value = false
              errorHandling(err)
            })
        }
      })
    }

    return {
      workspaceDetail,

      workspaceMenu,
      filteredWorkspaceMenu,
      title,
      isOpen,
      loadingDetail,
      isLeftSidebarOpen,
      selectedItem,
      show,
      close,
      quit,
      fetchWorkspaceDetail,
      icons: {
        mdiClose,
        mdiAccountMultiplePlusOutline,
        mdiMenu,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.main-area {
  @include style-scroll-bar();
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
