<template>
  <span>
    <template v-for="(part, index) in truncatedParts">
      <span
        v-if="part.isMatch"
        class="font-weight-bold"
        :key="`highlight-result__target-${index}`"
      >
        {{ part.text }}
      </span>
      <span
        v-else
        class="text--secondary"
        :key="`highlight-result__text-${index}`"
      >
        {{ part.text }}
      </span>
    </template>
  </span>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const CONTEXT_LENGTH = 50

    const truncatedParts = computed(() => {
      if (!props.text || !props.target) return [{ text: props.text, isMatch: false }]

      const lowerText = props.text.toLowerCase()
      const lowerSearch = props.target.toLowerCase()
      const matches = [...lowerText.matchAll(new RegExp(lowerSearch, 'gi'))]

      if (matches.length === 0) return [{ text: props.text, isMatch: false }]

      let result = []
      let lastEnd = 0

      matches.forEach((match, i) => {
        const matchIndex = match.index
        const start = Math.max(0, matchIndex - CONTEXT_LENGTH)
        const end = Math.min(props.text.length, matchIndex + props.target.length + CONTEXT_LENGTH)

        if (matchIndex > lastEnd) {
          result.push({
            text: (start > lastEnd ? '...' : '') + props.text.slice(lastEnd, matchIndex),
            isMatch: false,
          })
        }

        result.push({
          text: props.text.slice(matchIndex, matchIndex + props.target.length),
          isMatch: true,
        })

        lastEnd = matchIndex + props.target.length

        if (i === matches.length - 1) {
          result.push({
            text: props.text.slice(lastEnd, end) + (end < props.text.length ? '...' : ''),
            isMatch: false,
          })
        }
      })

      return result
    })

    return {
      truncatedParts,
    }
  },
}
</script>
