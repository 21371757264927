import { getJobTypeDetail, getJobTypes } from '@/services/jobTypeService'
import { getField, setField } from 'vuex-use-fields'
import store from '..'

export default {
  namespaced: true,
  state: {
    jobTypeList: [],
    sharedJobTypeList: [],

    jobTypeDetail: null,

    jobTypeFilter: {
      search: '',
      folder_id: null,
      my_jobType: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: 'IS NOT SET',
      },
      sort: {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
      },
    },

    sharedJobTypeFilter: {
      my_jobType: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: 'IS NOT SET',
      },
      sort: {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
      },
      search: '',
    },

    loadingJobType: false,
    loadingJobTypeDetail: false,
    loadingSharedJobType: false,
  },
  getters: {
    getField,
    getJobTypeId: state => state.jobTypeDetail.id,
    getJobTypeVisibility: state => state.jobTypeDetail.is_public,
    getJobTypeDetail: state => state.jobTypeDetail,
    getJobTypePermissionList: state => state.jobTypeDetail.folder_permissions,
    getJobTypeCurrentUserPermission: state =>
      state.jobTypeDetail.folder_permissions.find(
        object => object.user.id === store.getters.getUserData.id,
      ),
    getJobTypeFilterValue: state => {
      const { my_jobType, show_archived, sort, search } = state.jobTypeFilter

      return {
        search,
        my_jobType: my_jobType.value,
        show_archived: show_archived.value,
        sort: sort.value,
        sortType: sort.sortType,
      }
    },
    getJobTypeStatus: state => state.jobTypeDetail.statuses,
    getJobTypePriority: state => state.jobTypeDetail.priorities,
    getJobTypeCustomAttributes: state => state.jobTypeDetail.custom_attributes,
    getJobTypeFilterCount: state => {
      const { my_jobType, show_archived, sort } = state.jobTypeFilter

      let count = 0
      if (my_jobType.value !== null) count += 1
      if (show_archived.value !== 'IS NOT SET') count += 1
      if (sort.id !== 1) count += 1

      return count
    },
  },
  mutations: {
    setField,
  },
  actions: {
    resetJobTypeFilter({ state }) {
      state.jobTypeFilter = {
        my_jobType: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: 'IS NOT SET',
        },
        sort: {
          id: 1,
          text: 'Nama (A-Z)',
          value: 'name',
          sortType: 'ASC',
        },
        search: '',
        folder_id: null,
      }
    },
    async fetchJobTypes({ state, getters }) {
      const { getJobTypeFilterValue } = getters
      const { folder_id } = state.jobTypeFilter

      state.loadingJobType = true
      state.jobTypeList = await getJobTypes(folder_id, getJobTypeFilterValue)
      state.loadingJobType = false
    },
    async fetchJobTypeDetail({ state }, { jobTypeId, excludeClosedStatus }) {
      state.loadingJobTypeDetail = true
      state.jobTypeDetail = await getJobTypeDetail({ jobTypeId, excludeClosedStatus })
      state.loadingJobTypeDetail = false
    },
  },
}
