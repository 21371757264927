import gql from 'graphql-tag'

const workspaceList = gql`
  query workspaceList {
    workspaceList {
      workspace {
        id
        identifier_id
        name
        photo

        owner {
          id
        }
      }
      role {
        id
        name
      }
    }
  }
`

export default workspaceList
