import useWorkspace from '@/composables/useWorkspace'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**
 * @typedef { import('vue-router').RouteRecord
 * & import('./definition').IMetaRoute
 * & import('vue-router').RouteConfig } IRoutes
 * @type {IRoutes[]}
 */
const routes = [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/initial/Login.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/initial/Register.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/need-verify',
    name: 'auth-need-verify',
    component: () => import('@/views/initial/NeedVerify.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/initial/VerifyAccount.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    props: route => ({ query: route.query }),
    beforeEnter: (to, from, next) => {
      console.log('masuk')
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        console.log('verify')
        next()
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/initial/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/initial/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    props: route => ({ query: route.query }),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/error/Unauthorized.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/initial/Welcome.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/InternalError.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/:workspace',
    name: 'workspace-root',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      action: 'read',
      resource: 'All',
      user: true,
    },
  },
  {
    path: '/:workspace/documents',
    name: 'documents',
    component: () => import('@/views/document/Document.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Dokumen',
      user: true,
    },
  },
  {
    path: '/:workspace/documents/detail',
    name: 'document-detail-root',
    component: () => import('@/views/document/DocumentDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Dokumen',
      user: true,
    },
    props: true,
    children: [
      {
        path: ':id',
        name: 'document-detail',
        component: () => import('@/views/document/detail/content/DocumentMainContent.vue'),
        meta: {
          action: 'read',
          resource: 'All',
          pageTitle: 'Detail Dokumen',
          user: true,
        },
      },
    ],
    // beforeEnter: (to, from, next) => {
    //   window.onpopstate = () => {
    //     if (from.name === null) next({ name: 'workspace-root' })
    //   }

    //   // window.history.pushState({}, null, to.path)

    //   // Vue.$detail({ componentId: 'document-detail', objectId: to.params.id })
    //   next()
    // },
  },
  {
    path: '/:workspace/notifications',
    name: 'notifications',
    component: () => import('@/views/notification/Notification.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Notifikasi',
      user: true,
    },
  },
  {
    path: '/:workspace/customers',
    name: 'customers',
    component: () => import('@/views/customer/Customer.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Pelanggan',
      user: true,
    },
  },
  {
    path: '/:workspace/customers/detail/:id',
    name: 'customer-detail',
    component: () => import('@/views/customer/CustomerDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Pelanggan',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/products',
    name: 'products',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Produk',
      user: true,
    },
  },
  {
    path: '/:workspace/products/detail/:id',
    name: 'product-detail',
    component: () => import('@/views/product/ProductDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Produk',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/chats',
    name: 'chats',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Chat',
      user: true,
    },
  },
  {
    path: '/:workspace/chats/:id',
    name: 'chat-detail',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Chat',
      navActiveLink: 'chats',
      user: true,
    },
  },
  {
    path: '/:workspace/folders/:id',
    name: 'folder-detail',
    component: () => import('@/views/folder/FolderDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Folder',
      user: true,
    },
    props: true,
    children: [
      {
        path: 'type/:jobtypeid',
        name: 'jobtype-detail',
        component: () => import('@/views/folder/JobTypeDetail.vue'),
        meta: {
          action: 'read',
          resource: 'All',
          pageTitle: 'Detail Job Type',
          user: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/:workspace/shared-job-type',
    name: 'shared-job-type',
    component: () => import('@/views/shared-job-type/SharedJobType.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Shared Job Type',
      user: true,
    },
    props: true,
    children: [
      {
        path: ':id',
        name: 'shared-job-type-detail',
        component: () => import('@/views/shared-job-type/SharedJobTypeDetail.vue'),
        meta: {
          action: 'read',
          resource: 'All',
          pageTitle: 'Detail Shared Job Type',
          user: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/:workspace/jobs/detail/:id',
    name: 'job-detail',
    component: () => import('@/views/job/JobDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Job',
      user: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      window.onpopstate = event => {
        if (from.name === null) next({ name: 'workspace-root' })
        console.log(event)
      }

      /* eslint-disable no-restricted-globals */
      // history.pushState({}, null, from.path)
      // history.replaceState({}, null, to.path)
      /* eslint-enable no-restricted-globals */

      // const { href } = this.$router.resolve(to.params.id)
      window.history.pushState({}, null, to.path)

      Vue.$detail({ componentId: 'job-detail', props: to.params })
    },
  },
  {
    path: '/:workspace/attachment/:id',
    name: 'attachment-detail',
    component: () => import('@/views/job/JobDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Job',
      user: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      window.onpopstate = event => {
        if (from.name === null) next({ name: 'workspace-root' })
        console.log(event)
      }

      window.history.pushState({}, null, to.path)

      Vue.$detail({ componentId: 'attachment-detail', props: to.params })
    },
  },
  {
    path: '/:workspace/reports',
    name: 'reports',
    component: () => import('@/views/ComingSoon.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Laporan',
      user: true,
    },
  },
  {
    path: '/:workspace/activities',
    name: 'activities',
    component: () => import('@/views/activity/Activity.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Aktivitas',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/activities/detail/:id',
    name: 'activity-detail',
    component: () => import('@/views/activity/Activity.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Aktivitas',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/users',
    name: 'users',
    component: () => import('@/views/user/User.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Pengguna',
      user: true,
    },
  },
  {
    path: '/:workspace/users/detail/:id',
    name: 'user-detail',
    component: () => import('@/views/user/UserDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Pengguna',
      user: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      window.onpopstate = event => {
        if (from.name === null) next({ name: 'workspace-root' })
        console.log(event)
      }

      window.history.pushState({}, null, to.path)

      Vue.$drawer({ id: to.params.id })
    },
  },

  {
    path: '/:workspace/teams',
    name: 'teams',
    component: () => import('@/views/team/Team.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Tim',
      user: true,
    },
  },
  {
    path: '/:workspace/teams/:id',
    name: 'team-detail',
    component: () => import('@/views/team/TeamDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Tim',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/teams/:id/channel/:channelid',
    name: 'channel',
    component: () => import('@/views/team/TeamDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Tim',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/workflows',
    name: 'workflows',
    component: () => import('@/views/workflow/Workflow.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Workflow',
      user: true,
    },
  },
  {
    path: '/:workspace/workflows/detail/:id',
    name: 'workflow-detail',
    // component: () => import('@/views/workflow/WorkflowDetail.vue'),
    component: () => import('@/views/workflow/WorkflowDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Workflow Detail',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/hierarchy',
    name: 'hierarchy',
    component: () => import('@/views/hierarchy/Hierarchy.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Hierarki',
      user: true,
    },
  },
  {
    path: '/:workspace/hierarchy/:id',
    name: 'hierarchy-detail',
    component: () => import('@/views/hierarchy/Hierarchy.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Hierarki',
      navActiveLink: 'hierarchy',
      user: true,
    },
  },
  // {
  //   path: '/:workspace/callback/trello',
  //   name: 'Authorize Trello',
  //   component: () => import('@/views/integration/trello/component/TrelloLoading.vue'),
  //   meta: {
  //     action: 'read',
  //     resource: 'All',
  //     pageTitle: 'Authorize Trello',
  //     layout: 'blank',
  //   },
  //   props: route => ({ query: route.query }),
  // },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      errorHandling('Halaman Tidak Ditemukan')
      next('/')
    },
  },

  // for test purpose
  {
    path: '/test/components',
    name: 'components',
    component: () => import('@/views/test/Components.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Components Test',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
    },
  },
  {
    path: '/invite/:id',
    name: 'invite-url',
    meta: {
      layout: 'blank',
      public: true,
    },
    component: () => import('@/views/initial/Invitation.vue'),
    props: true,
  },
  // khusus admin
  // {
  //   path: '/admin/dashboard',
  //   name: 'admin-dashboard',
  //   meta: {
  //     pageTitle: 'Dashboard',
  //     layout: 'admin',
  //     admin: true,
  //   },
  //   component: () => import('@/views/admin/DashboardAdmin.vue'),
  //   props: true,
  // },
  // {
  //   path: '/admin/user',
  //   name: 'admin-user',
  //   meta: {
  //     pageTitle: 'Manajemen User',
  //     layout: 'admin',
  //     admin: true,
  //   },
  //   component: () => import('@/views/admin/UserAdmin.vue'),
  //   props: true,
  // },
  // {
  //   path: '/admin/workflow',
  //   name: 'admin-workflow',
  //   meta: {
  //     pageTitle: 'Workflow',
  //     layout: 'admin',
  //     admin: true,
  //   },
  //   component: () => import('@/views/admin/WorkflowAdmin.vue'),
  //   props: true,
  // },
  // {
  //   path: '/admin/tokopedia',
  //   name: 'admin-tokopedia',
  //   meta: {
  //     pageTitle: 'Integrasi Tokopedia',
  //     layout: 'admin',
  //     admin: true,
  //   },
  //   component: () => import('@/views/admin/TokopediaAdmin.vue'),
  //   props: true,
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ selector: to.hash })
        }, 600)
      })
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          x: savedPosition ? savedPosition.x : 0,
          y: savedPosition ? savedPosition.y : 0,
          behavior: 'smooth',
        })
      }, 500)
    })
  },
})

router.beforeEach(async (to, from, next) => {
  const { fetchWorkspaceDetail } = useWorkspace()

  // set page title
  window.document.title =
    to.meta && to.meta.pageTitle
      ? `${to.meta.pageTitle} | OriensCRM - Next-gen CRM Solution`
      : 'OriensCRM - Next-gen CRM Solution'

  const hasToken = localStorage.getItem('token')
  // check if user is not authenticated but got token
  // using store.state.auth.isAuthenticated so data is always directly from state
  if (!store.state.auth.isAuthenticated && hasToken) {
    await store.dispatch('checkExpiredToken')
    await store.dispatch('getProfileInfo')
  }

  // get workspace list from state
  const workspaces = store.state.workspace.workspaceList

  if (to.path === '/verify') return next()
  if (to.path === '/welcome') return next()
  if (to.path === '/register') return next()
  if (to.path === '/need-verify') return next()
  if (to.path === '/reset-password') return next()
  if (to.path === '/forgot-password') return next()

  // if user is not authenticated and got no token
  // + workaround if on login page, then use the last next return
  if (!store.state.auth.isAuthenticated && !hasToken && to.path !== '/login') {
    localStorage.setItem('savedUrl', to.path)

    return next('/login')
  }

  // if accessing root path then start checking if the user has workspace
  // and if the user has selected workspace before
  if (to.path === '/') {
    if (!workspaces || !workspaces.length) return next('/welcome')

    const lastSelectedWorkspace = JSON.parse(localStorage.getItem('selectedWorkspace'))

    if (!lastSelectedWorkspace) {
      fetchWorkspaceDetail(workspaces[0].workspace.id)

      return next(`/${workspaces[0].workspace.identifier_id}`)
    }

    return next(`/${lastSelectedWorkspace.workspace.identifier_id}`)
  }

  if (
    (to.meta.user && store.state.auth.userData.is_admin) ||
    (to.meta.admin && !store.state.auth.userData.is_admin)
  ) {
    console.log(to)
    console.log(store.state.auth.userData?.is_admin)

    return next('/')
  }

  // reserved routes so it doesnt handled as workspace route
  const reservedRoutes = [
    '404',
    'error',
    'login',
    'unauthorized',
    'welcome',
    'invite',
    'test',
    'register',
    'forgot-password',
    'reset-password',
  ]

  if (!store.state.auth.userData?.is_admin) {
    const rootPath = to.path.split('/')[1]

    if (!reservedRoutes.some(el => el === rootPath)) {
      if (!workspaces || !workspaces.length) return next('/welcome')

      // check if workspace is exist
      const foundWorkspace = workspaces.find(el => el.workspace.identifier_id === rootPath)

      if (!foundWorkspace) {
        Vue.notify({
          title: 'Terjadi Kesalahan',
          text: 'Workspace tidak tersedia',
          type: 'warn',
        })

        // error handling if no workspace is available
        if (rootPath === 'workspace') {
          return window.location.replace('/')
        }

        // no need to set selected workspace state
        // because it's gonna revisiting this middleware again
        return next(`/${workspaces[0].workspace.identifier_id}`)
      }

      if (!store.getters.getCurrentWorkspaceUserList)
        fetchWorkspaceDetail(foundWorkspace.workspace.id)
    }
  }
  // get workspace slug as rootPath

  // check if the page require permission
  // console.log(store.state.auth.userData?.is_admin)
  // if (
  //   store.state.auth.isAuthenticated &&
  //   to.meta &&
  //   to.meta.resource &&
  //   to.meta.action &&
  //   !store.state.auth.userData?.is_admin
  // ) {
  // await store.dispatch('permissionsParser')
  // const canNavigate = to.matched.some(route =>
  //   ability.can(route.meta.action, route.meta.resource),
  // )

  // if (!canNavigate) return next('/unauthorized')
  // }

  return next()
})

export default router
