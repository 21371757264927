<template>
  <v-tab-item
    class="overflow-auto px-1"
    style="max-height: 100%; margin: 0 !important"
  >
    <v-form class="d-flex flex-column">
      <span class="text-subtitle-1 font-weight-semibold mt-1 mb-1">Letak Automation</span>

      <v-row
        no-gutters
        dense
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="folderDetailComputed"
            class="text-truncate mt-1 mr-0 mr-md-1 mb-2 mb-md-0"
            solo
            flat
            dense
            outlined
            return-object
            auto-select-first
            item-value="id"
            item-text="name"
            hide-details="auto"
            placeholder="Pilih Folder"
            :rules="[required]"
            :items="folderList"
          >
            <template #selection="{ item }">
              <div class="d-flex align-center py-3 px-1 overflow-hidden">
                <v-icon
                  :color="item.color"
                  class="mx-1"
                  size="18"
                >
                  {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center py-3 px-1 w-full">
                <v-icon
                  :color="item.color"
                  class="mx-1"
                  size="18"
                >
                  {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="jobTypeDetail"
            class="text-truncate mt-1 ml-0 ml-md-1"
            solo
            flat
            dense
            outlined
            return-object
            auto-select-first
            height="100%"
            item-value="id"
            item-text="name"
            hide-details="auto"
            placeholder="Pilih Job Type"
            :rules="[required]"
            :items="jobTypeList"
          >
            <template #selection="{ item }">
              <div class="d-flex align-center py-3 px-1 overflow-hidden">
                <v-badge
                  class="mr-1"
                  dot
                  inline
                  :color="item.color"
                />

                <v-icon
                  v-if="!item.is_public"
                  size="14"
                  class="mr-1"
                >
                  {{ icons.mdiLockOutline }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center py-3 px-1 w-full">
                <v-badge
                  class="mr-1"
                  dot
                  inline
                  :color="item.color"
                />

                <v-icon
                  v-if="!item.is_public"
                  size="14"
                  class="mr-1"
                >
                  {{ icons.mdiLockOutline }}
                </v-icon>

                <span class="text-truncate">{{ item.name }}</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <span class="text-subtitle-1 font-weight-semibold mt-4 mb-1">Tentang Automation</span>

      <v-row
        no-gutters
        dense
      >
        <v-col
          cols="12"
          md="3"
          order="0"
          order-md="1"
        >
          <v-chip
            class="ml-0 ml-md-2 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
            style="border-radius: 6px; height: 38px"
            :color="automationDetail.is_active ? 'primary' : ''"
            @click="automationDetail.is_active = !automationDetail.is_active"
          >
            <span class="text-subtitle-2 text-truncate">
              {{ automationDetail.is_active ? 'Status: Aktif' : 'Status: Nonaktif' }}
            </span>

            <v-icon
              v-if="automationDetail.is_active"
              class="ml-auto mx-1"
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiCheck }}
            </v-icon>

            <v-icon
              v-else
              class="ml-auto mx-1"
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-chip>
        </v-col>

        <v-col
          cols="12"
          md="9"
          order="1"
          order-md="0"
        >
          <v-text-field
            v-model="automationDetail.name"
            class="mt-2 mt-md-0"
            placeholder="Nama Automation"
            outlined
            dense
            hide-details="auto"
          />
        </v-col>

        <v-col
          cols="12"
          order="2"
        >
          <v-textarea
            v-model="automationDetail.description"
            class="mt-2"
            placeholder="Deskripsi Automation"
            outlined
            dense
            hide-details="auto"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          order="3"
        >
          <v-chip
            class="mt-2 mr-0 mr-md-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
            style="border-radius: 6px; height: 38px"
            :color="automationDetail.group_operator === 1 ? 'primary' : ''"
            @click="automationDetail.group_operator ^= 1"
          >
            <v-icon
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiRun }}
            </v-icon>

            <span class="ml-2 text-subtitle-2 text-truncate">
              {{
                automationDetail.group_operator === 1
                  ? 'Jalankan apabila semua Trigger terpenuhi'
                  : 'Jalankan apabila salah satu Trigger terpenuhi'
              }}
            </span>
          </v-chip>
        </v-col>

        <v-col
          cols="12"
          md="6"
          order="3"
        >
          <v-chip
            class="mt-2 ml-0 ml-md-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
            style="border-radius: 6px; height: 38px"
            :color="!automationDetail.is_automation_locked ? 'primary' : ''"
            @click="automationDetail.is_automation_locked = !automationDetail.is_automation_locked"
          >
            <v-icon
              v-if="!automationDetail.is_automation_locked"
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiWeb }}
            </v-icon>

            <v-icon
              v-else
              size="20"
              style="color: inherit !important"
            >
              {{ icons.mdiShieldOutline }}
            </v-icon>

            <span class="ml-2 text-subtitle-2 text-truncate">
              {{
                !automationDetail.is_automation_locked
                  ? 'Visibilitas: Publik'
                  : 'Visibilitas: Hanya Admin'
              }}
            </span>
          </v-chip>
        </v-col>
      </v-row>

      <span class="text-subtitle-1 font-weight-semibold mt-4 mb-1">Alur Automation</span>

      <div class="d-flex flex-column mb-1">
        <v-card
          class="mb-2"
          outlined
        >
          <v-card-title class="white--text pa-3">
            <div class="w-full mb-1">
              <span class="text-subtitle-2"> Pilih Tipe Object : </span>
            </div>

            <v-chip
              class="mt-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 6px 0 0 6px; height: 38px"
              :color="automationTriggerBy === 'job' ? 'primary' : ''"
              @click="automationTriggerBy === 'document' && changeAutomationTriggerBy('job')"
            >
              <v-icon
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiBriefcaseOutline }}
              </v-icon>

              <span class="ml-2 text-subtitle-2 text-truncate"> Object: Job </span>
            </v-chip>
            <v-chip
              class="mt-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 0 6px 6px 0; height: 38px"
              :color="automationTriggerBy === 'document' ? 'primary' : ''"
              @click="automationTriggerBy === 'job' && changeAutomationTriggerBy('document')"
            >
              <v-icon
                size="20"
                style="color: inherit !important"
              >
                {{ icons.mdiFileOutline }}
              </v-icon>

              <span class="ml-2 text-subtitle-2 text-truncate"> Object: Document </span>
            </v-chip>
          </v-card-title>
        </v-card>

        <v-card outlined>
          <v-card-title class="white--text pa-3">
            <v-chip
              label
              large
            >
              <v-icon
                class="ml-1 mr-2"
                size="24"
              >
                {{ icons.mdiLightningBolt }}
              </v-icon>
            </v-chip>
            <p class="ml-4 mb-0">
              Trigger
              <workflow-field-info
                title="Trigger"
                :details="['Trigger adalah aksi yang akan menjadi pemicu Automation.']"
              />
            </p>
          </v-card-title>
        </v-card>
        <v-card
          v-for="(trigger, index) in triggerList"
          :key="`trigger_${index}`"
          class="pa-3 mt-2"
          outlined
        >
          <v-row
            no-gutters
            dense
          >
            <v-col
              cols="12"
              md="3"
              lg="2"
            >
              <v-chip
                class="mb-2 mr-md-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
                style="border-radius: 6px; height: 38px"
                :color="trigger.is_active ? 'primary' : ''"
                @click="trigger.is_active = !trigger.is_active"
              >
                <span class="text-subtitle-2 text-truncate">
                  {{ trigger.is_active ? 'Trigger: Aktif' : 'Trigger: Nonaktif' }}
                </span>

                <v-icon
                  v-if="trigger.is_active"
                  class="ml-auto mx-1"
                  size="20"
                  style="color: inherit !important"
                >
                  {{ icons.mdiCheck }}
                </v-icon>

                <v-icon
                  v-else
                  class="ml-auto mx-1"
                  size="20"
                  style="color: inherit !important"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </v-chip>
            </v-col>

            <v-col
              cols="12"
              md="9"
              lg="10"
            >
              <div class="d-flex pb-2 ml-md-1">
                <v-text-field
                  v-model="trigger.name"
                  class="flex-fill"
                  placeholder="Nama Trigger"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[required]"
                />

                <v-tooltip
                  v-if="triggerList.length !== 1"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2 px-0"
                      color="error"
                      depressed
                      style="
                        border-radius: 5px;
                        min-width: 40px !important;
                        height: 40px !important;
                      "
                      @click="triggerList.splice(index, 1)"
                    >
                      <v-icon size="22">
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Hapus Trigger</span>
                </v-tooltip>
              </div>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="trigger.property"
                flat
                dense
                outlined
                auto-select-first
                hide-details="auto"
                placeholder="Property"
                item-text="label"
                item-value="id"
                :rules="[required]"
                :items="
                  propertyList.filter((property, index) => {
                    const selectedProperties = triggerList
                      .map(item => item.property)
                      .filter(item => item !== trigger.property)

                    return (
                      !selectedProperties.includes(property.id) &&
                      (automationTriggerBy === 'document'
                        ? index >= 19 && index <= 33
                        : index < 19 || index > 33)
                    )
                  })
                "
                @input="
                  trigger.property = $event
                  trigger.from_operator =
                    operatorList.filter(item =>
                      item.type.includes(propertyMap[trigger.property].type),
                    )[0]?.id || undefined
                  trigger.to_operator =
                    propertyMap[trigger.property].type === 'boolean'
                      ? 14
                      : operatorList.filter(item =>
                          item.type.includes(propertyMap[trigger.property].type),
                        )[0]?.id || undefined
                "
              >
                <template #selection="{ item }">
                  <v-icon
                    class="ml-1 mr-2"
                    size="20"
                  >
                    {{ item.icon }}
                  </v-icon>

                  {{
                    item.id <= 16
                      ? 'Job – '
                      : item.id >= 16
                      ? 'Document – '
                      : item.id >= 30
                      ? 'Custom Attribute – '
                      : null
                  }}
                  {{ item.label }}
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center flex-grow-1 py-3">
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="subtitle-2"> {{ item.label }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <template v-if="trigger.property && trigger.property !== 'ADD'">
              <v-col
                class="mt-3 mb-2"
                cols="12"
              >
                <span class="text-subtitle-2">
                  Periksa apakah
                  {{ propertyMap[trigger.property].message }}
                  sebelumnya
                  <span
                    v-if="trigger.from_operator"
                    class="text-lowercase"
                  >
                    {{ operatorMap[trigger.from_operator].label }}
                  </span>
                  :
                </span>
              </v-col>
              <v-col
                class="pr-0 pr-md-1 pb-md-0 pb-2"
                cols="12"
                :md="[13, 14, 15, 16].includes(trigger.from_operator) ? 12 : 4"
                :lg="[13, 14, 15, 16].includes(trigger.from_operator) ? 12 : 3"
              >
                <v-autocomplete
                  v-model="trigger.from_operator"
                  flat
                  dense
                  outlined
                  auto-select-first
                  hide-details="auto"
                  placeholder="Operator"
                  item-text="label"
                  item-value="id"
                  :rules="[required]"
                  :items="
                    operatorList.filter(item =>
                      item.type.includes(propertyMap[trigger.property]?.type),
                    )
                  "
                  @input="
                    () => {
                      if (trigger.from_operator === 13) trigger.to_operator = 14
                      if (trigger.from_operator === 14) trigger.to_operator = 13

                      if ([13, 14, 15, 16].includes(trigger.from_operator))
                        trigger.from_value = null
                    }
                  "
                >
                  <template #selection="{ item }">
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                    >
                      {{ item.icon }}
                    </v-icon>

                    {{ item.label }}
                  </template>

                  <template #item="{ item }">
                    <div class="d-flex align-center flex-grow-1 py-3">
                      <v-icon
                        class="ml-1 mr-2"
                        size="20"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <span class="subtitle-2"> {{ item.label }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="![13, 14, 15, 16].includes(trigger.from_operator)"
                class="pl-0 pr-md-1"
                cols="12"
                md="8"
                lg="9"
              >
                <AutomationCustomField
                  :isEditing="isEditing"
                  :value="trigger.from_value"
                  :type="propertyMap[trigger.property].type"
                  :property="trigger.property"
                  :operator="trigger.from_operator"
                  :icon="propertyMap[trigger.property].icon"
                  @input="triggerList[index].from_value = $event"
                />
              </v-col>

              <v-col
                class="mt-3 mb-2"
                cols="12"
              >
                <span class="text-subtitle-2">
                  Kemudian, jika nilai {{ propertyMap[trigger.property].message }}
                  <span
                    v-if="trigger.to_operator"
                    class="text-lowercase"
                  >
                    {{ operatorMap[trigger.to_operator].label }}
                  </span>
                  :
                </span>
              </v-col>
              <v-col
                class="pr-0 pr-md-1 pb-md-0 pb-2"
                cols="12"
                :md="[13, 14, 15, 16].includes(trigger.to_operator) ? 12 : 4"
                :lg="[13, 14, 15, 16].includes(trigger.to_operator) ? 12 : 3"
              >
                <v-autocomplete
                  v-model="trigger.to_operator"
                  flat
                  dense
                  outlined
                  auto-select-first
                  hide-details="auto"
                  placeholder="Operator"
                  item-text="label"
                  item-value="id"
                  :rules="[required]"
                  :items="
                    operatorList.filter(item =>
                      item.type.includes(propertyMap[trigger.property]?.type),
                    )
                  "
                  @input="
                    () => {
                      if (trigger.to_operator === 13) trigger.from_operator = 14
                      if (trigger.to_operator === 14) trigger.from_operator = 13

                      if ([13, 14, 15, 16].includes(trigger.to_operator)) trigger.to_value = null
                    }
                  "
                >
                  <template #selection="{ item }">
                    <v-icon
                      class="ml-1 mr-2"
                      size="20"
                    >
                      {{ item.icon }}
                    </v-icon>

                    {{ item.label }}
                  </template>

                  <template #item="{ item }">
                    <div class="d-flex align-center flex-grow-1 py-3">
                      <v-icon
                        class="ml-1 mr-2"
                        size="20"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <span class="subtitle-2"> {{ item.label }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="![13, 14, 15, 16].includes(trigger.to_operator)"
                class="pl-0 pr-md-1"
                cols="12"
                md="8"
                lg="9"
              >
                <AutomationCustomField
                  :isEditing="isEditing"
                  :value="trigger.to_value"
                  :type="propertyMap[trigger.property].type"
                  :property="trigger.property"
                  :operator="trigger.to_operator"
                  :icon="propertyMap[trigger.property].icon"
                  @input="triggerList[index].to_value = $event"
                />
              </v-col>
            </template>
          </v-row>
        </v-card>
        <v-btn
          class="mt-2 v-card v-sheet--outlined"
          depressed
          outlined
          style="border-radius: 5px; width: 100%"
          @click="handleAddNewTrigger()"
        >
          <h4 class="font-medium pl-2">Add New Trigger</h4>
        </v-btn>

        <v-card
          class="mt-8"
          outlined
        >
          <v-card-title class="white--text pa-3">
            <v-chip
              label
              large
            >
              <v-icon
                class="ml-1 mr-2"
                size="24"
              >
                {{ icons.mdiRocketLaunch }}
              </v-icon>
            </v-chip>
            <p class="ml-4 mb-0">
              Action
              <workflow-field-info
                title="Action"
                :details="[
                  'Action merupakan aksi yang akan dijalankan apabila kondisi Trigger terpenuhi.',
                ]"
              />
            </p>
          </v-card-title>
        </v-card>
        <v-card
          v-for="(action, index) in actionList"
          :key="`action_${index}`"
          class="pa-3 mt-2"
          outlined
        >
          <v-row
            no-gutters
            dense
          >
            <v-col
              cols="12"
              md="3"
              lg="2"
            >
              <v-chip
                class="mb-2 mr-md-1 d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
                style="border-radius: 6px; height: 38px"
                :color="action.is_active ? 'primary' : ''"
                @click="action.is_active = !action.is_active"
              >
                <span class="text-subtitle-2 text-truncate">
                  {{ action.is_active ? 'Action: Aktif' : 'Action: Nonaktif' }}
                </span>

                <v-icon
                  v-if="action.is_active"
                  class="ml-auto mx-1"
                  size="20"
                  style="color: inherit !important"
                >
                  {{ icons.mdiCheck }}
                </v-icon>

                <v-icon
                  v-else
                  class="ml-auto mx-1"
                  size="20"
                  style="color: inherit !important"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </v-chip>
            </v-col>

            <v-col
              cols="12"
              md="9"
              lg="10"
            >
              <div class="d-flex pb-2 ml-md-1">
                <v-text-field
                  v-model="action.name"
                  class="flex-fill"
                  placeholder="Nama Action"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[required]"
                />

                <v-tooltip
                  v-if="actionList.length !== 1"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2 px-0"
                      color="error"
                      depressed
                      style="
                        border-radius: 5px;
                        min-width: 40px !important;
                        height: 40px !important;
                      "
                      @click="actionList.splice(index, 1)"
                    >
                      <v-icon size="22">
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Hapus Action</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <v-autocomplete
            v-model="action.action_type"
            flat
            dense
            outlined
            auto-select-first
            hide-details="auto"
            placeholder="Property"
            item-text="label"
            item-value="id"
            :rules="[required]"
            :items="
              actionTypeList.filter(actionType => {
                if (automationTriggerBy === 'document') return actionType.id != '2'
                else return actionType
              })
            "
            @input="action.action_type = $event"
          >
            <template #selection="{ item }">
              <v-icon
                class="ml-1 mr-2"
                size="20"
              >
                {{ item.icon }}
              </v-icon>

              {{ item.label }}
            </template>

            <template #item="{ item }">
              <div class="d-flex align-center flex-grow-1 py-3">
                <v-icon
                  class="ml-1 mr-2"
                  size="20"
                >
                  {{ item.icon }}
                </v-icon>
                <span class="subtitle-2"> {{ item.label }}</span>
              </div>
            </template>
          </v-autocomplete>

          <template v-if="action.action_type">
            <div class="mt-3 mb-2">
              <span class="text-subtitle-2">
                {{ actionTypeMap[action.action_type]?.message }} :
              </span>
            </div>
            <AutomationFormAction
              :to_do="action.to_do"
              :action-type="action.action_type"
              :isEditing="isEditing"
              @input="actionList[index].to_do = $event"
            />
          </template>
        </v-card>
        <v-btn
          class="mt-2 v-card v-sheet--outlined"
          depressed
          outlined
          style="border-radius: 5px; width: 100%"
          @click="handleAddNewAction()"
        >
          <h4 class="font-medium pl-2">Add New Action</h4>
        </v-btn>
      </div>
    </v-form>
  </v-tab-item>
</template>

<script>
import { useRouter } from '@/@core/utils'
import {
  actionTypeList,
  actionTypeMap,
  attributePropertyList,
  attributePropertyMap,
  customAttributeTypeMap,
  operatorList,
  operatorMap,
} from '@/constants/automation'
import {
  mdiBriefcaseOutline,
  mdiCheck,
  mdiClose,
  mdiContainEnd,
  mdiDelete,
  mdiFileOutline,
  mdiFilterOutline,
  mdiFolder,
  mdiFolderLock,
  mdiLightningBolt,
  mdiLockOutline,
  mdiPlus,
  mdiRocketLaunch,
  mdiRun,
  mdiShieldOutline,
  mdiSourceCommit,
  mdiTuneVertical,
  mdiWeb,
} from '@mdi/js'
import Vue, { computed, inject, onMounted, ref, watch } from 'vue'

import { required } from '@/@core/utils/validation'
import useAutomation from '@/composables/useAutomationV3'
import errorHandling from '@/utils/errorHandling'
import AutomationCustomField from './components/AutomationCustomField.vue'
import AutomationFormAction from './components/AutomationFormAction.vue'
import WorkflowFieldInfo from './components/WorkflowFieldInfo.vue'

export default {
  components: {
    WorkflowFieldInfo,
    AutomationCustomField,
    AutomationFormAction,
  },

  props: {
    isCreating: {
      type: Boolean,
    },
    isEditing: {
      type: Boolean,
    },
    id: {
      type: Number,
    },
  },

  setup(props) {
    const { router } = useRouter()
    const { createAutomation, updateAutomation, deleteAutomation, fetchAutomationById } =
      useAutomation()

    const tabs = inject('tabs')
    const folderDetail = inject('folderDetail')
    const folderList = inject('folderList')
    const jobTypeDetail = inject('jobTypeDetail')
    const jobTypeList = inject('jobTypeList')

    const isLoading = ref(false)
    const localFolderDetail = ref()
    const folderDetailComputed = computed({
      get() {
        return props.isEditMode ? localFolderDetail.value : folderDetail.value
      },
      set(newValue) {
        if (props.isEditMode) {
          localFolderDetail.value = newValue
        } else {
          folderDetail.value = newValue
        }
      },
    })

    const automationTriggerBy = ref('job')
    const automationDetail = ref({
      name: '',
      description: '',
      is_active: true,
      is_automation_locked: false,
      group_operator: 0,
      folder_id: computed(() => folderDetail.value?.id),
      job_type_id: computed(() => jobTypeDetail.value?.id),
      isDocument: computed(() => triggerList.value.some(item => item.property >= 20)),
    })
    const triggerList = ref([
      {
        name: '',
        is_active: true,
        property: undefined,
        from_operator: 1,
        to_operator: 1,
        from_value: undefined,
        to_value: undefined,
      },
    ])
    const actionList = ref([
      {
        name: '',
        is_active: true,
        action_type: undefined,
        to_do: undefined,
      },
    ])

    const propertyList = computed(() => {
      let list = [...attributePropertyList]

      if (
        !jobTypeDetail.value?.custom_attributes ||
        jobTypeDetail.value?.custom_attributes?.length === 0
      )
        return list
      else {
        list.push({
          header: 'Job Custom Attribute Properties',
        })
        jobTypeDetail.value?.custom_attributes.forEach(item => {
          list.push({
            id: `CA_${item.id}`,
            label: item.name,
            message: customAttributeTypeMap[item.data_type.name].message,
            icon: customAttributeTypeMap[item.data_type.name].icon,
            type: customAttributeTypeMap[item.data_type.name].type,
          })
        })

        return list
      }
    })
    const propertyMap = computed(() => {
      let map = attributePropertyMap

      if (
        !jobTypeDetail.value?.custom_attributes ||
        jobTypeDetail.value?.custom_attributes?.length === 0
      )
        return map

      jobTypeDetail.value?.custom_attributes.forEach(item => {
        map = {
          ...map,

          [`CA_${item.id}`]: {
            id: `CA_${item.id}`,
            label: item.name,
            message: customAttributeTypeMap[item.data_type.name].message,
            type: customAttributeTypeMap[item.data_type.name].type,
            icon: customAttributeTypeMap[item.data_type.name].icon,
          },
        }
      })

      return map
    })

    const setAutomationEmpty = () => {
      automationDetail.value = {
        name: '',
        description: undefined,
        is_active: true,
        is_automation_locked: false,
        group_operator: 0,
        folder_id: computed(() => folderDetail.value?.id),
        job_type_id: computed(() => jobTypeDetail.value?.id),
        isDocument: computed(() => triggerList.value.some(item => item.property >= 20)),
      }

      triggerList.value = [
        {
          name: '',
          is_active: true,
          property: undefined,
          from_operator: 1,
          to_operator: 1,
          from_value: undefined,
          to_value: undefined,
        },
      ]
      actionList.value = [
        {
          name: '',
          is_active: true,
          action_type: undefined,
          filter: undefined,
          to_do: undefined,
        },
      ]
    }

    const handleAddNewTrigger = () => {
      triggerList.value.push({
        name: '',
        is_active: true,
        property: undefined,
        from_operator: undefined,
        to_operator: undefined,
        from_value: undefined,
        to_value: undefined,
      })
    }
    const handleAddNewAction = () => {
      actionList.value.push({
        name: '',
        is_active: true,
        action_type: undefined,
        filter: undefined,
        to_do: undefined,
        folder_id: undefined,
        job_type_id: undefined,
      })
    }

    const handleCreateAutomation = () => {
      createAutomation(automationDetail.value, triggerList.value, actionList.value)
        .then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menciptakan Automation!',
          })

          setAutomationEmpty()

          tabs.value = 0
        })
        .catch(err => {
          errorHandling(err)
        })
    }

    const handleUpdateAutomation = () => {
      updateAutomation(props.id, automationDetail.value, triggerList.value, actionList.value)
        .then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengudpate Automation!',
          })

          setAutomationEmpty()

          tabs.value = 0
        })
        .catch(err => {
          errorHandling(err)
        })
    }

    const hadnleDeleteAutomation = () => {
      Vue.$dialog({
        title: 'Hapus Automation?',
        body: 'Apakah anda yakin untuk menghapus Automation ini?',
      }).then(() =>
        deleteAutomation(props.id)
          .then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menciptakan Automation!',
            })

            setAutomationEmpty()

            tabs.value = 0
          })
          .catch(err => {
            errorHandling(err)
          }),
      )
    }

    const sanitizeIncomingData = (data, modifier) => {
      const sanitize = {
        [`${modifier}_id`]: data.id,
        ...data,
      }
      delete sanitize.id
      delete sanitize.__typename

      return sanitize
    }

    const fetchAutomationDetail = async () => {
      isLoading.value = true

      await fetchAutomationById(props.id)
        .then(data => {
          const {
            // Automation Detail
            name,
            description,
            is_active,
            is_automation_locked,
            group_operator,
            folder,
            job_type,

            // Automation Trigger
            triggers,

            // Automation Action
            actions,
          } = data

          if (triggers[0].property >= 20 && triggers[0].property <= 34)
            automationTriggerBy.value = 'document'
          else automationTriggerBy.value = 'job'

          triggerList.value = triggers.map(data => sanitizeIncomingData(data, 'trigger'))
          actionList.value = actions.map(data => sanitizeIncomingData(data, 'action'))

          automationDetail.value = {
            name: name,
            description: description,
            is_active: is_active,
            is_automation_locked: is_automation_locked,
            group_operator: group_operator,
            folder_id: folder.id,
            job_type_id: job_type.id,
            isDocument: computed(() => triggerList.value.some(item => item.property >= 20)),
          }
        })
        .finally(() => (isLoading.value = false))
    }

    const changeAutomationTriggerBy = async value => {
      Vue.$dialog({
        title: `Apakah anda yakin untuk merubah tipe object ke ${value}?`,
        body: `Merubah tipe object ke ${value} akan menghapus semua Trigger dan Action dari Automation ini.`,
        maxWidth: '27.5vw',
      })
        .then(() => {
          automationTriggerBy.value = value
        })
        .catch(() => {
          errorHandling('Object tidak diganti!')
        })
    }

    watch(
      () => props.id,
      async () => {
        if (props.isEditing !== true && !props.id) return

        fetchAutomationDetail()
      },
    )

    onMounted(() => {
      if (props.id) return fetchAutomationDetail()
    })

    return {
      folderDetail,
      folderList,
      jobTypeDetail,
      jobTypeList,

      folderDetailComputed,
      automationTriggerBy,
      automationDetail,
      triggerList,
      actionList,
      setAutomationEmpty,
      handleAddNewTrigger,
      handleAddNewAction,
      handleCreateAutomation,
      handleUpdateAutomation,
      hadnleDeleteAutomation,
      changeAutomationTriggerBy,

      propertyList,
      propertyMap,
      operatorList,
      operatorMap,
      actionTypeList,
      actionTypeMap,

      router,
      required,

      icons: {
        mdiClose,
        mdiPlus,
        mdiTuneVertical,
        mdiFilterOutline,
        mdiContainEnd,
        mdiSourceCommit,
        mdiCheck,
        mdiRun,
        mdiShieldOutline,
        mdiWeb,
        mdiFolder,
        mdiFolderLock,
        mdiDelete,
        mdiLockOutline,
        mdiLightningBolt,
        mdiRocketLaunch,
        mdiBriefcaseOutline,
        mdiFileOutline,
      },
    }
  },
}
</script>
