<template>
  <div class="d-flex flex-column overflow-hidden h-full">
    <div>
      <span class="caption ml-1 mb-1">{{ t('Tampilkan berdasarkan') }}</span>
    </div>

    <div class="d-flex mb-4">
      <v-chip
        class="d-flex flex-column align-stretch flex-fill mr-2 pl-3 pr-1 overflow-hidden"
        style="border-radius: 6px; height: 38px"
        :color="filterUnread ? 'primary' : ''"
        @click="filterUnread = !filterUnread"
      >
        <span class="text-subtitle-2 text-truncate">
          {{
            filterUnread ? t('Hanya yang telah terselesaikan') : t('Hanya yang belum terselesaikan')
          }}
        </span>

        <v-icon
          v-if="filterUnread"
          class="ml-auto mr-2"
          size="20"
          style="color: white !important"
        >
          {{ icons.mdiCheck }}
        </v-icon>
      </v-chip>

      <v-menu
        bottom
        center
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="v-card v-sheet--outlined px-4"
            depressed
            outlined
            style="border-radius: 6px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="20">
              {{ icons.mdiFilterVariant }}
            </v-icon>

            <span class="text-subtitle-1 font-weight-medium pl-2">{{ t('Urutkan') }}</span>

            <v-badge
              class="pl-1"
              inline
              v-show="filterCategory.id"
              :value="filterCategory.id"
              :content="filterCategory.id ? '1' : undefined"
            >
            </v-badge>
          </v-btn>
        </template>

        <v-card
          class="d-flex flex-column px-3 pt-2 pb-3"
          width="320px"
        >
          <v-row
            no-gutters
            dense
            class="mb-1"
          >
            <v-col>
              <span class="body-2">{{ t('Urutkan berdasarkan') }}</span>
            </v-col>

            <v-col
              class="py-0 px-0 d-flex justify-end"
              cols="4"
            >
              <v-btn
                text
                class="px-4"
                color="primary"
                style="height: unset !important; min-width: unset !important"
                @click="handleResetSort"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            class="mt-2"
            no-gutters
            dense
          >
            <v-chip
              class="d-flex flex-column align-stretch flex-fill pl-3 pr-1 overflow-hidden"
              style="border-radius: 6px; height: 38px"
              @click="handleToggleSortOrder"
            >
              <v-icon
                class="mr-2"
                size="20"
                style="color: white !important"
              >
                {{ jobsFilter.order === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending }}
              </v-icon>

              <span class="text-subtitle-2 text-truncate">
                {{
                  jobsFilter.order === 'ASC'
                    ? t('Dari terendah ke tertinggi')
                    : t('Dari tertinggi ke terendah')
                }}
              </span>
            </v-chip>
          </v-row>

          <v-row
            class="mt-2"
            no-gutters
            dense
          >
            <v-autocomplete
              v-model="jobsFilter.field"
              solo
              flat
              dense
              outlined
              item-value="target"
              item-text="label"
              auto-select-first
              hide-details="auto"
              :items="jobSortFieldList"
              :placeholder="t('Berdasarkan ketegori')"
            >
              <template #selection="{ item }">
                <v-icon
                  class="mr-2"
                  size="20"
                >
                  {{ item.icon }}
                </v-icon>

                {{ item.label }}
              </template>

              <template #item="{ item }">
                <div class="d-flex align-center flex-grow-1 py-3">
                  <v-icon
                    class="mr-2"
                    size="20"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <span class="subtitle-2"> {{ item.label }}</span>
                </div>
              </template>
            </v-autocomplete>
          </v-row>
        </v-card>
      </v-menu>
    </div>

    <div
      class="d-flex flex-column h-full overflow-auto px-1"
      style="max-height: 100%"
    >
      <template v-if="!filterUnread">
        <v-card
          v-for="(list, index) in Object.values(jobsList).slice(0, 4)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels multiple>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-3 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title }}&nbsp;<span style="opacity: 0.6"> {{ list.count }}</span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-4 px-2"
                style="max-height: 420px"
                v-scroll.self="element => listenScroll(element, list)"
              >
                <template v-if="list.jobs.length === 0">
                  <v-alert
                    class="v-sheet--outlined mb-2 d-flex align-center justify-center flex-fill"
                    width="100%"
                    min-height="150px"
                  >
                    <span class="d-flex w-full text-subtitle-1 text-center">{{
                      list.placeholder
                    }}</span>
                  </v-alert>
                </template>

                <template v-else>
                  <v-card
                    v-for="(job, index) in list.jobs"
                    :key="index"
                    class="d-flex px-4 py-3 mb-3"
                    outlined
                    style="max-width: 100%"
                  >
                    <div class="d-flex flex-column overflow-hidden">
                      <div
                        class="d-flex text-subtitle-2 mb-1 text-no-wrap font-weight-semibold overflow-hidden"
                        style="max-width: 100%"
                      >
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`${hover ? 'text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              opacity: hover ? 1 : 0.6,
                              cursor: 'pointer',
                            }"
                            @click="
                              $router.push({
                                name: 'folder-detail',
                                params: { id: job.job_type.folder.id },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mb-1 mr-1"
                              size="20"
                              :color="job.job_type.folder.color"
                            >
                              {{ icons.mdiFolder }} </v-icon
                            >{{ job.job_type.folder.name }}
                          </span>
                        </v-hover>
                        <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                        <v-hover v-slot="{ hover }">
                          <span
                            :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                            :style="{
                              transition: 'opacity 200ms linear',
                              opacity: hover ? 1 : 0.6,
                              cursor: 'pointer',
                            }"
                            style="opacity: 0.6"
                            @click="
                              $router.push({
                                name: 'jobtype-detail',
                                params: {
                                  id: job.job_type.folder.id,
                                  jobtypeid: job.job_type.id,
                                },
                              })
                            "
                            @click.stop
                          >
                            <v-icon
                              class="mr-1"
                              size="10"
                              :color="job.job_type.color"
                              style="margin-bottom: 2.5px"
                            >
                              {{ icons.mdiCircle }} </v-icon
                            >{{ job.job_type.name }}
                          </span>
                        </v-hover>
                      </div>

                      <v-hover v-slot="{ hover }">
                        <div
                          class="d-flex align-center overflow-hidden"
                          style="max-width: 100%"
                        >
                          <span
                            :class="`text-subtitle-1 text-truncate font-weight-semibold${
                              hover ? ' text-decoration-underline' : ''
                            }`"
                            style="max-width: 100%; cursor: pointer"
                            @click="
                              $router.push({
                                name: 'job-detail',
                                params: {
                                  id: job.id,
                                },
                              })
                            "
                            @click.stop
                          >
                            {{ job.name }}
                          </span>

                          <v-slide-x-transition>
                            <v-icon
                              v-show="hover"
                              class="ml-1"
                              size="14"
                              style="margin-top: 3px"
                            >
                              {{ icons.mdiOpenInNew }}
                            </v-icon>
                          </v-slide-x-transition>
                        </div>
                      </v-hover>
                    </div>

                    <v-spacer></v-spacer>

                    <v-menu
                      bottom
                      center
                      offset-y
                      style="z-index: 205"
                      :close-on-content-click="false"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="ml-2"
                          icon
                          depressed
                          small
                          style="border-radius: 4px"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectedJob = job"
                        >
                          <v-icon size="14">
                            {{ icons.mdiDotsVertical }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-card
                        class="d-flex flex-column px-3 pt-2 pb-3"
                        width="250px"
                      >
                        <span class="body-2 mb-2">{{ t('Quick Actions') }}</span>

                        <SingleSelectInput
                          :value="job.status"
                          :items="job.job_type.statuses"
                          @input="handleUpdateAttribute('status', $event, 'single', job)"
                        >
                          <template #activator="{ attrs, on, editMode }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              depressed
                              class="d-flex justify-start px-2 mb-1"
                              style="border-radius: 4px"
                              @click="editMode = true"
                            >
                              <v-icon
                                class="ml-1"
                                size="20"
                              >
                                {{ icons.mdiProgressCheck }}
                              </v-icon>

                              <v-chip
                                v-if="job.status"
                                class="mx-2"
                                v-on="on"
                                v-bind="attrs"
                                x-small
                                label
                                style="cursor: pointer"
                                :color="job.status.color"
                                @click="editMode = true"
                              >
                                {{ job.status.name }}
                              </v-chip>
                              <span v-else>-</span>
                            </v-btn>
                          </template>
                        </SingleSelectInput>

                        <SingleSelectInput
                          :value="job.priority"
                          :items="job.job_type.priorities"
                          @input="handleUpdateAttribute('priority', $event, 'single', job)"
                        >
                          <template #activator="{ attrs, on, editMode }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              depressed
                              class="d-flex justify-start px-2 mb-1"
                              style="border-radius: 4px"
                              @click="editMode = true"
                            >
                              <v-icon
                                class="ml-1"
                                size="20"
                              >
                                {{ icons.mdiLightbulbAlertOutline }}
                              </v-icon>

                              <v-chip
                                v-if="job.priority"
                                class="mx-2"
                                v-on="on"
                                v-bind="attrs"
                                x-small
                                label
                                style="cursor: pointer"
                                :color="job.priority.color"
                                @click="editMode = true"
                              >
                                {{ job.priority.name }}
                              </v-chip>
                              <span
                                class="mx-2"
                                v-else
                                >-</span
                              >
                            </v-btn>
                          </template>
                        </SingleSelectInput>

                        <DateInput
                          :value="job.expected_close_date"
                          @input="handleUpdateAttribute('expected_close_date', $event, 'date', job)"
                        >
                          <template #activator="{ attrs, on, editMode }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              depressed
                              class="d-flex justify-start px-2 mb-1"
                              style="border-radius: 4px"
                              @click="editMode = true"
                            >
                              <v-icon
                                class="ml-1"
                                size="20"
                              >
                                {{ icons.mdiCalendarRemoveOutline }}
                              </v-icon>

                              <span class="mx-2">
                                {{
                                  job.expected_close_date
                                    ? dateFormat(job.expected_close_date, 3)
                                    : 'Tidak Memiliki End Date'
                                }}
                              </span>
                            </v-btn>
                          </template>
                        </DateInput>

                        <v-menu
                          @input="controlQuickActionAssign()"
                          :close-on-content-click="false"
                          style="z-index: 206"
                        >
                          <template #activator="{ attrs, on }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              depressed
                              class="d-flex justify-start px-2 mb-1"
                              style="border-radius: 4px"
                            >
                              <v-icon
                                class="ml-1"
                                size="20"
                              >
                                {{ icons.mdiClipboardAccountOutline }}
                              </v-icon>

                              <UserLimitElements
                                v-if="job.assignedTo && job.assignedTo.length"
                                class="mx-2"
                                :users="job.assignedTo"
                                :size="28"
                              />

                              <span
                                v-else
                                class="mx-2"
                              >
                                Tidak Memiliki Assignee
                              </span>
                            </v-btn>
                          </template>

                          <UserTeamSelector
                            v-if="selectedJob?.id === job.id"
                            v-model="assignUserId"
                          />
                        </v-menu>

                        <v-btn
                          depressed
                          text
                          color="error"
                          class="d-flex justify-start px-2 mb-1"
                          style="border-radius: 4px"
                          @click="deleteJob(job.id)"
                        >
                          <v-icon
                            class="ml-1"
                            size="20"
                          >
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>

                          <h3
                            class="font-medium pl-2"
                            style="
                              color: var(--v-error-base) !important;
                              caret-color: var(--v-error-base) !important;
                            "
                          >
                            {{ t('Hapus') }}
                          </h3>
                        </v-btn>
                      </v-card>
                    </v-menu>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>

      <template v-else>
        <v-card
          v-for="(list, index) in Object.values(jobsList).slice(4)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels multiple>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-3 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title }}&nbsp;<span style="opacity: 0.6"> {{ list.count }}</span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-4 px-2"
                style="max-height: 420px"
              >
                <template v-if="list.jobs.length === 0">
                  <v-alert
                    class="v-sheet--outlined mb-2 d-flex align-center justify-center flex-fill"
                    width="100%"
                    min-height="150px"
                  >
                    <span class="d-flex w-full text-subtitle-1 text-center">{{
                      list.placeholder
                    }}</span>
                  </v-alert>
                </template>

                <template v-else>
                  <v-card
                    v-for="(job, index) in list.jobs"
                    :key="index"
                    class="d-flex flex-column px-4 py-3 mb-3 overflow-hidden"
                    outlined
                    style="max-width: 100%"
                  >
                    <div
                      class="d-flex text-subtitle-2 mb-1 text-no-wrap font-weight-semibold overflow-hidden"
                      style="max-width: 100%"
                    >
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`${hover ? 'text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            opacity: hover ? 1 : 0.6,
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'folder-detail',
                              params: { id: job.job_type.folder.id },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mb-1 mr-1"
                            size="20"
                            :color="job.job_type.folder.color"
                          >
                            {{ icons.mdiFolder }} </v-icon
                          >{{ job.job_type.folder.name }}
                        </span>
                      </v-hover>
                      <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            opacity: hover ? 1 : 0.6,
                            cursor: 'pointer',
                          }"
                          style="opacity: 0.6"
                          @click="
                            $router.push({
                              name: 'jobtype-detail',
                              params: {
                                id: job.job_type.folder.id,
                                jobtypeid: job.job_type.id,
                              },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mr-1"
                            size="10"
                            :color="job.job_type.color"
                            style="margin-bottom: 2.5px"
                          >
                            {{ icons.mdiCircle }} </v-icon
                          >{{ job.job_type.name }}
                        </span>
                      </v-hover>
                    </div>

                    <v-hover v-slot="{ hover }">
                      <div
                        class="d-flex align-center overflow-hidden"
                        style="max-width: 100%"
                      >
                        <span
                          :class="`text-subtitle-1 text-truncate font-weight-semibold${
                            hover ? ' text-decoration-underline' : ''
                          }`"
                          style="max-width: 100%; cursor: pointer"
                          @click="
                            $router.push({
                              name: 'job-detail',
                              params: {
                                id: job.id,
                              },
                            })
                          "
                          @click.stop
                        >
                          {{ job.name }}
                        </span>

                        <v-slide-x-transition>
                          <v-icon
                            v-show="hover"
                            class="ml-1"
                            size="14"
                            style="margin-top: 3px"
                          >
                            {{ icons.mdiOpenInNew }}
                          </v-icon>
                        </v-slide-x-transition>
                      </div>
                    </v-hover>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/inputs/editable/DateInput.vue'
import SingleSelectInput from '@/components/inputs/editable/SingleSelectInput.vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import UserLimitElements from '@/components/misc/UserLimitElements.vue'
import useJob from '@/composables/useJob'
import { jobSortFieldList } from '@/constants/user-detail/sortField'
import { updateCustomAttribute } from '@/graphql/mutations'
import { performAssignObject } from '@/services/generalService'
import { performEditJob } from '@/services/jobService'
import dateFormat from '@/utils/dateFormat'
import errorHandling from '@/utils/errorHandling'
import { useUtils } from '@core/libs/i18n'
import {
  mdiCalendarRemoveOutline,
  mdiCheck,
  mdiCircle,
  mdiClipboardAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFilterVariant,
  mdiFolder,
  mdiLightbulbAlertOutline,
  mdiOpenInNew,
  mdiProgressCheck,
  mdiSortAscending,
  mdiSortDescending,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import Vue, { inject, ref, watch } from 'vue'

export default {
  components: {
    SingleSelectInput,
    DateInput,
    UserTeamSelector,
    UserLimitElements,
  },

  setup(props, { emit }) {
    const jobsList = inject('jobsList')
    const jobsFilter = inject('jobsFilter')
    const selectedJob = ref()
    const filterUnread = ref(false)
    const filterCategory = ref({
      id: null,
      label: 'Pilih kategori',
      icon: null,
      message: null,
    })

    const { t } = useUtils()
    const { deleteJob } = useJob()

    const controlWhenAssigning = ref(false)
    const controlQuickActionAssign = () => {
      controlWhenAssigning.value = !controlWhenAssigning.value

      if (controlWhenAssigning.value) return (initialAssignUserId.value = assignUserId.value)

      if (initialAssignUserId.value.length !== assignUserId.value.length)
        return updateJobAssignUser()

      for (let i = 0; i < assignUserId.value.length; i++) {
        if (initialAssignUserId.value[i] !== assignUserId.value[i]) return updateJobAssignUser()
      }
    }

    const handleToggleSortOrder = () => {
      jobsFilter.value.order = jobsFilter.value.order === 'ASC' ? 'DESC' : 'ASC'
    }
    const handleResetSort = () => {
      jobsFilter.value = {
        order: 'DESC',
        field: 'name',
      }
    }

    const listenScroll = useDebounceFn(async (element, list) => {
      if (
        element.target.offsetHeight + element.target.scrollTop >=
          element.target.scrollHeight - 50 &&
        list.jobs.length < list.count
      )
        emit('handleScrollJob', list.key)
    }, 200)

    // ! Taken Directly from JobCard.vue, by @maulanafanny
    const initialAssignUserId = ref()
    const assignUserId = ref()
    const handleUpdateAttribute = async (key, value, inputType, job) => {
      let payload
      const payloadKey = {
        description: 'description',
        status: 'jobStatusId',
        priority: 'jobPriorityId',
        start_date: 'startDate',
        expected_close_date: 'expectedCloseDate',
      }

      /* TODO: check if value is changed or not, if not return */

      if (typeof key === 'string') {
        switch (inputType) {
          case 'text':
          case 'date':
            payload = { [payloadKey[key]]: value }
            break

          case 'single':
            payload = { [payloadKey[key]]: value.id }
            break
        }

        const { msg } = await performEditJob({
          id: job.id,
          ...payload,
        })

        Vue.notify({
          title: 'Sukses!',
          text: msg,
          type: 'success',
          duration: 300,
        })

        return
      }

      switch (inputType) {
        case 'text':
        case 'date':
        case 'boolean':
          payload = String(value)
          break

        case 'single':
          payload = String(value.id)
          break

        case 'multi':
          payload = `[${value.map(el => el.id).join(',')}]`
          break
      }

      await updateCustomAttribute({
        objectId: job.id,
        typeId: 2,
        values: [{ id: key, value: payload }],
      })

      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil mengcustom attribute job!',
        type: 'success',
        duration: 300,
      })
    }

    const updateJobAssignUser = async () => {
      try {
        await performAssignObject({
          jobId: selectedJob.value.id,
          assignUserId: assignUserId.value.length ? assignUserId.value : [],
        })

        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah user yang ditugaskan!',
        })
      } catch (err) {
        errorHandling(err)
      }
    }

    watch(selectedJob, () => {
      const assignedToData = selectedJob.value.assignedTo.map(item => item.user.id)

      initialAssignUserId.value = assignedToData
      assignUserId.value = assignedToData
    })

    return {
      // i18n
      t,

      jobsList,
      selectedJob,
      jobsFilter,
      filterUnread,
      filterCategory,
      assignUserId,

      listenScroll,
      handleResetSort,
      controlQuickActionAssign,
      handleToggleSortOrder,
      handleUpdateAttribute,
      deleteJob,

      // constant
      jobSortFieldList,

      dateFormat,

      icons: {
        mdiFilterVariant,
        mdiCheck,
        mdiFolder,
        mdiCircle,
        mdiOpenInNew,
        mdiDotsVertical,
        mdiProgressCheck,
        mdiLightbulbAlertOutline,
        mdiCalendarRemoveOutline,
        mdiClipboardAccountOutline,
        mdiDeleteOutline,
        mdiSortAscending,
        mdiSortDescending,
      },
    }
  },
}
</script>
