<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-btn
      small
      text
      outlined
      color="primary"
      min-width="30vw"
      class="app-bar-search-toggler"
      @click="openGlobalSearch = !openGlobalSearch"
    >
      <span class="text--secondary">Cari...</span>
    </v-btn>

    <!-- This is clever hack to hide scrolling 😉 -->
    <v-dialog
      v-model="openGlobalSearch"
      hide-overlay
      transition="dialog-top-transition"
      max-width="1200px"
      :content-class="'global-search-dialog'"
    >
      <v-card
        rounded
        class="global-search-card d-flex flex-column"
        style="height: 90vh"
      >
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchParameter.search"
            class="global-search-text-field"
            hide-details="auto"
            placeholder="Cari sesuatu..."
            autofocus
          >
            <template #prepend-inner>
              <v-icon
                class="me-2"
                size="26"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </div>

        <v-divider />

        <div class="d-flex flex-wrap px-1 pt-1">
          <v-menu
            bottom
            center
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="my-1 mx-1 px-2"
                small
                depressed
                outlined
                :style="{
                  'border-radius': '5px',
                  border:
                    searchParameter.object !== 'all'
                      ? 'solid 1px var(--color-highlight)'
                      : 'solid 1px var(--color-foreground-high)',
                }"
                :color="searchParameter.object !== 'all' ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  style="margin-bottom: 0.5px"
                >
                  {{ objectList.find(item => item.id == searchParameter.object)?.icon }}
                </v-icon>

                <h6
                  class="text-subtitle-2 font-medium pl-1"
                  style="color: inherit !important"
                >
                  {{ objectList.find(item => item.id == searchParameter.object)?.label }}
                </h6>
              </v-btn>
            </template>

            <v-card
              class="d-flex flex-column px-3 pt-2 pb-3"
              width="320px"
            >
              <v-row
                no-gutters
                dense
                class="mb-1"
              >
                <v-col>
                  <span class="body-2">Target objek</span>
                </v-col>

                <v-col
                  class="py-0 px-0 d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    text
                    class="px-4"
                    color="primary"
                    style="height: unset !important; min-width: unset !important"
                    @click="searchParameter.object = 'all'"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                dense
              >
                <v-col
                  v-for="option in objectList"
                  :key="option.id"
                  cols="12"
                >
                  <v-chip
                    class="d-flex flex-column align-stretch flex-fill mb-2"
                    style="border-radius: 8px"
                    :color="searchParameter.object === option.id ? 'primary' : ''"
                    @click="searchParameter.object = option.id"
                  >
                    <v-icon
                      class="mr-2"
                      size="20"
                      :style="{
                        color: searchParameter.object === option.id ? 'white !important' : '',
                      }"
                    >
                      {{ option.icon }}
                    </v-icon>

                    <span class="subtitle-2"> {{ option.label }}</span>

                    <v-icon
                      v-if="searchParameter.object === option.id"
                      class="ml-auto mr-2"
                      size="20"
                      style="color: white !important"
                    >
                      {{ icons.mdiCheck }}
                    </v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <!-- Sort -->
          <v-menu
            bottom
            center
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="my-1 mx-1 px-2"
                small
                depressed
                outlined
                :style="{
                  'border-radius': '5px',
                  border:
                    searchParameter.sort !== 'best_matches'
                      ? 'solid 1px var(--color-highlight)'
                      : 'solid 1px var(--color-foreground-high)',
                }"
                :color="searchParameter.sort !== 'best_matches' ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  style="margin-bottom: 0.5px"
                >
                  {{ sortList.find(item => item.id == searchParameter.sort)?.icon }}
                </v-icon>

                <h6
                  class="text-subtitle-2 font-medium pl-1"
                  style="color: inherit !important"
                >
                  {{ sortList.find(item => item.id == searchParameter.sort)?.label }}
                </h6>
              </v-btn>
            </template>

            <v-card
              class="d-flex flex-column px-3 pt-2 pb-3"
              width="320px"
            >
              <v-row
                no-gutters
                dense
                class="mb-1"
              >
                <v-col>
                  <span class="body-2">Urutkan berdasarkan</span>
                </v-col>

                <v-col
                  class="py-0 px-0 d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    text
                    class="px-4"
                    color="primary"
                    style="height: unset !important; min-width: unset !important"
                    @click="searchParameter.sort = 'best_matches'"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                dense
              >
                <v-col
                  v-for="option in sortList"
                  :key="option.id"
                  cols="12"
                >
                  <v-chip
                    class="d-flex flex-column align-stretch flex-fill mb-2"
                    style="border-radius: 8px"
                    :color="searchParameter.sort === option.id ? 'primary' : ''"
                    @click="searchParameter.sort = option.id"
                  >
                    <v-icon
                      class="mr-2"
                      size="20"
                      :style="{
                        color: searchParameter.sort === option.id ? 'white !important' : '',
                      }"
                    >
                      {{ option.icon }}
                    </v-icon>

                    <span class="subtitle-2"> {{ option.label }}</span>

                    <v-icon
                      v-if="searchParameter.sort === option.id"
                      class="ml-auto mr-2"
                      size="20"
                      style="color: white !important"
                    >
                      {{ icons.mdiCheck }}
                    </v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <!-- Archive Status -->
          <v-menu
            bottom
            center
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="my-1 mx-1 px-2"
                small
                depressed
                outlined
                :style="{
                  'border-radius': '5px',
                  border:
                    searchParameter.archiveStatus !== 2
                      ? 'solid 1px var(--color-highlight)'
                      : 'solid 1px var(--color-foreground-high)',
                }"
                :color="searchParameter.archiveStatus !== 2 ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  style="margin-bottom: 0.5px"
                >
                  {{
                    archiveStatusList.find(item => item.id == searchParameter.archiveStatus)?.icon
                  }}
                </v-icon>

                <h6
                  class="text-subtitle-2 font-medium pl-1"
                  style="color: inherit !important"
                >
                  {{
                    archiveStatusList.find(item => item.id == searchParameter.archiveStatus)?.label
                  }}
                </h6>
              </v-btn>
            </template>

            <v-card
              class="d-flex flex-column px-3 pt-2 pb-3"
              width="320px"
            >
              <v-row
                no-gutters
                dense
                class="mb-1"
              >
                <v-col>
                  <span class="body-2">Status Arsip</span>
                </v-col>

                <v-col
                  class="py-0 px-0 d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    text
                    class="px-4"
                    color="primary"
                    style="height: unset !important; min-width: unset !important"
                    @click="searchParameter.archiveStatus = 2"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                dense
              >
                <v-col
                  v-for="option in archiveStatusList"
                  :key="option.id"
                  cols="12"
                >
                  <v-chip
                    class="d-flex flex-column align-stretch flex-fill mb-2"
                    style="border-radius: 8px"
                    :color="searchParameter.archiveStatus === option.id ? 'primary' : ''"
                    @click="searchParameter.archiveStatus = option.id"
                  >
                    <v-icon
                      class="mr-2"
                      size="20"
                      :style="{
                        color:
                          searchParameter.archiveStatus === option.id ? 'white !important' : '',
                      }"
                    >
                      {{ option.icon }}
                    </v-icon>

                    <span class="subtitle-2"> {{ option.label }}</span>

                    <v-icon
                      v-if="searchParameter.archiveStatus === option.id"
                      class="ml-auto mr-2"
                      size="20"
                      style="color: white !important"
                    >
                      {{ icons.mdiCheck }}
                    </v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <!-- Assigned To -->
          <v-menu
            bottom
            center
            offset-y
            style="z-index: 206"
            @input="openAssignedTo = !openAssignedTo"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                class="my-1 mx-1 px-2"
                small
                depressed
                outlined
                :style="{
                  'border-radius': '5px',
                  border:
                    searchParameter.assignedTo !== null
                      ? 'solid 1px var(--color-highlight)'
                      : 'solid 1px var(--color-foreground-high)',
                }"
                :color="searchParameter.assignedTo !== null ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  style="margin-bottom: 0.5px"
                >
                  {{ icons.mdiAccountArrowRightOutline }}
                </v-icon>

                <h6
                  class="text-subtitle-2 font-medium pl-1"
                  style="color: inherit !important"
                >
                  Ditugaskan Kepada:
                  {{ selectedAssignedUser }}
                </h6>
              </v-btn>
            </template>

            <UserTeamSelector
              v-if="openAssignedTo"
              v-model="searchParameter.assignedTo"
              disable-team-utility
              width="450"
              @input="searchParameter.assignedTo = $event"
            />
          </v-menu>

          <!-- Created By -->
          <v-menu
            bottom
            center
            offset-y
            style="z-index: 206"
            @input="openCreatedBy = !openCreatedBy"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                class="my-1 mx-1 px-2"
                small
                depressed
                outlined
                :style="{
                  'border-radius': '5px',
                  border:
                    searchParameter.createdBy !== null
                      ? 'solid 1px var(--color-highlight)'
                      : 'solid 1px var(--color-foreground-high)',
                }"
                :color="searchParameter.createdBy !== null ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  style="margin-bottom: 0.5px"
                >
                  {{ icons.mdiAccountOutline }}
                </v-icon>

                <h6
                  class="text-subtitle-2 font-medium pl-1"
                  style="color: inherit !important"
                >
                  Dibuat Oleh:
                  {{ selectedCreatedUser }}
                </h6>
              </v-btn>
            </template>

            <UserTeamSelector
              v-if="openCreatedBy"
              v-model="searchParameter.createdBy"
              disable-team-utility
              width="450"
              @input="searchParameter.createdBy = $event"
            />
          </v-menu>
        </div>

        <div
          v-if="resultList.length !== 0"
          class="overflow-auto mx-1 px-1 my-2"
          v-scroll.self="handleScroll"
        >
          <v-hover
            v-for="(object, index) in resultList"
            :key="`${object.type}-globalSearch-${object.id}-${index}`"
            v-slot="{ hover }"
          >
            <v-card
              flat
              class="d-flex flex-column cursor-pointer"
              @click="openObject(object)"
              :style="{
                transition: 'background-color 0.3s',
                'background-color': hover ? 'var(--color-foreground-low)' : '',
              }"
            >
              <v-card-title class="d-flex align-center w-full pt-2 pb-1 px-3">
                <v-icon
                  class="mr-2"
                  size="20"
                >
                  {{ objectMap[object.type]?.icon }}
                </v-icon>

                <h1 class="text-h6 text-medium">
                  {{ object.name }}
                </h1>
                <span class="font-italic text-subtitle-2 text--secondary">
                  &nbsp;&nbsp;–&nbsp;&nbsp;{{ objectMap[object.type]?.label }}
                </span>

                <v-spacer />

                <v-slide-x-reverse-transition mode="out-in">
                  <div v-show="hover">
                    <v-icon
                      class="mr-1"
                      size="18"
                    >
                      {{ icons.mdiOpenInNew }}
                    </v-icon>
                  </div>
                </v-slide-x-reverse-transition>
              </v-card-title>

              <v-card-subtitle
                class="d-flex flex-column ma-0 pt-0 pb-2"
                style="padding-left: 40px"
              >
                <h2
                  v-if="object.description != ''"
                  class="text-subtitle-2 mb-1"
                >
                  <v-icon
                    class="mr-1"
                    size="16"
                    style="margin-bottom: 0.5px"
                  >
                    {{ icons.mdiText }}
                  </v-icon>

                  <HighlightResult
                    :text="object.description"
                    :target="searchParameter.search"
                  />
                </h2>

                <h3 class="text-subtitle-2 text--disabled mb-1">
                  <v-icon
                    class="mr-1"
                    size="16"
                    style="margin-bottom: 0.5px"
                  >
                    {{ icons.mdiCalendarText }}
                  </v-icon>

                  Dibuat {{ dateFormat(object.created_at, 11) }}

                  &nbsp;–&nbsp;

                  <v-icon
                    class="mr-1"
                    size="16"
                    style="margin-bottom: 0.5px"
                  >
                    {{ icons.mdiCalendarEdit }}
                  </v-icon>

                  Diubah {{ dateFormat(object.updated_at, 11) }}
                </h3>

                <h4
                  v-if="object.folder && object.jobtype"
                  class="text-subtitle-2 text--disabled mb-1"
                >
                  <v-icon
                    :color="object.folder.color"
                    class="mr-1"
                    size="16"
                    style="margin-bottom: 0.5px"
                  >
                    {{ object.folder.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                  </v-icon>
                  {{ object.folder.name }}

                  &nbsp;/&nbsp;

                  <v-badge
                    dot
                    inline
                    :color="object.jobtype.color"
                    style="margin-bottom: 0.5px"
                  />
                  <v-icon
                    v-if="!object.jobtype.is_public"
                    class="mr-1"
                    size="14"
                    style="margin-bottom: 0.5px"
                  >
                    {{ icons.mdiLockOutline }}
                  </v-icon>
                  {{ object.jobtype.name }}
                </h4>

                <h4 class="text-subtitle-2 text--disabled">
                  <v-icon
                    class="mr-1"
                    size="16"
                    style="margin-bottom: 0.5px"
                  >
                    {{ icons.mdiCubeOutline }}
                  </v-icon>

                  Workspace &nbsp;–&nbsp;

                  {{ object.workspace.name }}
                </h4>
              </v-card-subtitle>

              <v-divider
                v-if="resultList.indexOf(object) !== resultList.length - 1"
                class="mx-1 mt-1"
                style="border-color: var(--color-foreground-low)"
              />
            </v-card>
          </v-hover>
        </div>

        <v-card
          v-else
          class="d-flex flex-column align-center justify-center"
          style="height: 700px"
        >
          <v-icon
            size="100"
            class="mb-2"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>

          <template
            v-if="searchParameter.search !== '' && resultList.length === 0 && !loadingGlobalSearch"
          >
            <h1 class="text-h5 text-medium">Tidak ada hasil yang ditemukan</h1>
            <h2 class="text-subtitle-2 text--secondary">Coba kata kunci lain</h2>
          </template>

          <template v-else>
            <h1 class="text-h5 text-medium">Mulai pencarian</h1>
            <h2 class="text-subtitle-2 text--secondary">
              Masukkan kata kunci untuk memulai pencarian
            </h2>
          </template>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import useComment from '@/composables/useComment'
import useGlobalSearch from '@/composables/useGlobalSearch'
import { archiveStatusList, objectList, objectMap, sortList } from '@/constants/global-search'
import store from '@/store'
import dateFormat from '@/utils/dateFormat'
import { useRouter } from '@core/utils'
import {
  mdiAccountArrowRightOutline,
  mdiAccountOutline,
  mdiCalendarEdit,
  mdiCalendarText,
  mdiCheck,
  mdiCubeOutline,
  mdiFolder,
  mdiFolderLock,
  mdiInformationOutline,
  mdiMagnify,
  mdiOpenInNew,
  mdiText,
} from '@mdi/js'
import { useDebounceFn, useVModel } from '@vueuse/core'
import { computed, provide, ref, watch } from 'vue'
import HighlightResult from './components/HighlightResult.vue'

export default {
  components: {
    UserTeamSelector,
    HighlightResult,
  },

  props: {
    shallShowFullSearch: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    filter: {
      type: Function,
      default: null,
    },
    searchQuery: {
      type: [String, null],
      default: '',
    },
  },

  setup(props, { emit }) {
    const shallShowFullSearchLocal = useVModel(props, 'shallShowFullSearch', emit)
    const searchQueryLocal = useVModel(props, 'searchQuery', emit)

    const { fetchGlobalSearch, loadingGlobalSearch } = useGlobalSearch()
    const { router } = useRouter()
    const { getCommentOrigin } = useComment()

    const openGlobalSearch = ref(false)

    const pagination = ref({
      limit: 10,
      offset: 0,
    })
    const searchParameter = ref({
      search: '',
      object: 'all',
      archiveStatus: 2,
      sort: 'best_matches',
      assignedTo: null,
      createdBy: null,
    })
    const openAssignedTo = ref(false)
    const openCreatedBy = ref(false)
    const selectedAssignedUser = computed(() =>
      searchParameter.value.assignedTo
        ? store.getters.getCurrentWorkspaceUserList
            .reduce((acc, user) => {
              if (searchParameter.value.assignedTo.includes(user.id)) acc.push({ user })

              return acc
            }, [])
            .map(el => el.user.name)
            .join(', ')
        : '',
    )
    const selectedCreatedUser = computed(() =>
      searchParameter.value.createdBy
        ? store.getters.getCurrentWorkspaceUserList
            .reduce((acc, user) => {
              if (searchParameter.value.createdBy.includes(user.id)) acc.push({ user })

              return acc
            }, [])
            .map(el => el.user.name)
            .join(', ')
        : '',
    )

    const resultList = ref([])
    const searchResultCap = ref(false)

    provide(searchParameter.value.search, 'target')

    const closeSearch = () => {
      searchParameter.value = {
        search: '',
        object: 'all',
        archiveStatus: 2,
        sort: 'best_matches',
        assignedTo: null,
        createdBy: null,
      }

      pagination.value = {
        limit: 10,
        offset: 0,
      }

      resultList.value = []

      openGlobalSearch.value = false
    }

    const requestSearch = useDebounceFn(() => {
      if (searchParameter.value.search === '') return (resultList.value = [])

      fetchGlobalSearch(searchParameter.value, pagination.value).then(value => {
        resultList.value = value
        pagination.value.offset = value.length
      })
    }, 400)

    const openObject = async object => {
      const numberId = parseInt(object.id)

      if (object.type === 'job comment') {
        const data = await getCommentOrigin(numberId)

        console.log(data)
        if (!data) return

        return router.push({
          name: 'job-detail',
          params: { id: data?.job.id, commentId: numberId },
        })
      } else if (object.type === 'document comment') {
        const data = await getCommentOrigin(numberId)

        console.log(data)
        if (!data) return

        return router.push({
          name: 'document-detail',
          params: { uuid: data?.document.uuid },
        })
      } else if (object.type === 'attachment') {
        return router.push({
          name: 'attachment-detail',
          params: {
            id: object.id,
          },
        })
      } else
        router.replace({
          name: objectMap[object.type].route,
          params: { id: object.id },
        })

      closeSearch()
    }

    const handleScroll = useDebounceFn(async data => {
      if (searchResultCap.value) return

      if (
        data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 500 &&
        !loadingGlobalSearch.value
      ) {
        await fetchGlobalSearch(searchParameter.value, pagination.value).then(value => {
          if (value.length === 0) searchResultCap.value = true
          else {
            resultList.value.push(...value)
            pagination.value.offset = resultList.value.length
          }
        })
      }
    }, 200)

    watch(openGlobalSearch, value => {
      store.commit('app/TOGGLE_CONTENT_OVERLAY', value)

      if (!value) closeSearch()
    })

    watch(
      searchParameter,
      () => {
        if (searchParameter.value.assignedTo?.length == 0) searchParameter.value.assignedTo = null
        if (searchParameter.value.createdBy?.length == 0) searchParameter.value.createdBy = null

        searchResultCap.value = false

        pagination.value = {
          limit: 10,
          offset: 0,
        }

        resultList.value = []

        requestSearch()
      },
      {
        deep: true,
      },
    )

    return {
      loadingGlobalSearch,
      openGlobalSearch,

      store,
      searchParameter,
      openAssignedTo,
      openCreatedBy,
      selectedAssignedUser,
      selectedCreatedUser,
      resultList,

      sortList,
      objectList,
      objectMap,
      archiveStatusList,

      dateFormat,
      handleScroll,
      requestSearch,
      closeSearch,
      openObject,

      icons: {
        mdiAccountArrowRightOutline,
        mdiAccountOutline,
        mdiCalendarEdit,
        mdiCalendarText,
        mdiCheck,
        mdiCubeOutline,
        mdiMagnify,
        mdiOpenInNew,
        mdiText,
        mdiInformationOutline,
        mdiFolder,
        mdiFolderLock,
      },

      // Legacy
      shallShowFullSearchLocal,
      searchQueryLocal,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
.global-search-dialog {
  overflow: hidden;
}

.result-list {
  @include style-scroll-bar();
}

@include theme(app-bar-autocomplete-box) using ($material) {
  div[role='combobox'] {
    background-color: map-deep-get($material, 'cards');
  }
}
.global-search-text-field >>> .v-input__slot::before {
  border-style: none !important;
}

.global-search-text-field {
  margin: 0 0px !important;
  padding: 10px 10px !important;
  font-size: medium;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}

.v-input__control::before {
  border-style: none !important;
}

// ————————————————————————————————————
//* ——— Horizontal Nav
// ————————————————————————————————————

.content-layout.horizontal-nav {
  .app-system-bar {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    z-index: 7;

    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }

    // ? In Full content contet have padding of 1.5rem
    &:not(.app-system-bar-boxed) {
      .app-bar-autocomplete-box {
        max-width: calc(100% - 1.5rem * 2);
        @include ltr() {
          margin-left: 1.5rem;
        }
        @include rtl() {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

.app-bar-search-toggler {
  &.v-btn {
    background-color: map-deep-get($material-light, 'buttons', 'focused-alt') !important;
    border-radius: 8px;
  }

  .theme--dark & {
    &.v-btn {
      background-color: map-deep-get($material-dark, 'buttons', 'focused-alt') !important;
    }
  }
}
</style>
