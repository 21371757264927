<template>
  <v-card class="d-flex flex-column px-3 h-full">
    <h4 class="mb-1">Utama</h4>
    <v-form
      ref="workspaceForm"
      lazy-validation
      class="d-flex flex-column h-full"
    >
      <div class="d-flex">
        <div class="d-flex flex-column mr-4">
          <span class="text-caption mb-1">Icon Workspace</span>

          <v-card
            width="116"
            height="116"
            outlined
            class="d-flex align-center justify-center"
            @click="$refs.workspaceIconPicker.click()"
          >
            <v-img
              v-if="localDetail.workspace.photo"
              :src="localDetail.workspace.photo"
              height="116"
              width="116"
              class="rounded"
            />
            <v-icon
              v-else
              class="text--disabled"
              size="32"
            >
              {{ icons.mdiImage }}
            </v-icon>
          </v-card>
          <input
            ref="workspaceIconPicker"
            type="file"
            class="d-none"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange($event.target.files)"
          />
        </div>

        <div class="d-flex flex-column w-full">
          <span class="text-caption mb-1">Nama Workspace</span>
          <v-text-field
            v-model="localDetail.workspace.name"
            :rules="[required]"
            hide-details="auto"
            placeholder="Input nama workspace"
            outlined
            dense
            class="mb-2"
          />
          <span class="text-caption mb-1">Workspace ID</span>
          <v-text-field
            v-model="localDetail.workspace.identifier_id"
            :rules="[required, alphaDashValidator, maxLength(workspaceData.identifier, 20)]"
            placeholder="Input Workspace ID"
            outlined
            dense
            hide-details="auto"
            :error="isIDError && !isIDApproved"
            :success="isIDApproved && !isIDError"
            :error-messages="isIDError && !isIDApproved ? 'ID workspace tidak tersedia' : null"
            @keyup="checkID"
          >
            <template #append>
              <v-menu
                open-on-hover
                offset-y
                left
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="text--disabled"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiHelpCircleOutline }}
                  </v-icon>
                </template>
                <v-card
                  max-width="300"
                  class="pa-3 d-flex flex-column"
                >
                  <span class="text-caption font-medium mb-2">Workspace ID</span>
                  <span class="text-caption"
                    >Workspace ID digunakan untuk mengatur alamat URL unik dari workspace mu.</span
                  >
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </div>
      </div>

      <!-- <span class="text-caption error--text mb-1">{{ errorPhoto }}</span>
      <h4 class="mb-1 mt-4">Visibilitas</h4>
      <span class="d-block mb-1 text-caption">
        Atur visibilitas default dari masing masing objek yang akan dibuat.
      </span>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.customer"
            label="Visibilitas Pelanggan"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.job"
            label="Visibilitas Job"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.tasks"
            label="Visibilitas Task"
          />
        </v-col>
      </v-row> -->

      <div class="mt-6 mt-auto ml-auto">
        <v-btn
          color="primary"
          :loading="loading"
          @click="update"
        >
          Update Workspace
        </v-btn>
      </div>
    </v-form>
    <div v-if="role ? role.id === 2 : false">
      <v-divider class="my-6" />
      <div class="d-flex flex-column flex-md-row">
        <div>
          <h4 class="mb-1 text-error">Hapus Workspace</h4>
          <span
            >Hapus workspace beserta data objek (pelanggan, produk, dan job). Data yang dihapus
            tidak dapat dikembalikan.</span
          >
        </div>
        <div class="mt-4 mt-md-0 ml-md-auto">
          <v-btn
            small
            color="error"
            outlined
            :loading="loading"
            @click="deleteWorkspaceFunc"
          >
            Hapus Workspace
          </v-btn>
        </div>
      </div>
      <v-divider class="my-6" />
    </div>
  </v-card>
</template>

<script>
import { deleteWorkspace, identifierIDChecker, updateWorkspace } from '@/graphql/mutations'
import { myRole } from '@/graphql/queries'
import router from '@/router'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { alphaDashValidator, maxLength, required } from '@core/utils/validation'
import { mdiHelpCircleOutline, mdiImage } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import Vue, { inject, onMounted, ref } from 'vue'

export default {
  name: 'WorkspaceSetting',

  setup(props, { emit }) {
    const workspaceDetail = inject('workspaceDetail')

    const localDetail = ref(workspaceDetail)

    const workspaceData = ref({
      id: null,
      name: '',
      identifier: '',
      photo: null,
      photoPreview: '',
      defaultVisibility: {
        job: 6,
        tasks: 6,
        customer: 6,
      },
    })
    const identifierID = ref('')
    const isIDApproved = ref(false)
    const isIDError = ref(false)
    const loading = ref(false)
    const workspaceForm = ref()
    const errorPhoto = ref('')
    const role = ref()

    const fetchRole = () => {
      loading.value = true
      apolloClient
        .query({
          query: myRole,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          role.value = data.myRole
          loading.value = false
        })
        .catch(err => {
          loading.value = false
          errorHandling(err)
        })
    }
    const checkID = useDebounceFn(() => {
      if (identifierID.value !== workspaceData.value.identifier) {
        isIDApproved.value = false
        isIDError.value = false
        apolloClient
          .mutate({
            mutation: identifierIDChecker,
            variables: {
              identifier_id: workspaceData.value.identifier,
            },
          })
          .then(result => {
            if (result.data.identifierIDChecker.status === 'success') {
              isIDApproved.value = true
              isIDError.value = false
            } else {
              isIDApproved.value = false
              isIDError.value = true
            }
          })
      } else {
        isIDApproved.value = true
        isIDError.value = false
      }
    }, 1000)
    onMounted(() => {
      identifierID.value = workspaceDetail.value.identifier_id

      workspaceData.value = {
        id: workspaceDetail.value.id,
        name: workspaceDetail.value.name,
        identifier: workspaceDetail.value.identifier_id,
        photo: null,
        photoPreview: workspaceDetail.value.photo,
        defaultVisibility: {
          job: workspaceDetail.value.defaultVisibilityId_job,
          customer: workspaceDetail.value.defaultVisibilityId_customer,
          tasks: workspaceDetail.value.defaultVisibilityId_tasks,
        },
      }
      fetchRole()
    })
    const onFileChange = files => {
      errorPhoto.value = ''

      // check for error photo
      if (files.length) {
        if (!files[0].type.includes('image')) errorPhoto.value = 'File tidak valid'
        if (files[0].size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

        workspaceData.value.photoPreview = URL.createObjectURL(files[0])

        // eslint-disable-next-line prefer-destructuring
        workspaceData.value.photo = files[0]
      }
    }
    const update = () => {
      if (workspaceForm.value.validate() && !errorPhoto.value.length && !isIDError.value) {
        const { id, name, identifier, photo } = workspaceData.value
        loading.value = true
        apolloClient
          .mutate({
            mutation: updateWorkspace,
            variables: {
              name,
              identifier_id: identifier,
              photo,
              workspace_id: id,
            },
          })
          .then(() => {
            loading.value = false
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengupdate workspace!',
            })
          })
          .catch(err => {
            loading.value = false
            errorHandling(err, 'Update Workspace')
          })
      } else {
        Vue.notify({
          type: 'warn',
          title: 'Tidak Valid',
          text: 'Harap cek kembali data anda',
        })
      }
    }

    const deleteWorkspaceFunc = () => {
      Vue.$dialog({
        title: 'Hapus workspace?',
        body: 'Data yang dihapus tidak dapat dikembalikan',
      }).then(result => {
        if (result) {
          apolloClient
            .mutate({
              mutation: deleteWorkspace,
              variables: {
                workspace_id: workspaceData.value.id,
              },
            })
            .then(() => {
              loading.value = false
              store.dispatch('getProfileInfo').then(() => {
                Vue.notify({
                  title: 'Sukses!',
                  text: 'Berhasil menghapus workspace!',
                })
                emit('onDelete')
                router.push(`/${store.state.workspace.workspaceList[0].workspace.identifier_id}`)
              })
            })
            .catch(err => {
              loading.value = false
              errorHandling(err, 'Hapus Workspace')
            })
        }
      })
    }

    return {
      workspaceDetail,
      localDetail,

      isIDApproved,
      isIDError,
      errorPhoto,
      workspaceData,
      workspaceForm,
      loading,
      onFileChange,
      update,
      deleteWorkspaceFunc,
      role,
      checkID,
      icons: {
        mdiImage,
        mdiHelpCircleOutline,
      },

      // validations
      required,
      alphaDashValidator,
      maxLength,
    }
  },
}
</script>

<style></style>
