import gql from 'graphql-tag'

export const teamHierarchy = gql`
  query teamHierarchy($workspace_id: Float!) {
    teamHierarchy(workspace_id: $workspace_id) {
      teams {
        ...recursiveTeams
      }
      usersNotInTeam {
        ...userDetail
      }
    }
  }

  fragment userDetail on User {
    id
    name
    phone
    email
    photo
    is_guest
  }
  fragment teamDetail on Teams {
    id
    name
    status
    is_public
    description
    parent {
      id
    }
    created_by {
      id
    }
    users {
      user {
        ...userDetail
      }
    }
  }
  fragment recursiveTeams on Teams {
    ...teamDetail
    children {
      ...teamDetail
      children {
        ...teamDetail
        children {
          ...teamDetail
          children {
            ...teamDetail
            children {
              ...teamDetail
              children {
                ...teamDetail
                children {
                  ...teamDetail
                  children {
                    ...teamDetail
                    children {
                      ...teamDetail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
