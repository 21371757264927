<template>
  <v-card
    class="overflow-hidden"
    outlined
    id="mentionMenuList"
    min-width="410px"
    max-width="410px"
  >
    <v-tabs
      v-model="tab"
      hide-slider
      class="flex-grow-1 pt-2 px-2"
      height="36px"
    >
      <v-tab class="rounded-lg overflow-hidden">
        <v-icon
          size="18"
          class="me-3"
        >
          {{ icons.mdiAccountGroup }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">User</span>
      </v-tab>

      <v-tab class="ml-1 rounded-lg overflow-hidden">
        <v-icon
          size="18"
          class="me-3"
        >
          {{ icons.mdiFileDocumentMultiple }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">Dokumen</span>
      </v-tab>

      <v-tab
        class="ml-1 rounded-lg overflow-hidden"
        disabled
      >
        <v-icon
          size="18"
          class="me-3"
        >
          {{ icons.mdiBriefcaseOutline }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">Job</span>
      </v-tab>

      <v-tab-item class="pb-2 pt-1">
        <AutomationCustomField
          autofocus
          hide-details
          disable-require
          return-detail
          type="user-single-select"
          :value="usersObjectSelected"
          @input="
            selectedObject = $event
            mentionHandler()
          "
          style="border: none"
        />
      </v-tab-item>

      <v-tab-item>
        <documentSelector
          single-select
          @update:selectedDocuments="
            selectedObject = $event
            mentionHandler()
          "
        />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import DocumentSelector from '@/components/inputs/DocumentSelector.vue'
import { mdiAccountGroup, mdiBriefcaseOutline, mdiFileDocumentMultiple } from '@mdi/js'
import { ref, watch } from 'vue'
import AutomationCustomField from '../../../../views/automation/components/AutomationCustomField.vue'

export default {
  components: {
    DocumentSelector,
    AutomationCustomField,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
      required: true,
    },
    topLevelNodeType: {
      type: String,
      default: '',
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const tab = ref()
    const selectedObject = ref()

    const mentionHandler = () => {
      if (selectedObject.value == undefined) return

      let content

      if (selectedObject.value?.uuid)
        content = {
          type: 'documentMention',
          attrs: {
            uuid: selectedObject.value.uuid,
            label: selectedObject.value.name.trim(),
            docIsPublic: selectedObject.value.is_public,
            docPermission: JSON.stringify(
              selectedObject.value.document_permissions.map(permission => permission.user.id),
            ),
            createdBy: selectedObject.value.created_by.id,
          },
        }
      else if (selectedObject.value?.id)
        content = {
          type: 'userMention',
          attrs: {
            id: selectedObject.value.id,
            label: selectedObject.value.name.trim(),
          },
        }

      props.editor.chain().focus().insertContentAt(props.instance.range, content).run()
    }

    watch(tab, () => {
      selectedObject.value = undefined
    })

    return {
      tab,
      selectedObject,

      mentionHandler,

      icons: {
        mdiAccountGroup,
        mdiFileDocumentMultiple,
        mdiBriefcaseOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.slash-command-list {
  @include style-scroll-bar();
  overflow-y: auto;
}
</style>
