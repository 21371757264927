<!-- !-IMPO: no object should be disabled -->
<!-- ?-NOTE: Folder show all avaiable user in the selected Workspace -->
<!-- !-SCRP: JobType shows only user that has permission to Folder -->
<!-- ?-NOTE: JobType show all avaiable user too, due to SharedJobType functionality -->
<!-- ?-NOTE: Job only show users with access to JobType -->
<!-- —————————————————————————————————————————————————————————————————————————————— -->
<!-- ?-NOTE: Document show... what? -->

<template>
  <div
    :style="{
      overflowY: 'auto',
      height: isNaN(height) ? height : `${height}px`,
      maxHeight: isNaN(height) ? height : `${height}px`,
      width: isNaN(width) ? width : `${width}px`,
      maxWidth: isNaN(width) ? width : `${width}px`,
    }"
  >
    <multi-tree-view
      v-if="hierarchyList.length && !loading"
      v-model="selectedKeys"
      :items="hierarchyList"
      :search="searchValue"
      class="user-selector-treeview text-subtitle-2 pb-2"
    >
      <template #prepend="{ item }">
        <v-simple-checkbox
          :value="item.is_selected"
          :indeterminate="isTeam(item.key) && isIndeterminate(item)"
          :disabled="item.is_disabled"
          color="primary"
          class="my-0 ps-1 py-0 mx-auto v-icon--dense v-input--dense v-input--selection-controls"
          @input="handleSelect(item, $event)"
        />
      </template>

      <template #label="{ item }">
        <div v-if="!item.users">
          <v-avatar
            size="30"
            class="mr-2 v-avatar-light-bg"
          >
            <v-img
              v-if="item.photo"
              :src="item.photo"
            />
            <span v-else>{{ avatarText(item?.name) }}</span>
          </v-avatar>
          <span>{{ item?.name }}</span>
          <v-chip
            x-small
            v-if="item.id === currentUserId"
            color="primary"
            class="ml-2 px-2"
          >
            Anda
          </v-chip>
        </div>
        <span v-else>{{ item?.name }}</span>
      </template>

      <template #append="{ item }">
        <slot
          name="append"
          :object-owner="objectOwner"
          :item="item"
          :user="usersList[item?.id] || undefined"
        />

        <template v-if="!disableTeamUtility">
          <div v-if="isTeam(item.key) && !isJoinedTeam(item.status)">
            <v-btn
              v-if="item.is_public"
              x-small
              color="primary"
              :loading="loadingJoinTeam === item.id"
              @click="handleJoinTeam({ id: item.id, isPublic: item.is_public })"
            >
              Join Team
            </v-btn>
            <v-btn
              v-else
              x-small
              color="primary"
              :loading="loadingJoinTeam === item.id"
              @click="handleJoinTeam({ id: item.id, isPublic: item.is_public })"
            >
              {{ item.status === 'requested' ? 'Request Ulang' : 'Request Join' }}
            </v-btn>
          </div>
          <div v-else-if="isInvitable(item)">
            <InvitePermission
              :object-type="objectType"
              :object-detail="objectDetail"
              :user-id="item.id"
              :is-guest="item.is_guest ? true : false"
              @success="item.object_permission = $event"
            />
          </div>
        </template>
      </template>
    </multi-tree-view>

    <v-sheet
      v-else
      height="300px"
    >
      <v-skeleton-loader
        type="list-item, list-item-two-line, list-item-two-line, list-item-three-line"
      />
    </v-sheet>
  </div>
</template>

<script setup>
import InvitePermission from '@/components/inputs/invite-permission/InvitePermission.vue'
import useTeam from '@/composables/useTeam'
import { folderPermissionMap } from '@/constants/permission'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import Vue, { computed, onMounted, ref, watch } from 'vue'
import MultiTreeView from './MultiTreeView.vue'

const TEAM_PREFIX = 't'
const USER_PREFIX = 'u'

const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },

  // Styling
  height: {
    type: [Number, String],
    default: 300,
  },
  width: {
    type: [Number, String],
    default: 300,
  },

  objectOwner: {
    type: Object,
  },
  permissionList: {
    type: Array,
  },
  searchValue: {
    type: String,
  },
  returnOnlyId: {
    type: Boolean,
  },
  returnOnlySelect: {
    type: Array,
    validator(value) {
      const validKeys = [
        'permission_type',
        'id',
        'key',
        'name',
        'email',
        'photo',
        'is_guest',
        'is_public',
        'is_selected',
        'is_disabled',
      ]

      return value.every(key => validKeys.includes(key))
    },
  },
  disableTeamUtility: {
    type: Boolean,
  },
  userDisabledRule: {
    type: Function,
    default: () => false,
  },
})

const { joinTeam, requestJoin, fetchTeamHiearchy } = useTeam()

const ownerId = ref()
const loading = ref(false)
const loadingJoinTeam = ref(0)

const hierarchyList = ref([])
const usersList = ref({})

const selectedKeys = ref([])
const selectedUsers = computed(() =>
  selectedKeys.value.map(key => usersList.value[key.split('-')[1]]),
)

const currentUserId = computed(() => store.getters.getUserData.id)
const isUserLoginOwner = computed(() => currentUserId.value === ownerId.value)

const createTrackableUser = (key, user) => {
  if (!usersList.value[key]) Vue.set(usersList.value, key, user)
}

// —————————————————————————————— Checkbox Utility ——————————————————————————————— //
const isIndeterminate = item => {
  if (!isTeam(item.key)) return false

  if (item.users.every(child => child.is_selected)) return false

  return item.users.some(child => child.is_selected)
}

/**
 * ?NOTE: How deos this mapper work?
 *
 * @param team
 */
const mapUsers = team => {
  return team.users.map(item => {
    const { id, name, email, photo, is_guest } = item.user

    const key = `${USER_PREFIX}-${id}`
    let permission_type =
      folderPermissionMap[
        props.permissionList?.find(object => object?.user?.id == id)?.permission_type
      ]?.id || 4

    let data = {
      permission_type,

      id,
      key,
      name,
      email,
      photo,
      is_guest,
      is_public: team.is_public,
      is_selected: computed(() => selectedKeys.value.includes(key)),
    }

    data = {
      ...data,
      is_disabled: props.userDisabledRule(data) || props.objectOwner?.id == id,
    }

    createTrackableUser(id, data)

    return data
  })
}

/**
 * ?NOTE: How does this mapper work?
 *
 *
 * @param teams
 */
const mapHierarchyList = teams => {
  const result = teams.map(team => {
    const listChildren = [
      ...mapUsers(team),
      ...mapHierarchyList(team.children, `${TEAM_PREFIX}-${team.id}`),
    ]

    return {
      key: `${TEAM_PREFIX}-${team.id}`,

      id: team.id,
      name: team.name,
      status: team.status,
      is_public: team.is_public,
      is_disabled: listChildren.length === 0 || listChildren.every(child => child.is_disabled),
      is_selected:
        listChildren.length === 0
          ? false
          : computed(() => listChildren.every(child => child.is_selected)),
      users: listChildren,
    }
  })

  return result
}
const fetchUsersTeams = async () => {
  const teamHierarchy = await fetchTeamHiearchy()

  hierarchyList.value = [
    ...mapHierarchyList(teamHierarchy.teams),
    ...mapUsers({ users: teamHierarchy.usersNotInTeam.map(object => ({ user: object })) }),
  ]
}

const toggleAllUserInTeam = (team, newValue) => {
  const users = team.users
    .filter(el => !el.is_disabled && el.key.startsWith(USER_PREFIX))
    .map(user => user.id)

  const teamsChildren = team.users.filter(el => el.key.startsWith(TEAM_PREFIX))

  teamsChildren.forEach(child => toggleAllUserInTeam(child, newValue))

  if (!newValue)
    selectedKeys.value = selectedKeys.value.filter(
      key => !users.includes(Number(key.split('-')[1])),
    )
  else
    selectedKeys.value = Array.from(
      new Set([...selectedKeys.value, ...users.map(id => `${USER_PREFIX}-${id}`)]),
    )
}
const handleSelect = (object, newValue) => {
  if (isTeam(object.key)) toggleAllUserInTeam(object, newValue)
  else
    selectedKeys.value = newValue
      ? Array.from(new Set([...selectedKeys.value, object.key]))
      : selectedKeys.value.filter(key => key !== object.key)
}

// ———————————————————————————— useTeam Functionality ————————————————————————————— //
const isInvitable = user => {
  if (!isJoinedObject(user.object_permission) && isUserLoginOwner.value && !user.is_object_owner) {
    return true
  }

  return false
}
const isJoinedObject = permission => permission !== 'not_joined'
const isTeam = key => key.startsWith(TEAM_PREFIX)
const isJoinedTeam = status => status === 'joined'
const findTeam = id => {
  const team = hierarchyList.value.find(team => team.id === id)

  if (team.id === id) return team

  return findTeam(team.id)
}
const handleJoinTeam = async ({ id, isPublic }) => {
  loadingJoinTeam.value = id
  const team = findTeam(id)

  if (isPublic) {
    try {
      await joinTeam(id)

      team.status = 'joined'
      team.disabled = false
      team.users.forEach(user => {
        user.disabled = false
      })
    } catch (err) {
      //
    }
  } else {
    try {
      await requestJoin(id)

      team.status = 'requested'
    } catch (err) {
      //
    }
  }

  loadingJoinTeam.value = 0
}

// ——————————————————————————— Component Functionality ———————————————————————————— //

watch(
  () => props.value,
  incoming => {
    const newKeys = incoming.map(user =>
      props.returnOnlyId ? `${USER_PREFIX}-${user}` : `${USER_PREFIX}-${user.id}`,
    )

    if (JSON.stringify(newKeys) !== JSON.stringify(selectedKeys.value)) selectedKeys.value = newKeys
  },
  { deep: true },
)

watch(selectedKeys, () => {
  const newEmitValue = props.returnOnlyId
    ? selectedUsers.value.map(user => user.id)
    : props.returnOnlySelect
    ? selectedUsers.value.map(user =>
        props.returnOnlySelect.reduce((acc, key) => {
          acc[key] = user[key]

          return acc
        }, {}),
      )
    : selectedUsers.value

  if (JSON.stringify(newEmitValue) !== JSON.stringify(props.value)) emit('input', newEmitValue)
})

onMounted(async () => {
  loading.value = true

  await fetchUsersTeams()

  loading.value = false

  if (props.value)
    selectedKeys.value = props.value.map(user => {
      if (typeof user === 'object') return `${USER_PREFIX}-${user?.id}`
      else return `${USER_PREFIX}-${user}`
    })
})
</script>
