import {
  mdiArchiveArrowUpOutline,
  mdiArchiveOutline,
  mdiBriefcaseOutline,
  mdiCalendarPlusOutline,
  mdiCalendarRemoveOutline,
  mdiCartOutline,
  mdiCheckboxMultipleOutline,
  mdiClipboardAccountOutline,
  mdiCommentCheckOutline,
  mdiCommentTextMultipleOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiFaceMan,
  mdiFileDocumentEditOutline,
  mdiFileOutline,
  mdiLabelOutline,
  mdiLightbulbAlertOutline,
  mdiProgressCheck,
  mdiTagMultipleOutline,
  mdiTagOutline,
  mdiText,
} from '@mdi/js'

export const categoryList = [
  {
    id: 1,
    label: 'Job',
    icon: mdiBriefcaseOutline,
  },
  {
    id: 2,
    label: 'Status',
    icon: mdiProgressCheck,
  },
  {
    id: 3,
    label: 'Prioritas',
    icon: mdiLightbulbAlertOutline,
  },
  {
    id: 4,
    label: 'Waktu Ditutup',
    icon: mdiCalendarRemoveOutline,
  },
  {
    id: 5,
    label: 'Waktu Dimulai',
    icon: mdiCalendarPlusOutline,
  },
  {
    id: 6,
    label: 'Ditugaskan ke',
    icon: mdiClipboardAccountOutline,
  },
  {
    id: 7,
    label: 'Pelanggan',
    icon: mdiFaceMan,
  },
  {
    id: 8,
    label: 'Produk',
    icon: mdiCartOutline,
  },
  {
    id: 9,
    label: 'Tag',
    icon: mdiTagOutline,
  },
  {
    id: 10,
    label: 'Nama',
    icon: mdiLabelOutline,
  },
  {
    id: 11,
    label: 'Deskripsi',
    icon: mdiText,
  },
  {
    id: 12,
    label: 'Atribut Kustom',
    icon: mdiTagMultipleOutline,
  },
  {
    id: 13,
    label: 'Archive',
    icon: mdiArchiveOutline,
  },
  {
    id: 14,
    label: 'Unarchive',
    icon: mdiArchiveArrowUpOutline,
  },
  {
    id: 15,
    label: 'Delete',
    icon: mdiDeleteOutline,
  },
  {
    id: 16,
    label: 'Task',
    icon: mdiCheckboxMultipleOutline,
  },
  {
    id: 17,
    label: 'Attachment',
    icon: mdiFileOutline,
  },
  {
    id: 18,
    label: 'Watcher',
    icon: mdiEyeOutline,
  },
  {
    id: 19,
    label: 'Assign Comment',
    icon: mdiCommentTextMultipleOutline,
  },
  {
    id: 20,
    label: 'Resolve Comment',
    icon: mdiCommentCheckOutline,
  },
]

export const categoryMap = {
  1: {
    id: 1,
    label: 'Job',
    icon: mdiBriefcaseOutline,
    message: 'Job ini telah ditugaskan kepada seseorang.',
  },
  2: {
    id: 2,
    label: 'Status',
    icon: mdiProgressCheck,
    message: 'Seseorang menyebut Pengguna dalam sebuah deskripsi, komentar, atau Attachment.',
  },
  3: {
    id: 3,
    label: 'Prioritas',
    icon: mdiLightbulbAlertOutline,
    message: 'Tanggal untuk Job ini telah diubah.',
  },
  4: {
    id: 4,
    label: 'Waktu Ditutup',
    icon: mdiCalendarRemoveOutline,
    message: 'Job ini telah dibatalkan untuk ditugaskan kepada seseorang.',
  },
  5: {
    id: 5,
    label: 'Waktu Dimulai',
    icon: mdiCalendarPlusOutline,
    message: 'Seseorang telah menambahkan Pengguna lain kedalam Team ini.',
  },
  6: {
    id: 6,
    label: 'Ditugaskan ke',
    icon: mdiClipboardAccountOutline,
    message: 'Seseorang telah menghapus Pengguna lain kedalam Team ini.',
  },
  7: {
    id: 7,
    label: 'Pelanggan',
    icon: mdiFaceMan,
    message: 'Seseorang meminta untuk bergabung dengan Team ini.',
  },
  8: {
    id: 8,
    label: 'Produk',
    icon: mdiCartOutline,
    message: 'Permintaan Anda untuk bergabung dengan Team ini telah diterima.',
  },
  9: {
    id: 9,
    label: 'Tag',
    icon: mdiTagOutline,
    message: 'Permintaan Anda untuk bergabung dengan Team ini telah ditolak.',
  },
  10: {
    id: 10,
    label: 'Nama',
    icon: mdiLabelOutline,
    message: 'Seseorang telah ditetapkan sebagai Watcher untuk Job.',
  },
  11: {
    id: 11,
    label: 'Deskripsi',
    icon: mdiText,
    message: 'Seseorang telah dihapus sebagai Watcher untuk Job.',
  },
  12: {
    id: 12,
    label: 'Atribut Kustom',
    icon: mdiTagMultipleOutline,
    message: 'Batas waktu untuk Job ini telah terlewati.',
  },
  13: {
    id: 13,
    label: 'Archive',
    icon: mdiArchiveOutline,
    message: 'Job ini telah melewati batas waktu yang ditetapkan.',
  },
  14: {
    id: 14,
    label: 'Unarchive',
    icon: mdiArchiveArrowUpOutline,
    message: 'Pengingat untuk Assignee Job ini sebelum tanggal mulai.',
  },
  15: {
    id: 15,
    label: 'Delete',
    icon: mdiDeleteOutline,
    message: 'Pengingat untuk Watcher atau Owner dari Job ini sebelum tanggal mulai.',
  },
  16: {
    id: 16,
    label: 'Task',
    icon: mdiCheckboxMultipleOutline,
    message: 'Pengingat untuk Assignee Job ini sebelum tanggal selesai.',
  },
  17: {
    id: 17,
    label: 'Attachment',
    icon: mdiFileOutline,
    message: 'Pengingat untuk Watcher atau Owner dari Job ini sebelum tanggal selesai.',
  },
  18: {
    id: 18,
    label: 'Watcher',
    icon: mdiEyeOutline,
    message: 'Seseorang telah ditetapkan sebagai Watcher untuk Job.',
  },
  19: {
    id: 19,
    label: 'Assign Comment',
    icon: mdiCommentTextMultipleOutline,
    message: 'Seseorang telah dihapus sebagai Watcher untuk Job.',
  },
  20: {
    id: 20,
    label: 'Resolve Comment',
    icon: mdiCommentCheckOutline,
    message: 'Batas waktu untuk Job ini telah terlewati.',
  },
  21: {
    id: 21,
    label: 'Document Update',
    icon: mdiFileDocumentEditOutline,
    message: 'Seseorang telah mengupdate Document yang ter-relasikan kepada Job ini.',
  },
}

export const messageList = {
  1: 'membuat',
  11: 'mengubah deskripsi',
  13: 'men-archive',
  14: 'men-unarchive',
  15: 'menghapus',
  4: 'tanggal akhir',
  5: 'tanggal mulai',
}
