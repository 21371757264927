<!-- !IMPO: Filter & Sort function currently disabled, since requesting data more is not neccessary -->
<!-- ?NOTE: fix Filter & Sort function using local implementation or use request data if necessary -->

<template>
  <div class="d-flex flex-column flex-fill overflow-hidden">
    <div>
      <v-row
        class="flex-shrink-1 mt-2"
        dense
        no-gutters
      >
        <!-- class="pr-0 pr-md-1 pt-md-0" -->
        <v-col cols="12">
          <v-text-field
            v-model="filter.search"
            dense
            outlined
            hide-details
            hint="Cari pengguna..."
            label="Filter Berdasarkan Nama"
          >
            <template #append>
              <div class="d-flex align-center">
                <v-icon
                  class="mr-2"
                  size="22"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
              </div>
            </template>
          </v-text-field>
        </v-col>

        <!-- FILTER -->
        <!-- <v-col
        class="px-0 px-md-1 pt-2 pt-md-0"
        cols="12"
        md="3"
        lg="3"
        xs="12"
        sm="12"
      >
        <FilterMenu :workspace="workspaceDetail" />
      </v-col> -->

        <!-- SORTIR -->
        <!-- <v-col
        class="pl-0 pl-md-1 pt-2 pt-md-0"
        cols="12"
        md="3"
        lg="3"
        sm="12"
        xs="12"
      >
        <SortButton
          :sort-options="sortOptions"
          :sort-select="filter.sortSelect"
          :sort-count="sortCount"
          :is-hover="true"
          :is-asc="filter.isASC"
          @ir="addSortir"
        />
      </v-col> -->
      </v-row>
    </div>

    <div
      class="mt-1 overflow-auto"
      style="max-height: 100%"
    >
      <v-row
        dense
        no-gutters
      >
        <v-col
          v-for="(member, index) in workspaceDetail.workspace.members"
          :key="index"
          cols="12"
          lg="6"
          class="pa-1"
        >
          <v-card outlined>
            <div class="d-flex pa-3 justify-space-between">
              <div class="d-flex">
                <v-avatar size="35">
                  <!-- <v-img src="@/assets/images/avatars/5.png" /> -->
                  <v-img
                    v-if="member.user?.photo"
                    :src="member.user?.photo"
                    class="rounded"
                  />
                  <v-avatar
                    v-else-if="avatarText"
                    class="primary"
                    @click="on"
                  >
                    <span class="white--text">
                      {{ avatarText(`${member.user?.first_name} ${member.user?.last_name}`) }}
                    </span>
                  </v-avatar>
                </v-avatar>
                <div class="ml-4">
                  <div class="d-flex">
                    <p
                      class="mb-0 mr-2 body-2 font-weight-semibold d-inline-block text-truncate"
                      style="max-width: 200px"
                    >
                      {{ member.user ? member.user.name : member.email }}
                    </p>
                    <v-chip
                      v-show="member.user && userLogin.id === member.user?.id"
                      x-small
                      color="primary"
                    >
                      Anda
                    </v-chip>
                  </div>

                  <span class="text-caption text--disabled">
                    {{ member.user ? member.user?.email : member.email }}
                  </span>

                  <v-select
                    style="max-width: 200px"
                    v-model="member.role"
                    dense
                    :items="
                      workspaceOwner?.id == userLogin.id
                        ? roleList
                        : workspaceRole?.id === 1 && member?.role?.id !== 1
                        ? roleList.slice(1)
                        : roleList
                    "
                    :disabled="
                      workspaceOwner?.id === userLogin.id && member?.user?.id !== userLogin.id
                        ? false // Owner can modify anything
                        : workspaceRole?.id === 1 &&
                          member?.role?.id !== 1 &&
                          member?.user?.id !== userLogin.id &&
                          member?.user?.id !== workspaceOwner?.id
                        ? false // Admin can modify non-admin, non-owner, non-self
                        : member?.user?.id === workspaceOwner?.id ||
                          member?.role?.id === 1 ||
                          member?.user?.id === userLogin.id
                    "
                    item-value="id"
                    item-text="name"
                    return-object
                    class="mt-1"
                    hide-details="auto"
                    @click="capturePreviousRole = member.role"
                    @change="data => updateUserRole(member, data)"
                  >
                    <template #item="{ item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="d-flex align-center">
                <div v-if="member.is_pending">
                  <v-chip
                    small
                    color="warning"
                    outlined
                    label
                  >
                    Pending
                  </v-chip>
                </div>
                <v-menu
                  v-if="
                    member.user &&
                    userLogin.id !== member.user.id &&
                    member.role.id > 2 &&
                    workspaceRole.id <= 1
                  "
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      plain
                      icon
                      v-bind="attrs"
                      @click="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="error--text"
                        @click="removeUser(member.user)"
                      >
                        Keluarkan dari Workspace
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import SortButton from '@/components/filters/SortButton.vue'
import roleList from '@/constants/workspace/roleList'
import { removeFromWorkspace, updateStatusRole } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { avatarText } from '@core/utils/filter'
import {
  mdiClose,
  mdiDotsVertical,
  mdiMagnify,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortClockAscending,
  mdiSortClockDescending,
} from '@mdi/js'
import Vue, { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
// import FilterMenu from './FilterMenu.vue'

export default defineComponent({
  name: 'ActiveSharedUser',
  components: {
    // PerfectScrollbar,
    // SortButton,
    // FilterMenu,
  },
  setup() {
    const workspaceDetail = inject('workspaceDetail')

    const workspaceRole = computed(() => workspaceDetail.value.role)
    const workspaceOwner = computed(() => workspaceDetail.value.workspace.owner)
    const userLogin = computed(() => store.getters.getUserData)

    const pagination = ref({})
    const attrs = ref('')

    const filter = ref({
      search: '',
      roleSelect: [],
      statusSelect: [],
      sortSelect: {
        id: 0,
        text: 'Nama',
        value: 'name',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortAlphabeticalAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortAlphabeticalDescending,
          },
        ],
      },
      isASC: 1,
    })
    const loadingMember = ref(false)
    const sortCount = ref(0)

    const on = () => {
      console.log('hahaha')
    }

    const sortOptions = ref([
      {
        id: 0,
        text: 'Nama',
        value: 'name',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortAlphabeticalAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortAlphabeticalDescending,
          },
        ],
      },
      {
        id: 1,
        text: 'Tanggal Dibuat',
        value: 'created_at',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortClockAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortClockDescending,
          },
        ],
      },
      {
        id: 2,
        text: 'Terakhir Dilihat',
        value: 'last_seen_at',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortClockAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortClockDescending,
          },
        ],
      },
    ])

    const capturePreviousRole = ref(undefined)

    const updateUserRole = (object, data) => {
      Vue.$dialog({
        title: 'Ganti Role?',
        body: `Yakin ingin mengganti role untuk akun ${object.user.name} menjadi ${data.name}?`,
        maxWidth: '45vw',
      })
        .then(result => {
          if (result) {
            apolloClient
              .mutate({
                mutation: updateStatusRole,
                variables: {
                  workspace_id: workspaceDetail.value.workspace.id,
                  user_id: object.user.id,
                  role_id: data.id,
                },
              })
              .then(() => {
                workspaceDetail.value.workspace.members.find(
                  item => item.user.id == object.user.id,
                ).role.id = data.id

                Vue.notify({
                  title: 'Sukses!',
                  text: 'Berhasil update role pengguna!',
                })
              })
              .catch(err => errorHandling(err))
          }
        })
        .catch(
          () =>
            (workspaceDetail.value.workspace.members.find(
              item => item.user.id == object.user.id,
            ).role = capturePreviousRole.value),
        )
    }

    const addSortir = val => {
      if (filter.value.sortSelect.id === val) {
        filter.value.isASC = filter.value.isASC === 0 ? 1 : 0
      } else {
        filter.value.isASC = 0
      }
      sortCount.value += 1
      filter.value.sortSelect = sortOptions.value[val]
    }

    const removeUser = userData => {
      Vue.$dialog({
        title: 'Keluarkan Pengguna?',
        body: `Yakin ingin mengeluarkan ${userData.name}?`,
      }).then(result => {
        if (result) {
          loadingMember.value = true
          apolloClient
            .mutate({
              mutation: removeFromWorkspace,
              variables: {
                workspace_id: workspaceDetail.value.workspace.id,
                user_id: userData.id,
              },
            })
            .then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengeluarkan pengguna!',
              })
              loadingMember.value = false
            })
            .catch(err => {
              loadingMember.value = false
              errorHandling(err)
            })
        }
      })
    }
    watch(pagination, () => {})
    onMounted(() => {})

    return {
      workspaceDetail,

      workspaceRole,
      workspaceOwner,
      userLogin,

      filter,
      sortCount,
      loadingMember,

      pagination,
      capturePreviousRole,
      updateUserRole,
      removeUser,
      roleList,
      avatarText,
      icons: {
        mdiDotsVertical,
        mdiMagnify,
        mdiClose,
      },

      attrs,
      on,

      sortOptions,
      addSortir,
    }
  },
})
</script>

<style lang="scss">
.userlist-footer {
  position: fixed;
  top: 16px;
  right: 0;
  margin: 0 24px;
  z-index: 20;

  .userlist-pagination {
    .v-data-footer__select {
      margin-left: 0 !important;
    }

    .v-data-footer__pagination {
      margin-left: auto !important;
    }
  }
}

.sortir-select {
  .v-input__control {
    max-width: 164px;
  }
}

.align-self-center {
  .v-badge__wrapper {
    .v-badge__badge {
      inset-inline-end: -80px;
      inset-block: -20px;
    }
  }
}
</style>
