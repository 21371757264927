var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('v-form',{ref:"permissionForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"text-caption"},[_vm._v("Hak akses")])]),_c('v-btn',{staticClass:"me-4",attrs:{"min-height":"110","min-width":"110","outlined":"","text":!_vm.localValue?.is_public,"color":_vm.localValue?.is_public ? 'primary' : null},on:{"click":function($event){_vm.localValue.is_public = true}}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"text--disabled mb-2",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountGroup)+" ")]),_c('span',{class:_vm.localValue?.is_public ? '' : 'text--disabled'},[_vm._v("Publik")])],1)]),_c('v-btn',{attrs:{"min-height":"110","min-width":"110","outlined":"","text":_vm.localValue?.is_public,"color":!_vm.localValue?.is_public ? 'primary' : null},on:{"click":function($event){_vm.localValue.is_public = false}}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"text--disabled mb-2",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShieldLock)+" ")]),_c('span',{class:!_vm.localValue?.is_public ? '' : 'text--disabled'},[_vm._v("Kustom")])],1)])],1),(!_vm.localValue?.is_public)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"text-caption"},[_vm._v("User Permission")])]),_c('div',{staticClass:"autocomplete-field pa-0"},[(_vm.userList.length !== 0)?_c('chip-search-input',{staticClass:"pt-1 pb-2 px-3",attrs:{"object-type":"user","width":400,"max-items":12,"search":_vm.search,"autoFocus":true,"selected-object-ids":_vm.selectedUser.map(user => user?.id),"object-list":_vm.userList,"can-chip-be-deleted":object => {
              const isAdmin = _vm.findUserPermission(object?.id)?.permission_type === 'Admin'
              const isOwner = object?.id === _vm.objectDetail?.owner?.id
              const isSelf = object?.id === _vm.currentUserId

              return !isAdmin && !isOwner && !isSelf
            }},on:{"update:search":function($event){_vm.search=$event},"input-search":function($event){_vm.search = $event ?? ''},"remove":_vm.handleChipDelete,"clear":function($event){_vm.selectedUser = []}}}):_vm._e()],1)]):_vm._e()],1),_c('div',{staticClass:"mt-4 mb-1"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"text-caption"},[_vm._v("Pemilik Folder")])]),_c('v-avatar',{staticClass:"mr-2 v-avatar-light-bg",attrs:{"size":"30"}},[(_vm.objectDetail?.owner?.photo)?_c('v-img',{attrs:{"src":_vm.objectDetail?.owner?.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(_vm.objectDetail?.owner?.name)))])],1),_c('span',[_vm._v(_vm._s(_vm.objectDetail?.owner?.name))])],1),(!_vm.objectDetail?.is_public)?[_c('TreeUserSelector',{attrs:{"disable-team-utility":"","width":"100%","value":_vm.selectedUser,"search-value":_vm.search,"object-owner":_vm.objectDetail?.owner,"return-only-select":['permission_type', 'id'],"permission-list":_vm.objectDetail[_vm.permissionAccessor[_vm.objectType].target],"user-disabled-rule":item => {
          const isOwner = item.id === _vm.objectDetail?.owner?.id
          const isAdmin = item.permission_type === 1
          const isCurrentUser = item.id === _vm.currentUserId

          if (_vm.objectDetail?.owner?.id === _vm.currentUserId) return false

          if (_vm.lookupCurrentUserPermisison?.permission_type === 'Admin' && !isOwner && !isAdmin)
            return false

          return isOwner || isAdmin || isCurrentUser
        }},on:{"input":function($event){_vm.selectedUser = $event}},scopedSlots:_vm._u([{key:"append",fn:function({ item, user }){return [(item.id == _vm.objectDetail?.owner?.id)?_c('div',{staticStyle:{"max-width":"150px"}},[_c('v-select',{staticClass:"my-0",attrs:{"disabled":true,"item-text":"label","item-value":"value","placeholder":"Owner","dense":"","outlined":"","hide-details":"auto"}})],1):(_vm.selectedUser.find(user => user?.id === item.id))?_c('div',{staticStyle:{"max-width":"150px"}},[_c('v-select',{staticClass:"my-0",attrs:{"items":_vm.folderPermissionList.filter(permission => {
                const isNotOwner = _vm.currentUserId !== _vm.objectDetail?.owner?.id
                const isNotSuperAdmin = user.permission_type !== 1

                return isNotOwner && isNotSuperAdmin ? permission.id !== 1 : true
              }),"disabled":_vm.currentUserId !== _vm.objectDetail?.owner?.id && item.is_disabled,"item-text":"label","item-value":"id","placeholder":"Permission","dense":"","outlined":"","hide-details":"auto"},on:{"change":function($event){return _vm.handleChangePermission($event, item.id)}},model:{value:(user.permission_type),callback:function ($$v) {_vm.$set(user, "permission_type", $$v)},expression:"user.permission_type"}})],1):_vm._e()]}}],null,false,1576928797)})]:_vm._e()],2):_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"min-height":"500px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }