import store from '@/store'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { createClient } from 'graphql-ws'
import Vue from 'vue'
import VueApollo from 'vue-apollo'

const getWorkspaceId = () => store.getters.getCurrentWorkspaceId

// WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_GRAPHQL_WS,
    shouldRetry: () => true,
    retryAttempts: 10,
    retryDelay: 1000,
    connectionParams: () => ({
      token: `Bearer ${localStorage.getItem('token')}|${getWorkspaceId() ?? ''}`,
    }),
  }),
)

// HTTP link for queries and mutations
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
})

// Middleware to add the workspace ID to all queries and mutations
const workspaceLink = new ApolloLink((operation, forward) => {
  const workspaceId = getWorkspaceId()

  // Modify the operation's variables to include the workspace_id
  operation.variables = {
    workspace_id: workspaceId,
    ...operation.variables,
  }

  return forward(operation)
})

// Middleware to add authorization headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Combine all links, applying `authLink` and `workspaceLink` before the split logic
const link = ApolloLink.from([
  authLink,
  workspaceLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  ),
])

// Apollo Client setup
export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)
