import { mdiSortAlphabeticalAscending } from '@mdi/js'
import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    folderList: [],

    folderDetail: undefined,

    filter: {
      search: '',
      my_folder: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: 'IS NOT SET',
      },
      sort: {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
    },

    loadingFolder: false,
    loadingFolderDetail: false,
  },
  getters: {
    getField,
    getFolderList: state => state.folderList,
    getFolderDetail: state => state.folderDetail,
    getFolderDetailId: state => state.folderDetail?.id,
    getFolderFilter: state => ({
      search: state.filter.search,
      sort: state.filter.sort.value,
      sortType: state.filter.sort.sortType,
      show_archived: state.filter.show_archived.value,
      my_folder: state.filter.my_folder.value,
    }),

    getLoadingFolder: state => state.loadingFolder,
  },
  mutations: {
    setField,
  },
  actions: {
    resetFolderFilter(state) {
      state.state.filter = {
        search: '',
        my_folder: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: 'IS NOT SET',
        },
        sort: {
          id: 1,
          text: 'Nama (A-Z)',
          value: 'name',
          sortType: 'ASC',
          icon: mdiSortAlphabeticalAscending,
        },
      }
    },
  },
}
