import gql from 'graphql-tag'

const jobTypeDetail = gql`
  query jobTypeDetail(
    $workspace_id: Float!
    $job_type_id: Float!
    $exclude_closed_status: Boolean
  ) {
    jobTypeDetail(
      workspace_id: $workspace_id
      job_type_id: $job_type_id
      exclude_closed_status: $exclude_closed_status
    ) {
      id
      name
      color
      is_public
      created_at
      archived_at
      owner {
        id
        name
        photo
      }
      folder_permissions {
        permission_type
        user {
          id
          name
          photo
        }
      }
      statuses {
        id
        name
        color
        urutan
        type
      }
      priorities {
        id
        name
        color
        urutan
      }
      custom_attributes {
        id
        name
        order
        formula
        options {
          id
          name
        }
        data_type {
          id
          name
          operator {
            id
            name
            value
          }
        }
        object_type {
          id
          name
        }
      }
    }
  }
`

export default jobTypeDetail
