import { createHelpers } from 'vuex-map-fields'
import store from '..'

const { getWorkspaces, updateWorkspaces } = createHelpers({
  getterType: 'getWorkspaces',
  mutationType: 'updateWorkspaces',
})

export default {
  state: {
    workspaceList: [],
    selectedWorkspace: JSON.parse(localStorage.getItem('selectedWorkspace') ?? '{}'),
  },
  getters: {
    getWorkspaces,

    /**
     * @returns {{
     *  id: number
     *  identifier_id: string
     *  name: string
     *  owner: {
     *    id: number,
     *  }
     *  members: import('./definition/auth').User
     *  invite_code: string
     *  open_invite: boolean
     *  out_of_working_hours_autoreply: boolean
     *  working_hours: any
     *  photo: string
     * } | null}
     */
    getCurrentWorkspace(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.workspace
    },

    /**
     * @returns {number|null}
     */
    getCurrentWorkspaceId(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.workspace.id
    },

    /**
     * @returns {number|null}
     */
    getCurrentWorkspaceRole(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.role
    },

    /**
     * @returns {[
     *  {
     *    role: {
     *      id: number
     *      name: "ADMIN" | "MEMBER" | "GUEST"
     *    }
     *    user: import('./definition/auth').User
     *  }
     * ]|null}
     */
    getCurrentWorkspaceUserList(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.workspace.members
        .map(object => object.user)
        .filter(user => user !== null)
    },
  },
  mutations: {
    updateWorkspaces,
  },
  actions: {
    /**
     * @param { any } state
     * @param { Array } payload
     */
    updateWorkspaceData(state, payload) {
      state.commit('updateWorkspaces', { path: 'workspaceList', value: payload })
    },

    /**
     * @param { any } state
     * @param { {
     *   id: number
     *   identifier_id: string
     *   name: string
     *   photo: string
     *   owner: {
     *     id: number
     *   }
     * } } payload
     */
    setSelectedWorkspace(state, payload) {
      state.commit('updateWorkspaces', { path: 'selectedWorkspace', value: payload })
      localStorage.setItem('selectedWorkspace', JSON.stringify(payload))

      store.commit('updateAuthField', {
        path: 'isWorkspaceOwner',
        value: payload.workspace.owner.id === store.getters.getUserData.id,
      })
    },
  },
}
