<template>
  <v-list-item
    v-bind="linkProps"
    class="vertical-nav-menu-link"
    active-class="bg-gradient-primary"
    :exact="true"
  >
    <v-list-item-icon>
      <v-icon :color="isActive ? 'white' : null">
        {{ mdiBell }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title :class="{ 'white--text': isActive }">
      {{ t(item.title) }}
    </v-list-item-title>

    <v-list-item-action
      v-if="item.badge"
      class="flex-shrink-0"
    >
      <v-badge
        inline
        :color="item.badgeColor"
        :value="unreadNotification > 0"
        :content="unreadNotification > 99 ? '99+' : unreadNotification"
      ></v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import useNotification from '@/composables/useNotification'
import store from '@/store'
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'

import { useUtils } from '@core/libs/i18n'
import { mdiBell } from '@mdi/js'
import themeConfig from '@themeConfig'
import { computed, onMounted } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { linkProps } = useVerticalNavLink(props.item)
    const { t } = useUtils()

    const { fetchUnreadNotification } = useNotification()

    const unreadNotification = computed(() => store.state.notification.unreadNotification)

    onMounted(() => fetchUnreadNotification())

    return {
      isActive: false,
      linkProps,
      mdiBell,
      alternateIcon: themeConfig.menu.groupChildIcon,

      unreadNotification,

      // i18n
      t,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
