<template>
  <v-row>
    <v-col
      cols="8"
      class="pe-0"
    >
      <v-subheader>
        <span
          v-show="!menuIsVerticalNavMini || (menuIsVerticalNavMini && isHovered)"
          class="title-wrapper"
          :class="{ 'no-style': menuIsVerticalNavMini && !isHovered }"
        >
          <span>{{ t(item.title) }}</span>
        </span>
        <v-icon v-show="menuIsVerticalNavMini && !isHovered">
          {{ icons.mdiMinus }}
        </v-icon>
      </v-subheader>
    </v-col>
    <v-col
      v-if="!menuIsVerticalNavMini || (menuIsVerticalNavMini && isHovered)"
      cols="4"
      class="px-1"
    >
      <span class="action-wrapper d-flex">
        <v-menu
          v-model="showSearchForm"
          :close-on-content-click="false"
          offset-x
        >
          <template #activator="menu">
            <v-tooltip top>
              <template #activator="tooltip">
                <v-btn
                  v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                  icon
                  class="me-1"
                  v-on="{ ...menu.on, ...tooltip.on }"
                  @click.stop="menuIsVerticalNavMini = false"
                >
                  <v-badge
                    :value="folderFilterCount > 0"
                    color="primary"
                    :content="folderFilterCount"
                    overlap
                    class="align-self-center"
                  >
                    <v-icon size="24">
                      {{ icons.mdiMagnify }}
                    </v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Cari Folder</span>
            </v-tooltip>
          </template>

          <search-folder-form />
        </v-menu>

        <v-tooltip top>
          <template #activator="tooltip">
            <v-btn
              v-bind="tooltip.attrs"
              icon
              v-on="tooltip.on"
              @click.stop="
                $refs.folderForm.show()
                menuIsVerticalNavMini = false
              "
            >
              <v-icon size="24">
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>Tambah Folder</span>
        </v-tooltip>

        <folder-form
          ref="folderForm"
          @onChangeFolder="$emit('onChangeFolder')"
        />
      </span>
    </v-col>
  </v-row>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import SearchFolderForm from '@/views/folder/SearchFolderForm.vue'
import FolderForm from '@/views/folder/FolderForm.vue'
import { mdiMinus, mdiMagnify, mdiPlus } from '@mdi/js'
import { computed, inject, ref } from 'vue'
import { useUtils } from '@core/libs/i18n'

import { createFieldMapper } from 'vuex-use-fields'

const useFieldFolder = createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })

export default {
  components: {
    SearchFolderForm,
    FolderForm,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const state = {
      ...useFieldFolder(['filter']),
    }

    const { menuIsVerticalNavMini } = useAppConfig()
    const isHovered = inject('isHovered')
    const { t } = useUtils()

    const showSearchForm = ref(false)
    const folderForm = ref(null)
    const folderFilterCount = computed(() => {
      let count = 0
      if (state.filter.value.search !== '') count += 1
      if (state.filter.value.sort.id !== 1) count += 1
      if (state.filter.value.show_archived.id !== 2) count += 1
      if (state.filter.value.my_folder.id !== 1) count += 1

      return count
    })

    return {
      menuIsVerticalNavMini,
      isHovered,

      // i18n
      t,

      // Icons
      icons: {
        mdiMinus,
        mdiMagnify,
        mdiPlus,
      },

      showSearchForm,
      folderForm,
      folderFilterCount,
      state,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.v-menu__content {
  @include style-scroll-bar();
}

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;
  .v-icon {
    color: map-deep-get($material, 'dividers');
  }

  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .action-wrapper {
    &:not(.no-style) {
      // text-align: center;
      position: absolute;
      right: 0;
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
    .no-style {
      display: none;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;
    font-size: 0.75rem;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }

  .action-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;
    font-size: 0.75rem;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
</style>
