import Vue from 'vue'
import Vuex from 'vuex'

import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { setupStore } from 'vuex-use-fields'

import app from './app'
import auth from './module/auth'
import chat from './module/chat'
import comment from './module/comment'
import dashboard from './module/dashboard'
import document from './module/document'
import folder from './module/folder'
import google from './module/google'
import job from './module/job'
import jobType from './module/jobType'
import notification from './module/notification'
import team from './module/team'
import trello from './module/trello'
import user from './module/user'
import workspace from './module/workspace'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,

    // * Store Module
    app,
    auth,
    chat,
    comment,
    dashboard,
    document,
    folder,
    google,
    job,
    jobType,
    notification,
    team,
    trello,
    user,
    workspace,
  },
  plugins: [setupStore],
})
