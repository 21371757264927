export const folderPermissionList = [
  {
    id: 1,
    value: 'Admin',
    label: 'Admin',
  },
  {
    id: 2,
    value: 'Editor',
    label: 'Editor',
  },
  {
    id: 3,
    value: 'CommentOnly',
    label: 'Comment Only',
  },
  {
    id: 4,
    value: 'ViewOnly',
    label: 'View Only',
  },
]
export const folderPermissionMap = {
  Admin: {
    id: 1,
    value: 'Admin',
    label: 'Admin',
  },
  Editor: {
    id: 2,
    value: 'Editor',
    label: 'Editor',
  },
  CommentOnly: {
    id: 3,
    value: 'CommentOnly',
    label: 'Comment Only',
  },
  ViewOnly: {
    id: 4,
    value: 'ViewOnly',
    label: 'View Only',
  },
}

export const jobTypePermissionList = [
  {
    id: 1,
    value: 'Admin',
    label: 'Admin',
  },
  {
    id: 2,
    value: 'Editor',
    label: 'Editor',
  },
  {
    id: 3,
    value: 'CommentOnly',
    label: 'Comment Only',
  },
  {
    id: 4,
    value: 'ViewOnly',
    label: 'View Only',
  },
]
export const jobTypePermissionMap = {
  Admin: {
    id: 1,
    value: 'Admin',
    label: 'Admin',
  },
  Editor: {
    id: 2,
    value: 'Editor',
    label: 'Editor',
  },
  CommentOnly: {
    id: 3,
    value: 'CommentOnly',
    label: 'Comment Only',
  },
  ViewOnly: {
    id: 4,
    value: 'ViewOnly',
    label: 'View Only',
  },
}

export const documentPermissionList = [
  {
    id: 1,
    value: 'MAINTAINER',
    label: 'Maintainer',
  },
  {
    id: 2,
    value: 'CONTRIBUTOR',
    label: 'Contributor',
  },
  {
    id: 3,
    value: 'COMMENTOR',
    label: 'Commentor',
  },
  {
    id: 4,
    value: 'VIEWER',
    label: 'Viewer',
  },
]

export const teamPermissionList = [
  {
    id: 1,
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    id: 2,
    value: 'MEMBER',
    label: 'Member',
  },
  {
    id: 3,
    value: 'VIEWER',
    label: 'Viewer',
  },
]

export const permissionAccessor = {
  folder: {
    target: 'folder_permissions',
    list: folderPermissionList,
    map: folderPermissionMap,
  },
  'job-type': {
    target: 'folder_permissions',
    list: jobTypePermissionList,
    // map: jobTypePermissionMap,
  },

  document: {
    list: documentPermissionList,
    // map: documentPermissionMap,
  },
  team: {
    list: teamPermissionList,
    // map: teamPermissionMap,
  },
}
