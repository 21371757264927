<template>
  <v-card
    :width="width"
    :min-height="height"
    outlined
    class="user-selector-container elevation-0"
  >
    <chip-search-input
      class="py-2 px-3"
      auto-focus
      object-type="user"
      :width="width"
      :search.sync="search"
      :selected-object-ids="localValue"
      :object-list="userList"
      @input-search="search = $event"
      @remove="handleChipDelete"
      @clear="localValue = []"
    />

    <v-divider />

    <TreeUserSelector
      return-only-id
      :height="height"
      :width="width"
      :value="value"
      :search-value="search"
      :disable-team-utility="disableTeamUtility"
      :user-disabled-rule="
        item => {
          const isAvailable = lookupAvailableUser.includes(item.id)

          return !isAvailable
        }
      "
      @input="localValue = $event"
    >
      <template #append="{ item, user }">
        <div
          v-if="user && !lookupAvailableUser.includes(item.id)"
          style="max-width: 150px"
        >
          <v-btn
            x-small
            color="primary"
            :loading="loadingEditJobType"
            @click="handleInviteToJobType(item)"
          >
            Invite to Job Type
          </v-btn>
        </div>
      </template>
    </TreeUserSelector>
  </v-card>
</template>

<script setup>
import useJob from '@/composables/useJob'
import { jobTypePermissionMap } from '@/constants/permission'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import Vue, { computed, ref, watch } from 'vue'
import ChipSearchInput from './components/ChipSearchInput.vue'
import TreeUserSelector from './components/treeview/TreeUserSelector.vue'

const emit = defineEmits(['input', 'update:selectedUsers'])
const { value, height, width, objectType, disableTeamUtility } = defineProps({
  value: {
    type: [Array, Object, Number, String],
    default: () => [],
  },

  // Styling
  height: {
    type: [Number, String],
    default: 300,
  },
  width: {
    type: [Number, String],
    default: 450,
  },

  objectType: {
    type: String,
    validator: value => ['folder', 'job-type'].includes(value),
  },

  // TreeUserSelector Props
  disableTeamUtility: {
    type: Boolean,
  },
})

const { updateJobTypePermission, loadingEditJobType } = useJob()

const search = ref()
const localValue = ref(value)

const userList = computed(() => store.getters.getCurrentWorkspaceUserList)
const lookupIsAdmin = computed(
  () => store.getters['jobType/getJobTypeCurrentUserPermission']?.permission_type == 'Admin',
)
const lookupAvailableUser = computed(() => {
  if (objectType === 'job-type')
    return store.getters['jobType/getJobTypePermissionList'].map(item => item?.user?.id)
  else return store.getters.getCurrentWorkspaceUserList.map(item => item?.id)
})

const handleChipDelete = userId => {
  localValue.value = localValue.value.filter(id => id !== userId)
}

const handleInviteToJobType = user => {
  if (!lookupIsAdmin.value) return errorHandling('Action cannot be done, Admin previlage required!')

  Vue.$dialog({
    title: 'Apakah anda yakin untuk melakukan ini?',
    body: `Anda akan mengundan ${user.name} ke Job Type ini.`,
    maxWidth: '550',
  })
    .then(result => {
      if (result)
        updateJobTypePermission({
          id: store.getters['jobType/getJobTypeId'],
          isPublic: store.getters['jobType/getJobTypeVisibility'],
          userPermissions: [
            ...store.getters['jobType/getJobTypePermissionList'].map(item => ({
              user_id: item.user.id,
              permission_type: jobTypePermissionMap[item.permission_type].id,
            })),
            {
              user_id: user.id,
              permission_type: 4,
            },
          ],
        })
    })
    .catch(() => {})
}

watch(localValue, value => {
  emit('input', value)
})
</script>
