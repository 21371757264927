const roleList = [
  {
    id: 1,
    name: 'ADMIN',
  },
  {
    id: 2,
    name: 'MEMBER',
  },
  {
    id: 3,
    name: 'GUEST',
  },
]

export default roleList
