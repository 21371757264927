import gql from 'graphql-tag'

export const getCommentById = gql`
  query GetComment($getCommentId: Float!) {
    getComment(id: $getCommentId) {
      status
      msg
      data
    }
  }
`
