import gql from 'graphql-tag'

const comments = gql`
  query comments(
    $pagination: pagination!
    $attachment_id: String
    $document_uuid: String
    $job_id: Float
    $workspace_id: Float!
    $sort: commentsSort!
    $target_id: Float
    $timestamp: DateTime!
  ) {
    comments(
      pagination: $pagination
      attachment_id: $attachment_id
      document_uuid: $document_uuid
      job_id: $job_id
      workspace_id: $workspace_id
      sort: $sort
      target_id: $target_id
      timestamp: $timestamp
    ) {
      count
      comments {
        id
        comment
        strip_comment
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        created_at
        updated_at
        deleted_at
        resolved_at
        lexorank
        job {
          id
          job_type {
            id
          }
        }
        document {
          uuid
        }
        assigned_user {
          id
          email
          name
          photo
        }
        parent {
          id
          strip_comment
          job {
            id
            job_type {
              id
            }
          }
          document {
            uuid
          }
          user {
            id
            name
          }
        }
        children {
          id
          comment
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
          assigned_user {
            id
            email
            name
            photo
          }
          created_at
          updated_at
          deleted_at
          resolved_at
        }
      }
    }
  }
`

export default comments
