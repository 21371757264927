import useWorkspace from '@/composables/useWorkspace'
import { login } from '@/graphql/mutations'
import { profileInfo } from '@/graphql/queries'
import ability from '@/plugins/acl/ability'
import { initialAbility } from '@/plugins/acl/config'
import router from '@/router'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import { createHelpers } from 'vuex-map-fields'
import store from '..'
import useFolder from '@/composables/useFolder'

const { getAuthField, updateAuthField } = createHelpers({
  getterType: 'getAuthField',
  mutationType: 'updateAuthField',
})

export default {
  /**
   * @type {import('./definition/auth').StateClass}
   */
  state: {
    userData: null,
    loadingLogin: false,
    globalLoading: false,
    isAuthenticated: false,
    isGuest: undefined,
    isWorkspaceOwner: undefined,
  },
  getters: {
    getAuthField,

    /**
     * @method
     * @param {import('./definition/auth').StateClass} state
     * @returns
     */
    getUserData: state => state.userData,
  },
  mutations: {
    updateAuthField,

    /**
     * @method
     * @param {import('./definition/auth').StateClass} state
     * @returns {void}
     */
    setUserData(state, data) {
      state.userData = data
    },
  },
  actions: {
    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @param {any} payload
     * @returns {Promise<any>}
     */
    login(state, payload) {
      return new Promise((resolve, reject) => {
        state.commit('updateAuthField', { path: 'loadingLogin', value: true })
        apolloClient
          .mutate({
            mutation: login,
            variables: payload,
          })
          .then(async result => {
            state.commit('updateAuthField', { path: 'isAuthenticated', value: true })
            localStorage.setItem('token', result.data.login.token)
            localStorage.setItem('refreshToken', result.data.login.refreshToken)
            await state.dispatch('getProfileInfo')
            state.commit('updateAuthField', { path: 'loadingLogin', value: false })
            resolve(result)
          })
          .catch(err => {
            state.commit('updateAuthField', { path: 'loadingLogin', value: false })
            errorHandling('Credentials Incorrect!')
            reject(err)
          })
      })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @param {any} payload
     * @returns {Promise<any>}
     */
    getProfileInfo(state) {
      return new Promise((resolve, reject) => {
        state.commit('updateAuthField', { path: 'globalLoading', value: true })

        apolloClient
          .query({
            query: profileInfo,
            fetchPolicy: 'no-cache',
          })
          .then(result => {
            state.commit('updateAuthField', { path: 'globalLoading', value: false })
            state.commit('updateAuthField', { path: 'userData', value: result.data.profileInfo })
            state.commit('updateAuthField', { path: 'isAuthenticated', value: true })

            state.commit('updateAuthField', {
              path: 'isGuest',
              value: result.data.profileInfo.is_guest === null ? false : true,
            })

            if (store.getters.getCurrentworkspace)
              state.commit('updateAuthField', {
                path: 'isWorkspaceOwner',
                value: result.data.profileInfo.id === store.getters.getCurrentWorkspace.owner.id,
              })

            const { fetchWorkspaceList, fetchWorkspaceDetail } = useWorkspace()
            const { fetchFolder } = useFolder()

            fetchWorkspaceList().then(data =>
              fetchWorkspaceDetail(
                store.getters.getCurrentWorkspaceId ?? data[0]?.workspace?.id,
              ).then(() => {
                fetchFolder(store.getters['folder/getFolderFilter'])
                resolve(result)
              }),
            )
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @returns {void}
     */
    checkExpiredToken(state) {
      const token = jwtDecode(localStorage.getItem('token'))
      const now = new Date()
      const exp = new Date(token.exp * 1000 - 1000000)

      if (now > exp) {
        state.dispatch('refreshToken')
      }
    },
    refreshToken(state) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation ($token: String!) {
              refreshToken(token: $token) {
                status
                token
                refreshToken
              }
            }
          `,
          variables: {
            token: localStorage.getItem('refreshToken'),
          },
        })
        .then(result => {
          localStorage.setItem('token', result.data.refreshToken.token)
          localStorage.setItem('refreshToken', result.data.refreshToken.refreshToken)
          router.go()
        })
        .catch(() => {
          state.commit('updateAuthField', { path: 'globalLoading', value: false })
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userAbility')
          state.commit('updateAuthField', { path: 'userData', value: null })
          state.commit('updateAuthField', { path: 'isAuthenticated', value: false })

          // Reset ability
          ability.update(initialAbility)
          router.replace('/login')
        })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @returns {void}
     */
    logout(state) {
      Vue.$dialog({
        title: 'Ingin keluar?',
        body: 'Yakin ingin keluar OriensCRM?',
      }).then(result => {
        if (result) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          state.commit('updateAuthField', { path: 'userData', value: null })
          state.commit('updateAuthField', { path: 'isAuthenticated', value: false })

          // Reset ability
          ability.update(initialAbility)

          router.push('/login')
          Vue.notify({
            title: 'Berhasil logout!',
            text: 'Berhasil keluar dari OriensCRM.',
          })
          setTimeout(() => {
            window.location.reload()
          }, 300)
        }
      })
    },
  },
}
