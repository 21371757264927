<template>
  <v-dialog
    v-model="isOpen"
    max-width="1080"
    scrollable
    persistent
  >
    <v-form
      ref="folderForm"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Buat Folder Baru</span>
          <v-icon @click="close()">
            {{ icons.mdiClose }}
          </v-icon>
        </div>

        <v-card-text class="pt-2">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                label="Nama folder"
                placeholder="Masukkan nama folder"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <color-picker
                v-model="formData.color"
                label="Warna"
                class="mb-2"
                outlined
                :rules="[required]"
              />
            </v-col>
          </v-row>

          <PermissionForm
            ref="folderPermissionForm"
            object-type="folder"
            :object-detail="formData"
            @update:selected="formData.folder_permissions = $event"
          />
        </v-card-text>

        <v-card-actions>
          <div class="mt-4 ml-md-auto">
            <v-btn
              color="primary"
              @click="submitForm"
            >
              Tambahkan Folder
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import PermissionForm from '@/components/inputs/PermissionForm.vue'
import useFolder from '@/composables/useFolder'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiAccountGroup, mdiClose, mdiShieldLock } from '@mdi/js'
import { ref } from 'vue'

export default {
  components: {
    ColorPicker,
    PermissionForm,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const folderForm = ref(null)
    const formData = ref({
      name: null,
      color: null,
      is_public: true,
      folder_permissions: [],
      owner: {
        id: store.getters.getUserData.id,
        name: store.getters.getUserData.name,
        photo: store.getters.getUserData.photo,
      },
    })

    const { createFolder } = useFolder()
    const folderPermissionForm = ref(null)

    const reset = () => {
      formData.value = {
        name: null,
        color: null,
        is_public: true,
        folder_permissions: [],
        owner: {
          id: store.getters.getUserData.id,
          name: store.getters.getUserData.name,
          photo: store.getters.getUserData.photo,
        },
      }

      folderForm.value.reset()
      if (folderPermissionForm.value) folderPermissionForm.value.reset()
    }

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      reset()
      isOpen.value = false
    }

    const submitForm = () => {
      if (folderForm.value.validate()) {
        createFolder(formData.value).then(() => {
          close()
          emit('onChangeFolder')
        })
      }
    }

    return {
      show,
      close,
      isOpen,
      formData,

      folderForm,
      submitForm,
      folderPermissionForm,

      required,
      avatarText,

      icons: {
        mdiClose,
        mdiAccountGroup,
        mdiShieldLock,
      },
    }
  },
}
</script>
