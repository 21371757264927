import { joinWorkspace } from '@/graphql/mutations'
import {
  workspaceDetail as workspaceDetailQuery,
  workspaceList as workspaceListQuery,
} from '@/graphql/queries'
import invitationPage from '@/graphql/query/invitationPage'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from 'vue'

const useWorkspace = () => {
  const loadingInvitationPage = ref(false)
  const loadingJoinWorkspace = ref(false)

  const workspaceData = ref(null)

  const fetchWorkspaceList = () =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: workspaceListQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          if (data.workspaceList && data.workspaceList.length) {
            store.dispatch(
              'updateWorkspaceData',
              data.workspaceList.filter(el => el.workspace.identifier_id !== ''),
            )
          }

          resolve(data.workspaceList.filter(el => el.workspace.identifier_id !== ''))
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        })
    })

  const fetchWorkspaceDetail = (id, mutateStore = true) =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: workspaceDetailQuery,
          variables: {
            workspace_id: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          const role = data.workspaceDetail.members.find(
            member => member.user.id === store.getters.getUserData.id,
          )?.role

          if (!role) return errorHandling('User tidak termasuk dalam Workspace!')

          if (mutateStore)
            store.dispatch('setSelectedWorkspace', {
              role,
              workspace: data.workspaceDetail,
            })

          resolve({
            role,
            workspace: data.workspaceDetail,
          })
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        })
    })

  const WorkspacePermissionGuard = (data, role) => {
    if (data.workspace.owner.id === store.getters.getUserData.id) return true // Owner
    if (data.role.id <= role) return true // Admin

    return false
  }

  const getInvitationPage = inviteCode => {
    loadingInvitationPage.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: invitationPage,
          variables: {
            invite_code: inviteCode,
          },
        })
        .then(({ data }) => {
          loadingInvitationPage.value = false
          resolve(data.invitationPage)

          workspaceData.value = data.invitationPage
        })
        .catch(err => {
          loadingInvitationPage.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const joinToWorkspace = inviteCode => {
    loadingJoinWorkspace.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: joinWorkspace,
          variables: {
            invite_code: inviteCode,
          },
        })
        .then(({ data }) => {
          loadingJoinWorkspace.value = false
          resolve(data.joinWorkspace)
        })
        .catch(err => {
          loadingJoinWorkspace.value = false
          errorHandling(err)
          reject(err)
        })
    })
  }

  return {
    fetchWorkspaceList,
    fetchWorkspaceDetail,

    WorkspacePermissionGuard,

    getInvitationPage,
    loadingInvitationPage,
    workspaceData,

    joinToWorkspace,
    loadingJoinWorkspace,
  }
}

export default useWorkspace
